import { useState } from "react";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { useMutation } from "react-query";
import axiosinstance from "utils/AxiosInstance";
import { CISUI } from "utils/CISUI";
import Input from "shared/Input/Input";

const ForgotPass = () => {
  // const [state, setState] = useState<UserInfoInface>({});
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [option, setOption] = useState(true);

  // const [loading, setLoading] = useState(false)
  let history = useHistory();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const email_send = urlParams.get("e");

  const forgotpass = useMutation((e: any) => {
    e.preventDefault();
    const res = axiosinstance
      .post("forgotpassword", { phone: phone })
      .then((res) => {
        if (res.data.status === 0) {
          CISUI().notification("warning", res.data.msg);
        }
        if (res.data.status === 1) {
          CISUI().notification("success", res.data.msg);
          history.push({ pathname: "/password-reset", state: phone });
        }
      });
    return res;
  });

  // const handleChange = (e: any) => {
  //     setOption(!e.target.checked)
  // }

  return (
    <div className="flex items-center justify-center my-10">
      {email_send === "send" ? (
        <div>
          <p>Please check your email for a link to reset your password.</p>
          <ButtonPrimary href="/login/customer" className="my-5">
            Back to login
          </ButtonPrimary>
        </div>
      ) : (
        <form
          className="max-w-xl space-y-6 w-full"
          onSubmit={(e) => {
            forgotpass.mutate(e);
          }}
        >
          <div>
            <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
              Forgot Password
            </h2>
            <>
              <div>
                {option ? (
                  <>
                    <label className="mb-3 ">Enter your Phone Number</label>
                    <PhoneInput
                      country="us"
                      value={phone}
                      onChange={(phone) => setPhone(`+${phone}`)}
                      inputClass="block !w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 mt-1 h-11 px-4 py-3 text-sm font-normal bg-white dark:bg-neutral-900 dark:focus:bg-neutral-900"
                      dropdownClass="!dark:bg-neutral-900 dark:text-neutral-800"
                    />
                  </>
                ) : (
                  <>
                    <label> Enter your Email Address</label>
                    <Input
                      type="email"
                      name="email"
                      className="mt-1 rounded dark:text-white"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </>
                )}
              </div>
            </>
          </div>
          <div>
            <ButtonPrimary
              className={phone === "" || undefined ? "cursor-not-allowed" : ""}
              type="submit"
              disabled={
                phone === "" || undefined ? true : false || forgotpass.isLoading
              }
              loading={forgotpass.isLoading}
            >
              Send Otp
            </ButtonPrimary>
          </div>
        </form>
      )}
    </div>
  );
};

export default ForgotPass;
