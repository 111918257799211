import { FC, useEffect } from "react";
import { Tab } from "@headlessui/react";
import { useState, Fragment } from "react";
import Input from "../../shared/Input/Input";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";
import avaterPic from "../../images/avatars/avater.png";
import axios from "axios";
import config from "../../utils/config";
import Config from "../../utils/config";
import { CISUI } from "../../utils/CISUI";
import { useHistory } from "react-router-dom";
import Loading from "components/Loading/Loading";
import Label from "components/Label";
import PhoneInput from "react-phone-input-2";

interface UserInfoInface {
  firstname?: any;
  lastname?: any;
  phone?: any;
  email?: any;
  address?: any;
  country?: any;
  city?: any;
  password?: any;
  password_confirmation?: any;
  picture?: any;
  errors?: {};
  current_password?: any;
  countryCode?: any;
  otp?: any;
}

export interface ProfileUpdateProps {
  className?: string;
}
let gotEmail = "";
const ProfileUpdate: FC<ProfileUpdateProps> = () => {
  const [eyeToggle, setEyeToggle] = useState(false);
  const [state, setState] = useState<UserInfoInface>({});
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [popOver, setPopOver] = useState(false);
  const [stateId, setStateId] = useState(1);
  let urlParam = new URLSearchParams(window.location.search);
  let is_verified = urlParam.get("verified");
  const getUserInfo = () => {
    setLoading(true);
    axios
      .get(config.apiserver + "myaccount", CISUI().HeaderRequest)
      .then((res) => {
        // setState(res.data.data)
        gotEmail = res.data.data.email;
        setState({
          firstname: res.data.data.firstname,
          lastname: res.data.data.lastname,
          phone: res.data.data.phone,
          email: res.data.data.email,
          address: res.data.data.address,
          country: res.data.data.country,
          city: res.data.data.city,
          picture: res.data.data.image,
        });
        setLoading(false);
      })
      .catch((error) => {
        CISUI().notification("error", error);
        setLoading(false);
      });
  };
  useEffect(() => {
    getUserInfo();
  }, []);

  const [errorMsg, setErrorMsg] = useState("");
  const [errors, setErrors] = useState({
    current_password: "",
    password: "",
    password_confirmation: "",
    first_name: "",
    last_name: "",
    address: "",
    email: "",
    phone: "",
    country: "",
  });

  const [country, setCountry] = useState<CounrtyInface[]>([]);
  const getCountry = () => {
    setLoading(true);
    axios
      .get(config.apiserver + "country")
      .then(function (res) {
        setCountry(res.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        CISUI().notification("error", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getCountry();
  }, []);
  let obj = localStorage.getItem("user");
  // @ts-ignore
  let user = JSON.parse(obj);
  let id = user.id;
  let [categories] = useState([
    "Account Info",
    "Change Password",
    "Change Phone Number",
  ]);
  interface CounrtyInface {
    name?: string;
  }

  let history = useHistory();
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const userData = {
      first_name: state.firstname,
      last_name: state.lastname,
      phone: state.phone,
      email: state.email,
      address: state.address,
      country: state.country,
      city: state.city,
      image: state.picture,
    };

    axios
      .post(
        Config.apiserver + `updatecustomeraccount/${id}`,
        userData,
        CISUI().HeaderRequest
      )
      .then((res: { data: any }) => {
        setLoading(true);
        if (res.data.status === 1) {
          axios.defaults.headers.common["Authorization"] =
            res.data.access_token;
          // eslint-disable-next-line no-restricted-globals

          if (state.email !== gotEmail) {
            localStorage.removeItem("access_token");
            localStorage.removeItem("user");
            localStorage.removeItem("login_type");
            CISUI().notification(
              "success",
              "Successfully updated. Please Login again with the new email"
            );
            history.push("/login/customer");
          } else {
            CISUI().notification("success", "Successfully updated");
          }
        } else {
          if (res.data.msg !== undefined) {
            CISUI().notification("error", res.data.msg);
          } else {
            CISUI().notification("error", res.data.msg);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setErrors(error.response.data.errors);
        CISUI().notification("error", error);
        setLoading(false);
      });
  };

  const handlePasswordSubmit = (e: any) => {
    e.preventDefault();
    if (state.password === state.password_confirmation) {
      const PasswordData = {
        current_password: state.current_password,
        new_password: state.password,
        new_password_confirmation: state.password_confirmation,
      };

      axios
        .post(
          Config.apiserver + `updatepassword/${id}`,
          PasswordData,
          CISUI().HeaderRequest
        )
        .then((res: { data: any }) => {
          if (res.data.status === 1) {
            axios.defaults.headers.common["Authorization"] =
              res.data.access_token;
            localStorage.removeItem("access_token");
            localStorage.removeItem("user");
            localStorage.removeItem("login_type");
            CISUI().notification("success", res.data.msg);
            history.push("/login/customer");
          } else {
            CISUI().notification("error", res.data.msg);
          }
        })
        .catch((error) => {
          setErrors(error.response.data.errors);
          CISUI().notification("error", error);
        });
    } else {
      CISUI().notification(
        "error",
        "Password and Confirm Password does not match"
      );
    }
  };
  const handlePhoneSubmit = (e: any) => {
    setLoading(true);
    axios
      .post(
        Config.apiserver + `changephone`,
        { phone: state.phone },
        CISUI().HeaderRequest
      )
      .then((res) => {
        if (res.data.status === 1) {
          setLoading(false);
          CISUI().notification("success", res.data.msg);
          setStateId(2);
        } else {
          setLoading(false);
          CISUI().notification("success", res.data.msg);
        }
      })
      .catch((error) => {
        setLoading(false);
        CISUI().notification("error", error);
        // setLoading(false)
      });
  };
  const handleOtpSubmit = (e: any) => {
    e.preventDefault();
    const userData = {
      phone: state.phone,
      code: state.otp,
    };
    axios
      .post(config.apiserver + `verifyphonev2`, userData)
      .then((res: { data: any }) => {
        if (res.data.status === 1) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("user");
          localStorage.removeItem("login_type");
          history.push("/login/customer");
          CISUI().notification("success", res.data.msg);
        } else {
          if (res.data.msg !== undefined) {
            setErrorMsg(res.data.msg);
          } else {
            CISUI().notification("error", res.data.msg);
          }
        }
      })
      .catch((error) => {
        CISUI().notification("error", "Error");
      });
  };
  const changeInput = (e: any) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  // @ts-ignore
  var openFile = function (event) {
    var input = event.target;
    var reader = new FileReader();

    reader.onload = function () {
      var dataURL = reader.result;
      var output = document.getElementById("output");
      // @ts-ignore
      output.src = dataURL;
      state.picture = dataURL;
    };
    reader.readAsDataURL(input.files[0]);
  };
  // @ts-ignore
  let cityname: any = [];

  const getPrediction = async () => {
    setPopOver(false);
    if (state?.address?.length >= 2) {
      // setShowPopover(true)
      setPopOver(true);
      var results: any = await googleAutocomplete(state?.address);

      if (results) {
        results?.forEach((item: any) => {
          cityname.push(item.description);
        });
        setResults(cityname);
      }
    } else {
      setPopOver(false);
    }
  };

  const googleAutocomplete = async (text: any) =>
    // log
    new Promise((resolve, reject) => {
      if (!text) {
        return reject("Need valid text input");
      }
      // for use in things like GatsbyJS where the html is generated first
      if (typeof window === "undefined") {
        return reject("Need valid window object");
      }
      try {
        new window.google.maps.places.AutocompleteService().getPlacePredictions(
          { input: text },
          resolve
        );
      } catch (e) {
        reject(e);
      }
    });
  const handleSelectLocation = (item: any) => {
    setState({
      ...state,
      "address": item,
    });
    setPopOver(false);
  };
  return (
    <Tab.Group>
      <Tab.List className="flex space-x-1 overflow-x-auto">
        {categories.map((item) => (
          <Tab key={item} as={Fragment}>
            {({ selected }) => (
              <button
                className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                  selected
                    ? "bg-secondary-900 text-secondary-50 "
                    : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                } `}
              >
                {item}
              </button>
            )}
          </Tab>
        ))}
      </Tab.List>
      {loading ? (
        <Loading />
      ) : (
        <Tab.Panels>
          <Tab.Panel className="">
            <form className="flex md:flex-row" onSubmit={handleSubmit}>
              <div className="flex-grow mt-10 md:mt-0 max-w-3xl space-y-6">
                <div className="flex-shrink-0 flex items-start">
                  <div className="relative rounded-full overflow-hidden flex">
                    <div
                      className={`wil-avatar relative flex-shrink-0 inline-flex items-center justify-center text-neutral-100 uppercase font-semibold shadow-inner rounded-full w-32 h-32 text-sm ring-1 ring-white dark:ring-neutral-900`}
                    >
                      <img
                        id="output"
                        src={
                          state.picture
                            ? Config.imageURL + state.picture
                            : avaterPic
                        }
                        alt="avatar"
                        className={`absolute inset-0 w-full h-full object-cover rounded-full`}
                      />
                    </div>
                    {/*<Avatar imgUrl={pic} sizeClass="w-32 h-32" />*/}
                    {/*<img id='output' sizes="w-32 h-32"/>*/}
                    <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                          stroke="currentColor"
                          strokeWidth={1.5}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <span className="mt-1 text-xs">Change Image</span>
                    </div>
                    <input
                      type="file"
                      className="absolute inset-0 opacity-0 cursor-pointer"
                      name="picture"
                      onChange={(e) => openFile(e)}
                    />
                  </div>
                </div>
                <div>
                  <Label>First Name</Label>
                  <Input
                    name="firstname"
                    className="mt-1.5"
                    value={state.firstname}
                    onChange={(e) => changeInput(e)}
                  />
                  {errors && (
                    <p style={{ color: "red" }}>{errors.first_name}</p>
                  )}
                </div>
                <div>
                  <Label>Last Name</Label>
                  <Input
                    name="lastname"
                    className="mt-1.5"
                    value={state.lastname}
                    onChange={(e) => changeInput(e)}
                  />
                  {errors && <p style={{ color: "red" }}>{errors.last_name}</p>}
                </div>
                <div>
                  <Label>Email</Label>
                  <Input
                    name="email"
                    className="mt-1.5"
                    value={state.email}
                    onChange={(e) => changeInput(e)}
                  />
                  {errors && <p style={{ color: "red" }}>{errors.email}</p>}
                </div>
                {/* ---- */}
                <div>
                  <Label>Addess</Label>
                  <Input
                    name="address"
                    type="text"
                    placeholder="Address"
                    className="mt-1"
                    value={state.address}
                    onChange={(e) => {
                      getPrediction();
                      changeInput(e);
                    }}
                  />
                  {popOver && (
                    <div className="mt-2 absolute bg-white dark:bg-neutral-800 min-w-[350px] sm:min-w-[500px] shadow-2xl rounded-2xl z-50">
                      {results.map((item: any) => (
                        <span
                          onClick={() => handleSelectLocation(item)}
                          key={item}
                          className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 sm:py-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer rounded-2xl"
                        >
                          <span className=" block font-medium text-neutral-700 dark:text-neutral-200">
                            {item}
                          </span>
                        </span>
                      ))}
                    </div>
                  )}
                </div>
                <div>
                  <label className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300">
                    Country
                  </label>
                  <select
                    name="country"
                    placeholder="Country Name"
                    className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 mt-1 h-11 px-4 py-3 text-sm font-normal rounded-2xl"
                    onChange={(e) => changeInput(e)}
                    value={state.country}
                  >
                    <option value="">Select an option</option>
                    {country.map((item: any, index: number) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="pt-2">
                  <ButtonPrimary>Update info</ButtonPrimary>
                </div>
              </div>
            </form>
          </Tab.Panel>
          <Tab.Panel className="">
            <form
              className=" max-w-xl space-y-6"
              onSubmit={handlePasswordSubmit}
            >
              <div>
                <Label>
                  Current Password
                  <sup style={{ color: "red", fontSize: "15px" }}>*</sup>
                </Label>
                <div className="relative flex w-full flex-wrap items-stretch mb-3">
                  <input
                    name="current_password"
                    value={state.current_password}
                    onChange={(e) => changeInput(e)}
                    type={eyeToggle ? "text" : "password"}
                    placeholder="Current Password"
                    className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1.5"
                  />
                  <span
                    className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-4"
                    onClick={() => setEyeToggle(!eyeToggle)}
                  >
                    {eyeToggle ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                        />
                      </svg>
                    )}
                  </span>
                </div>

                {errors && (
                  <p style={{ color: "red" }}>{errors.current_password}</p>
                )}
              </div>
              <div>
                <Label>
                  New password{" "}
                  <sup style={{ color: "red", fontSize: "15px" }}>*</sup>
                </Label>
                {/*<Input name="password" type="password" className="mt-1.5"*/}
                {/*       value={state.password} onChange={(e) => changeInput(e)}/>*/}

                <div className="relative flex w-full flex-wrap items-stretch mb-3">
                  <input
                    name="password"
                    value={state.password}
                    onChange={(e) => changeInput(e)}
                    type={eyeToggle ? "text" : "password"}
                    placeholder="New Password"
                    className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1.5"
                  />
                  <span
                    className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-4"
                    onClick={() => setEyeToggle(!eyeToggle)}
                  >
                    {eyeToggle ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                        />
                      </svg>
                    )}
                  </span>
                </div>

                {errors && <p style={{ color: "red" }}>{errors.password}</p>}
              </div>
              <div>
                <Label>
                  Confirm password{" "}
                  <sup style={{ color: "red", fontSize: "15px" }}>*</sup>
                </Label>
                {/*<Input name="password_confirmation" type="password" className="mt-1.5"*/}
                {/*       value={state.password_confirmation} onChange={(e) => changeInput(e)}/>*/}

                <div className="relative flex w-full flex-wrap items-stretch mb-3">
                  <input
                    name="password_confirmation"
                    value={state.password_confirmation}
                    onChange={(e) => changeInput(e)}
                    type={eyeToggle ? "text" : "password"}
                    placeholder="Confirm Password"
                    className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1.5"
                  />
                  <span
                    className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-4"
                    onClick={() => setEyeToggle(!eyeToggle)}
                  >
                    {eyeToggle ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                        />
                      </svg>
                    )}
                  </span>
                </div>
                {errors && (
                  <p style={{ color: "red" }}>{errors.password_confirmation}</p>
                )}
              </div>
              <div className="pt-2">
                <ButtonPrimary>Update password</ButtonPrimary>
              </div>
            </form>
          </Tab.Panel>
          <Tab.Panel>
            {stateId === 1 ? (
              <form
                className="max-w-xl space-y-6 w-full"
                onSubmit={handlePhoneSubmit}
              >
                <div>
                  <Label>
                    Enter Your Phone Number
                    <sup style={{ color: "red", fontSize: "15px" }}>*</sup>
                  </Label>
                  {/*<Input name="email" type="email" className="mt-1.5" value={state.phone} onChange={(e) => changeInput(e)} required placeholder="Email" />*/}
                </div>
                <div className={"grid grid-cols-5 gap-1"}>
                  {/* <div className="col-span-2">
                    <select
                      className="`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 mt-1 h-11 px-4 py-3 text-sm font-normal rounded-2xl"
                      name="countryCode"
                      onChange={(e) => changeInput(e)}
                    >
                      <option data-countryCode="GB" value="44" selected={true}>
                        UK (+44)
                      </option>
                      <optgroup label="Other countries">
                        <option data-countryCode="DZ" value="213">
                          Algeria (+213)
                        </option>
                        <option data-countryCode="AD" value="376">
                          Andorra (+376)
                        </option>
                        <option data-countryCode="AO" value="244">
                          Angola (+244)
                        </option>
                        <option data-countryCode="AI" value="1264">
                          Anguilla (+1264)
                        </option>
                        <option data-countryCode="AG" value="1268">
                          Antigua &amp; Barbuda (+1268)
                        </option>
                        <option data-countryCode="AR" value="54">
                          Argentina (+54)
                        </option>
                        <option data-countryCode="AM" value="374">
                          Armenia (+374)
                        </option>
                        <option data-countryCode="AW" value="297">
                          Aruba (+297)
                        </option>
                        <option data-countryCode="AU" value="61">
                          Australia (+61)
                        </option>
                        <option data-countryCode="AT" value="43">
                          Austria (+43)
                        </option>
                        <option data-countryCode="AZ" value="994">
                          Azerbaijan (+994)
                        </option>
                        <option data-countryCode="BS" value="1242">
                          Bahamas (+1242)
                        </option>
                        <option data-countryCode="BH" value="973">
                          Bahrain (+973)
                        </option>
                        <option data-countryCode="BD" value="880">
                          Bangladesh (+880)
                        </option>
                        <option data-countryCode="BB" value="1246">
                          Barbados (+1246)
                        </option>
                        <option data-countryCode="BY" value="375">
                          Belarus (+375)
                        </option>
                        <option data-countryCode="BE" value="32">
                          Belgium (+32)
                        </option>
                        <option data-countryCode="BZ" value="501">
                          Belize (+501)
                        </option>
                        <option data-countryCode="BJ" value="229">
                          Benin (+229)
                        </option>
                        <option data-countryCode="BM" value="1441">
                          Bermuda (+1441)
                        </option>
                        <option data-countryCode="BT" value="975">
                          Bhutan (+975)
                        </option>
                        <option data-countryCode="BO" value="591">
                          Bolivia (+591)
                        </option>
                        <option data-countryCode="BA" value="387">
                          Bosnia Herzegovina (+387)
                        </option>
                        <option data-countryCode="BW" value="267">
                          Botswana (+267)
                        </option>
                        <option data-countryCode="BR" value="55">
                          Brazil (+55)
                        </option>
                        <option data-countryCode="BN" value="673">
                          Brunei (+673)
                        </option>
                        <option data-countryCode="BG" value="359">
                          Bulgaria (+359)
                        </option>
                        <option data-countryCode="BF" value="226">
                          Burkina Faso (+226)
                        </option>
                        <option data-countryCode="BI" value="257">
                          Burundi (+257)
                        </option>
                        <option data-countryCode="KH" value="855">
                          Cambodia (+855)
                        </option>
                        <option data-countryCode="CM" value="237">
                          Cameroon (+237)
                        </option>
                        <option data-countryCode="CA" value="1">
                          Canada (+1)
                        </option>
                        <option data-countryCode="CV" value="238">
                          Cape Verde Islands (+238)
                        </option>
                        <option data-countryCode="KY" value="1345">
                          Cayman Islands (+1345)
                        </option>
                        <option data-countryCode="CF" value="236">
                          Central African Republic (+236)
                        </option>
                        <option data-countryCode="CL" value="56">
                          Chile (+56)
                        </option>
                        <option data-countryCode="CN" value="86">
                          China (+86)
                        </option>
                        <option data-countryCode="CO" value="57">
                          Colombia (+57)
                        </option>
                        <option data-countryCode="KM" value="269">
                          Comoros (+269)
                        </option>
                        <option data-countryCode="CG" value="242">
                          Congo (+242)
                        </option>
                        <option data-countryCode="CK" value="682">
                          Cook Islands (+682)
                        </option>
                        <option data-countryCode="CR" value="506">
                          Costa Rica (+506)
                        </option>
                        <option data-countryCode="HR" value="385">
                          Croatia (+385)
                        </option>
                        <option data-countryCode="CU" value="53">
                          Cuba (+53)
                        </option>
                        <option data-countryCode="CY" value="90392">
                          Cyprus North (+90392)
                        </option>
                        <option data-countryCode="CY" value="357">
                          Cyprus South (+357)
                        </option>
                        <option data-countryCode="CZ" value="42">
                          Czech Republic (+42)
                        </option>
                        <option data-countryCode="DK" value="45">
                          Denmark (+45)
                        </option>
                        <option data-countryCode="DJ" value="253">
                          Djibouti (+253)
                        </option>
                        <option data-countryCode="DM" value="1809">
                          Dominica (+1809)
                        </option>
                        <option data-countryCode="DO" value="1809">
                          Dominican Republic (+1809)
                        </option>
                        <option data-countryCode="EC" value="593">
                          Ecuador (+593)
                        </option>
                        <option data-countryCode="EG" value="20">
                          Egypt (+20)
                        </option>
                        <option data-countryCode="SV" value="503">
                          El Salvador (+503)
                        </option>
                        <option data-countryCode="GQ" value="240">
                          Equatorial Guinea (+240)
                        </option>
                        <option data-countryCode="ER" value="291">
                          Eritrea (+291)
                        </option>
                        <option data-countryCode="EE" value="372">
                          Estonia (+372)
                        </option>
                        <option data-countryCode="ET" value="251">
                          Ethiopia (+251)
                        </option>
                        <option data-countryCode="FK" value="500">
                          Falkland Islands (+500)
                        </option>
                        <option data-countryCode="FO" value="298">
                          Faroe Islands (+298)
                        </option>
                        <option data-countryCode="FJ" value="679">
                          Fiji (+679)
                        </option>
                        <option data-countryCode="FI" value="358">
                          Finland (+358)
                        </option>
                        <option data-countryCode="FR" value="33">
                          France (+33)
                        </option>
                        <option data-countryCode="GF" value="594">
                          French Guiana (+594)
                        </option>
                        <option data-countryCode="PF" value="689">
                          French Polynesia (+689)
                        </option>
                        <option data-countryCode="GA" value="241">
                          Gabon (+241)
                        </option>
                        <option data-countryCode="GM" value="220">
                          Gambia (+220)
                        </option>
                        <option data-countryCode="GE" value="7880">
                          Georgia (+7880)
                        </option>
                        <option data-countryCode="DE" value="49">
                          Germany (+49)
                        </option>
                        <option data-countryCode="GH" value="233">
                          Ghana (+233)
                        </option>
                        <option data-countryCode="GI" value="350">
                          Gibraltar (+350)
                        </option>
                        <option data-countryCode="GR" value="30">
                          Greece (+30)
                        </option>
                        <option data-countryCode="GL" value="299">
                          Greenland (+299)
                        </option>
                        <option data-countryCode="GD" value="1473">
                          Grenada (+1473)
                        </option>
                        <option data-countryCode="GP" value="590">
                          Guadeloupe (+590)
                        </option>
                        <option data-countryCode="GU" value="671">
                          Guam (+671)
                        </option>
                        <option data-countryCode="GT" value="502">
                          Guatemala (+502)
                        </option>
                        <option data-countryCode="GN" value="224">
                          Guinea (+224)
                        </option>
                        <option data-countryCode="GW" value="245">
                          Guinea - Bissau (+245)
                        </option>
                        <option data-countryCode="GY" value="592">
                          Guyana (+592)
                        </option>
                        <option data-countryCode="HT" value="509">
                          Haiti (+509)
                        </option>
                        <option data-countryCode="HN" value="504">
                          Honduras (+504)
                        </option>
                        <option data-countryCode="HK" value="852">
                          Hong Kong (+852)
                        </option>
                        <option data-countryCode="HU" value="36">
                          Hungary (+36)
                        </option>
                        <option data-countryCode="IS" value="354">
                          Iceland (+354)
                        </option>
                        <option data-countryCode="IN" value="91">
                          India (+91)
                        </option>
                        <option data-countryCode="ID" value="62">
                          Indonesia (+62)
                        </option>
                        <option data-countryCode="IR" value="98">
                          Iran (+98)
                        </option>
                        <option data-countryCode="IQ" value="964">
                          Iraq (+964)
                        </option>
                        <option data-countryCode="IE" value="353">
                          Ireland (+353)
                        </option>
                        <option data-countryCode="IL" value="972">
                          Israel (+972)
                        </option>
                        <option data-countryCode="IT" value="39">
                          Italy (+39)
                        </option>
                        <option data-countryCode="JM" value="1876">
                          Jamaica (+1876)
                        </option>
                        <option data-countryCode="JP" value="81">
                          Japan (+81)
                        </option>
                        <option data-countryCode="JO" value="962">
                          Jordan (+962)
                        </option>
                        <option data-countryCode="KZ" value="7">
                          Kazakhstan (+7)
                        </option>
                        <option data-countryCode="KE" value="254">
                          Kenya (+254)
                        </option>
                        <option data-countryCode="KI" value="686">
                          Kiribati (+686)
                        </option>
                        <option data-countryCode="KP" value="850">
                          Korea North (+850)
                        </option>
                        <option data-countryCode="KR" value="82">
                          Korea South (+82)
                        </option>
                        <option data-countryCode="KW" value="965">
                          Kuwait (+965)
                        </option>
                        <option data-countryCode="KG" value="996">
                          Kyrgyzstan (+996)
                        </option>
                        <option data-countryCode="LA" value="856">
                          Laos (+856)
                        </option>
                        <option data-countryCode="LV" value="371">
                          Latvia (+371)
                        </option>
                        <option data-countryCode="LB" value="961">
                          Lebanon (+961)
                        </option>
                        <option data-countryCode="LS" value="266">
                          Lesotho (+266)
                        </option>
                        <option data-countryCode="LR" value="231">
                          Liberia (+231)
                        </option>
                        <option data-countryCode="LY" value="218">
                          Libya (+218)
                        </option>
                        <option data-countryCode="LI" value="417">
                          Liechtenstein (+417)
                        </option>
                        <option data-countryCode="LT" value="370">
                          Lithuania (+370)
                        </option>
                        <option data-countryCode="LU" value="352">
                          Luxembourg (+352)
                        </option>
                        <option data-countryCode="MO" value="853">
                          Macao (+853)
                        </option>
                        <option data-countryCode="MK" value="389">
                          Macedonia (+389)
                        </option>
                        <option data-countryCode="MG" value="261">
                          Madagascar (+261)
                        </option>
                        <option data-countryCode="MW" value="265">
                          Malawi (+265)
                        </option>
                        <option data-countryCode="MY" value="60">
                          Malaysia (+60)
                        </option>
                        <option data-countryCode="MV" value="960">
                          Maldives (+960)
                        </option>
                        <option data-countryCode="ML" value="223">
                          Mali (+223)
                        </option>
                        <option data-countryCode="MT" value="356">
                          Malta (+356)
                        </option>
                        <option data-countryCode="MH" value="692">
                          Marshall Islands (+692)
                        </option>
                        <option data-countryCode="MQ" value="596">
                          Martinique (+596)
                        </option>
                        <option data-countryCode="MR" value="222">
                          Mauritania (+222)
                        </option>
                        <option data-countryCode="YT" value="269">
                          Mayotte (+269)
                        </option>
                        <option data-countryCode="MX" value="52">
                          Mexico (+52)
                        </option>
                        <option data-countryCode="FM" value="691">
                          Micronesia (+691)
                        </option>
                        <option data-countryCode="MD" value="373">
                          Moldova (+373)
                        </option>
                        <option data-countryCode="MC" value="377">
                          Monaco (+377)
                        </option>
                        <option data-countryCode="MN" value="976">
                          Mongolia (+976)
                        </option>
                        <option data-countryCode="MS" value="1664">
                          Montserrat (+1664)
                        </option>
                        <option data-countryCode="MA" value="212">
                          Morocco (+212)
                        </option>
                        <option data-countryCode="MZ" value="258">
                          Mozambique (+258)
                        </option>
                        <option data-countryCode="MN" value="95">
                          Myanmar (+95)
                        </option>
                        <option data-countryCode="NA" value="264">
                          Namibia (+264)
                        </option>
                        <option data-countryCode="NR" value="674">
                          Nauru (+674)
                        </option>
                        <option data-countryCode="NP" value="977">
                          Nepal (+977)
                        </option>
                        <option data-countryCode="NL" value="31">
                          Netherlands (+31)
                        </option>
                        <option data-countryCode="NC" value="687">
                          New Caledonia (+687)
                        </option>
                        <option data-countryCode="NZ" value="64">
                          New Zealand (+64)
                        </option>
                        <option data-countryCode="NI" value="505">
                          Nicaragua (+505)
                        </option>
                        <option data-countryCode="NE" value="227">
                          Niger (+227)
                        </option>
                        <option data-countryCode="NG" value="234">
                          Nigeria (+234)
                        </option>
                        <option data-countryCode="NU" value="683">
                          Niue (+683)
                        </option>
                        <option data-countryCode="NF" value="672">
                          Norfolk Islands (+672)
                        </option>
                        <option data-countryCode="NP" value="670">
                          Northern Marianas (+670)
                        </option>
                        <option data-countryCode="NO" value="47">
                          Norway (+47)
                        </option>
                        <option data-countryCode="OM" value="968">
                          Oman (+968)
                        </option>
                        <option data-countryCode="PW" value="680">
                          Palau (+680)
                        </option>
                        <option data-countryCode="PA" value="507">
                          Panama (+507)
                        </option>
                        <option data-countryCode="PG" value="675">
                          Papua New Guinea (+675)
                        </option>
                        <option data-countryCode="PY" value="595">
                          Paraguay (+595)
                        </option>
                        <option data-countryCode="PE" value="51">
                          Peru (+51)
                        </option>
                        <option data-countryCode="PH" value="63">
                          Philippines (+63)
                        </option>
                        <option data-countryCode="PL" value="48">
                          Poland (+48)
                        </option>
                        <option data-countryCode="PT" value="351">
                          Portugal (+351)
                        </option>
                        <option data-countryCode="PR" value="1787">
                          Puerto Rico (+1787)
                        </option>
                        <option data-countryCode="QA" value="974">
                          Qatar (+974)
                        </option>
                        <option data-countryCode="RE" value="262">
                          Reunion (+262)
                        </option>
                        <option data-countryCode="RO" value="40">
                          Romania (+40)
                        </option>
                        <option data-countryCode="RU" value="7">
                          Russia (+7)
                        </option>
                        <option data-countryCode="RW" value="250">
                          Rwanda (+250)
                        </option>
                        <option data-countryCode="SM" value="378">
                          San Marino (+378)
                        </option>
                        <option data-countryCode="ST" value="239">
                          Sao Tome &amp; Principe (+239)
                        </option>
                        <option data-countryCode="SA" value="966">
                          Saudi Arabia (+966)
                        </option>
                        <option data-countryCode="SN" value="221">
                          Senegal (+221)
                        </option>
                        <option data-countryCode="CS" value="381">
                          Serbia (+381)
                        </option>
                        <option data-countryCode="SC" value="248">
                          Seychelles (+248)
                        </option>
                        <option data-countryCode="SL" value="232">
                          Sierra Leone (+232)
                        </option>
                        <option data-countryCode="SG" value="65">
                          Singapore (+65)
                        </option>
                        <option data-countryCode="SK" value="421">
                          Slovak Republic (+421)
                        </option>
                        <option data-countryCode="SI" value="386">
                          Slovenia (+386)
                        </option>
                        <option data-countryCode="SB" value="677">
                          Solomon Islands (+677)
                        </option>
                        <option data-countryCode="SO" value="252">
                          Somalia (+252)
                        </option>
                        <option data-countryCode="ZA" value="27">
                          South Africa (+27)
                        </option>
                        <option data-countryCode="ES" value="34">
                          Spain (+34)
                        </option>
                        <option data-countryCode="LK" value="94">
                          Sri Lanka (+94)
                        </option>
                        <option data-countryCode="SH" value="290">
                          St. Helena (+290)
                        </option>
                        <option data-countryCode="KN" value="1869">
                          St. Kitts (+1869)
                        </option>
                        <option data-countryCode="SC" value="1758">
                          St. Lucia (+1758)
                        </option>
                        <option data-countryCode="SD" value="249">
                          Sudan (+249)
                        </option>
                        <option data-countryCode="SR" value="597">
                          Suriname (+597)
                        </option>
                        <option data-countryCode="SZ" value="268">
                          Swaziland (+268)
                        </option>
                        <option data-countryCode="SE" value="46">
                          Sweden (+46)
                        </option>
                        <option data-countryCode="CH" value="41">
                          Switzerland (+41)
                        </option>
                        <option data-countryCode="SI" value="963">
                          Syria (+963)
                        </option>
                        <option data-countryCode="TW" value="886">
                          Taiwan (+886)
                        </option>
                        <option data-countryCode="TJ" value="7">
                          Tajikstan (+7)
                        </option>
                        <option data-countryCode="TH" value="66">
                          Thailand (+66)
                        </option>
                        <option data-countryCode="TG" value="228">
                          Togo (+228)
                        </option>
                        <option data-countryCode="TO" value="676">
                          Tonga (+676)
                        </option>
                        <option data-countryCode="TT" value="1868">
                          Trinidad &amp; Tobago (+1868)
                        </option>
                        <option data-countryCode="TN" value="216">
                          Tunisia (+216)
                        </option>
                        <option data-countryCode="TR" value="90">
                          Turkey (+90)
                        </option>
                        <option data-countryCode="TM" value="7">
                          Turkmenistan (+7)
                        </option>
                        <option data-countryCode="TM" value="993">
                          Turkmenistan (+993)
                        </option>
                        <option data-countryCode="TC" value="1649">
                          Turks &amp; Caicos Islands (+1649)
                        </option>
                        <option data-countryCode="TV" value="688">
                          Tuvalu (+688)
                        </option>
                        <option data-countryCode="UG" value="256">
                          Uganda (+256)
                        </option>
                        <option data-countryCode="GB" value="44">
                          UK (+44)
                        </option>
                        <option data-countryCode="UA" value="380">
                          Ukraine (+380)
                        </option>
                        <option data-countryCode="AE" value="971">
                          United Arab Emirates (+971)
                        </option>
                        <option data-countryCode="UY" value="598">
                          Uruguay (+598)
                        </option>
                        <option data-countryCode="US" value="1">
                          USA (+1)
                        </option>
                        <option data-countryCode="UZ" value="7">
                          Uzbekistan (+7)
                        </option>
                        <option data-countryCode="VU" value="678">
                          Vanuatu (+678)
                        </option>
                        <option data-countryCode="VA" value="379">
                          Vatican City (+379)
                        </option>
                        <option data-countryCode="VE" value="58">
                          Venezuela (+58)
                        </option>
                        <option data-countryCode="VN" value="84">
                          Vietnam (+84)
                        </option>
                        <option data-countryCode="VG" value="84">
                          Virgin Islands - British (+1284)
                        </option>
                        <option data-countryCode="VI" value="84">
                          Virgin Islands - US (+1340)
                        </option>
                        <option data-countryCode="WF" value="681">
                          Wallis &amp; Futuna (+681)
                        </option>
                        <option data-countryCode="YE" value="969">
                          Yemen (North)(+969)
                        </option>
                        <option data-countryCode="YE" value="967">
                          Yemen (South)(+967)
                        </option>
                        <option data-countryCode="ZM" value="260">
                          Zambia (+260)
                        </option>
                        <option data-countryCode="ZW" value="263">
                          Zimbabwe (+263)
                        </option>
                      </optgroup>
                    </select>
                  </div>
                  <div className="col-span-3">
                    <Input
                      name="phone"
                      type="tel"
                      className="mt-1"
                      placeholder="Enter your mobile number"
                      value={state.phone}
                      onChange={(e) => changeInput(e)}
                    />
                  </div> */}
                  {/* <div className="col-span-5">
                    <PhoneInput
                      country="us"
                      value={state.phone}
                      onChange={(phone) =>
                        setState({ ...state, phone: `+${phone}` })
                      }
                      inputClass="block !w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 mt-1 h-11 px-4 py-3 text-sm font-normal bg-white dark:bg-neutral-900 dark:focus:bg-neutral-900"
                      dropdownClass="!dark:bg-neutral-900 dark:text-neutral-800"
                    />
                  </div> */}
                </div>
                <div className="col-span-5">
                  <PhoneInput
                    country="us"
                    value={state.phone}
                    onChange={(phone) =>
                      setState({ ...state, phone: `+${phone}` })
                    }
                    inputClass="block !w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 mt-1 h-11 px-4 py-3 text-sm font-normal bg-white dark:bg-neutral-900 dark:focus:bg-neutral-900"
                    dropdownClass="!dark:bg-neutral-900 dark:text-neutral-800"
                  />
                </div>
                <div>
                  <ButtonPrimary
                    className={
                      state?.phone === "" || undefined
                        ? "cursor-not-allowed"
                        : ""
                    }
                    type="submit"
                    disabled={state?.phone === "" || undefined ? true : false}
                    loading={loading}
                  >
                    Send Otp
                  </ButtonPrimary>
                </div>
              </form>
            ) : stateId === 2 ? (
              <div className="container mb-24 lg:mb-32">
                <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                  OTP Verification
                </h2>
                <div className="max-w-md mx-auto space-y-6">
                  {errorMsg !== "" && (
                    <p
                      className="login_error"
                      style={{
                        textAlign: "center",
                        fontSize: 20,
                        color: "red",
                      }}
                    >
                      {errorMsg}
                    </p>
                  )}
                  {is_verified && (
                    <p
                      className="login_error"
                      style={{
                        textAlign: "center",
                        fontSize: 20,
                        color: "red",
                      }}
                    >
                      Your account is verified. Now you can login.
                    </p>
                  )}
                  <form
                    className="grid grid-cols-1 gap-6"
                    onSubmit={handleOtpSubmit}
                  >
                    <label className="block">
                      <span className="text-neutral-800 dark:text-neutral-200">
                        OTP code
                      </span>
                      <Input
                        name="otp"
                        type="text"
                        placeholder="Enter the otp code"
                        value={state.otp}
                        onChange={(e) => changeInput(e)}
                      />
                      {/*{*/}
                      {/*    errors &&*/}
                      {/*    <p style={{ color: "red" }}>{errors.phone}</p>*/}
                      {/*}*/}
                    </label>
                    <ButtonPrimary type="submit">Submit</ButtonPrimary>
                  </form>
                </div>
              </div>
            ) : (
              ""
            )}
          </Tab.Panel>
        </Tab.Panels>
      )}
    </Tab.Group>
  );
};
export default ProfileUpdate;
