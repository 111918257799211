import { useState } from "react";
import Input from "../../shared/Input/Input";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";
import axios from "axios";
import Config from "../../utils/config";
import { CISUI } from "../../utils/CISUI";
import { useHistory, useLocation } from "react-router-dom";
import Label from "components/Label";

interface UserInfoInface {
  code?: any;
  new_password?: any;
  confirm_password?: any;
  countryCode?: any;
}

const PassReset = () => {
  const [state, setState] = useState<UserInfoInface>({});
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const [errors, setErrors] = useState({
    code: "",
    new_password: "",
    confirm_password: "",
  });

  const [eyeToggle, setEyeToggle] = useState(false);
  let history = useHistory();

  const changeInput = (e: any) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handlePasswordSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);
    setErrors({
      code: "",
      new_password: "",
      confirm_password: "",
    });

    if (state.new_password === state.confirm_password) {
      let data = {
        phone: location.state,
        code: state.code,
        new_password: state.new_password,
      };

      axios
        .post(Config.apiserver + `passwordreset`, data)
        .then((res) => {
          setLoading(false);
          if (res.data.status === 1) {
            CISUI().notification("success", res.data.msg);
            history.push("/login/customer");
          } else {
            CISUI().notification("error", res.data.msg);
          }
        })
        .catch((error) => {
          setErrors(error.response.data.errors);
          setLoading(false);
          CISUI().notification("error", error);
        });
    } else {
      setErrors({
        code: "",
        new_password: "Password not match",
        confirm_password: "Password not match",
      });
    }
  };

  return (
    <div className="flex items-center justify-center mb-10">
      <form
        className="max-w-xl space-y-6 w-full"
        onSubmit={handlePasswordSubmit}
      >
        <h2 className="my-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Password Reset
        </h2>
        <div>
          <Label>
            Verified code{" "}
            <sup style={{ color: "red", fontSize: "15px" }}>*</sup>
          </Label>
          <Input
            name="code"
            type="text"
            className="mt-1.5"
            value={state.code}
            onChange={(e) => changeInput(e)}
            placeholder="Verified code"
          />
          {errors && <p style={{ color: "red" }}>{errors.code}</p>}
        </div>

        <div>
          <Label>
            New password <sup style={{ color: "red", fontSize: "15px" }}>*</sup>
          </Label>
          <div className="relative flex w-full flex-wrap items-stretch mb-3 mt-2">
            <input
              value={state.new_password}
              onChange={(e) => changeInput(e)}
              type={eyeToggle ? "text" : "password"}
              name="new_password"
              placeholder="New Password"
              className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3"
            />
            <span
              className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3"
              onClick={() => setEyeToggle(!eyeToggle)}
            >
              {eyeToggle ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                  />
                </svg>
              )}
            </span>
          </div>
          {errors && <p style={{ color: "red" }}>{errors.new_password}</p>}
        </div>
        <div>
          <Label>
            Confirm password{" "}
            <sup style={{ color: "red", fontSize: "15px" }}>*</sup>
          </Label>

          <div className="relative flex w-full flex-wrap items-stretch mb-3 mt-2">
            <input
              value={state.confirm_password}
              onChange={(e) => changeInput(e)}
              type={eyeToggle ? "text" : "password"}
              name="confirm_password"
              placeholder="Confirm password"
              className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3"
            />
            <span
              className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3"
              onClick={() => setEyeToggle(!eyeToggle)}
            >
              {eyeToggle ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                  />
                </svg>
              )}
            </span>
          </div>
          {errors && <p style={{ color: "red" }}>{errors.confirm_password}</p>}
        </div>
        <div className="pt-2">
          <ButtonPrimary loading={loading}>Change password</ButtonPrimary>
        </div>
      </form>
    </div>
  );
};

export default PassReset;
