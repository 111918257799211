// import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import config from "utils/config";
import moment from "moment";
// import Heading from "shared/Heading/Heading";
import Background from "components/Section/Background";
import HeroRealEstateSearchForm from "components/SearchForm/HeroRealEstateSearchForm";
import GallerySlider from "components/GallerySlider/GallerySlider";
import Loading from "components/Loading/Loading";
import { useQuery } from "react-query";

let PHOTOS: string[] = [];

const SearchList = (): JSX.Element => {
  // const [loading, setLoading] = useState(false);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const location = urlParams.get("l");
  const startDate = urlParams.get("sd");
  const endDate = urlParams.get("ed");
  const adultGuest: any = urlParams.get("ag");
  const childGuest: any = urlParams.get("cg");
  const infantGuest: any = urlParams.get("ig");
  const date = {
    startDate: moment(startDate, "MM-DD-YYYY"),
    endDate: moment(endDate, "MM-DD-YYYY"),
  };
  const guest = {
    guestAdults: parseInt(adultGuest),
    guestChildren: parseInt(childGuest),
    guestInfants: parseInt(infantGuest),
  };

  const { data: rooms, isLoading } = useQuery(
    [
      `searchproperty?check_in=${startDate}&check_out=${endDate}&location=${location}`,
    ],
    () =>
      axios
        .get(
          config.apiserver +
            `searchproperty?check_in=${startDate}&check_out=${endDate}&location=${location}`
        )
        .then((res) => res.data.data)
  );

  const content = (
    id: number,
    property_name: string,
    bed_room: string,
    bathroom: string,
    size: string,
    price: string,
    images: any[],
    is_featured: number,
    discount: number,
    address: string,
    latitude: string,
    longitude: string
  ): JSX.Element => {
    PHOTOS = [];
    images.map(({ room_id, image }) =>
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      room_id === id ? PHOTOS.push(config.imageURL + image) : ""
    );

    return (
      <div
        className={`nc-PropertyCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow h-full`}
        data-nc-id="PropertyCardH"
        // key={id}
      >
        <Link
          to={`/property-details/${id}`}
          className="w-full flex flex-col sm:flex-row sm:items-center"
        >
          <div className="flex-shrink-0 p-3 w-full h-full sm:w-64 ">
            <GallerySlider
              ratioClass="aspect-w-1 aspect-h-1"
              galleryImgs={PHOTOS}
              className="w-full h-full rounded-2xl overflow-hidden"
              uniqueID={`PropertyCardH-${Date.now()}-${id}`}
            />
            {discount === 0 ? (
              ""
            ) : (
              <div
                className={`nc-SaleOffBadge flex items-center justify-center text-xs py-0.5 px-3 text-red-50 rounded-full absolute left-5 top-5 bg-orange-500`}
                data-nc-id="SaleOffBadge"
              >
                {"-"} {discount} {"%"}
              </div>
            )}
          </div>
          <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
            <div className="space-y-4 w-full">
              <div className="flex items-center space-x-2">
                {/*<Badge name="ADS" color="green"/>*/}
                <h2 className="text-lg font-medium capitalize">
                  <span className="line-clamp-2">{property_name}</span>
                </h2>
              </div>
              <div className="flex items-center space-x-4">
                {/*<StartRating/>*/}
                {/*<span>·</span>*/}
                <i className="las la-map-marker-alt"></i>
                <span className="ml-1">{address}</span>
              </div>
              <div className="inline-grid grid-cols-3 gap-2">
                <div className="flex items-center space-x-2">
                  <span className="hidden sm:inline-block">
                    <i className="las la-bed text-lg" />
                  </span>
                  <span className="text-xs text-neutral-500 dark:text-neutral-400">
                    {bed_room} beds
                  </span>
                </div>

                <div className="flex items-center space-x-2">
                  <span className="hidden sm:inline-block">
                    <i className="las la-bath text-lg" />
                  </span>
                  <span className="text-xs text-neutral-500 dark:text-neutral-400">
                    {bathroom} baths
                  </span>
                </div>

                {/* ---- */}
                <div className="flex items-center space-x-2">
                  <span className="hidden sm:inline-block">
                    <i className="las la-expand-arrows-alt text-lg" />
                  </span>
                  <span className="text-xs text-neutral-500 dark:text-neutral-400">
                    {size} Sq. Fit
                  </span>
                </div>
              </div>
              <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 "></div>
              <div className="flex w-full justify-between items-end">
                <span className="flex items-center justify-center px-3 py-2 border border-secondary-500 rounded leading-none text-base font-medium text-secondary-500">
                  £{price}
                </span>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  };

  if (isLoading) return <Loading />;

  return (
    <>
      <div className="container relative space-y-24 mb-24 lg:space-y-32 lg:mb-32">
        <div className="relative py-16">
          <Background />
          <div className="nc-SectionGridFeatureProperty relative">
            <div className="flex flex-col relative">
              <HeroRealEstateSearchForm
                className="mb-10"
                location={location}
                date={date}
                guest={guest}
              />
              {/* <Heading>Search Result :</Heading> */}
              {rooms?.length === 0 ? (
                // <Heading desc="No Result found !" className="text-center"/>
                <span className="mt-2 md:mt-4 font-normal block text-lg text-neutral-500 dark:text-neutral-400 text-center">
                  No Result found !
                </span>
              ) : (
                ""
              )}
            </div>
            <div
              className={`grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-1 xl:grid-cols-2`}
            >
              {rooms?.map(
                ({
                  id,
                  property_name,
                  bed_room,
                  size,
                  name,
                  details,
                  balcony,
                  bathroom,
                  facilities,
                  kitchen,
                  other_room,
                  price,
                  discount,
                  room_type,
                  images,
                  is_featured,
                  address,
                  latitude,
                  longitude,
                }: any) =>
                  content(
                    id,
                    property_name,
                    bed_room,
                    bathroom,
                    size,
                    price,
                    images,
                    is_featured,
                    discount,
                    address,
                    latitude,
                    longitude
                  )
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchList;
