import Logo from "shared/Logo/Logo";
// import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import payment_dark from 'images/payment/accept_dark.png'
import payment_white from 'images/payment/accept_white.png'

import SocialsList from "shared/SocialsList/SocialsList";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "1",
    title: "Quick Link",
    menus: [
      { href: "/holiday-house", label: "Houses" },
      { href: "/apartment", label: "Apartments" },
      { href: "/short-term-rent", label: "Rooms" },
      // { href: "#", label: "Villas" },
      // { href: "#", label: "Hostels" },
      { href: "/terms", label: "Terms & Conditions" },
    ],
  },
  {
    id: "5",
    title: "Quick Link",
    menus: [
      { href: "/login/customer", label: "SignIn/SignUp" },
      // { href: "/contact", label: "General Q/A" },
      { href: "/complaint", label: "Complaint" },
      { href: "/contact", label: "Contact Us" },

    ],
  },
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      //Remove inline marginTop if you use Title
      <div key={index} className="text-sm" style={{ marginTop: "18px" }}>
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <>
      <div className="nc-Footer relative pt-12 lg:pt-12 border-t border-neutral-200 dark:border-neutral-700">
        <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-4 lg:gap-x-10 ">
          <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
            <div className="col-span-2 md:col-span-1">
              <Logo />
            </div>
            <div className="col-span-2 flex md:col-span-3 flex-col">
              {/* <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" /> */}
              <p className="text-sm text-neutral-6000 dark:text-neutral-300">We have a dedicated team of professionals on hand to give you best costumer experience , our costumer service is available 24/7 for the guests during your stay.</p>
              {/* <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" /> */}

            </div>
          </div>
          {widgetMenus.map(renderWidgetMenuItem)}
          <div className="text-sm" style={{ marginTop: "18px" }}>
            <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
              Accept Payments
            </h2>
            <div className="my-4 space-y-4">
              <img className="hidden dark:block w-full lg:w-3/4 xl:w-3/4" src={payment_dark} alt="Payment" />
              <img className="dark:hidden w-full lg:w-3/4  xl:w-3/4" src={payment_white} alt="Payment" />
            </div>

            <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
              Social Link
            </h2>

            <SocialsList className="mt-4" />



          </div>
        </div>

      </div>

      <div className="text-center py-4 text-neutral-6000 dark:text-neutral-300 text-xs mt-5 border-t border-neutral-200 dark:border-neutral-700">
        <p>Copyright &copy; {new Date().getFullYear()}. All rights reserved by <span className="font-semibold text-neutral-700 dark:text-neutral-200">Easy2Manage</span></p>
        <p className="text-xs mt-1">Technology Partner <a className="font-semibold text-neutral-700 dark:text-neutral-200" href="https://creativeitsoft.net" target="_blank" rel="noreferrer">Creative IT Soft</a></p>
      </div>
    </>
  );
};

export default Footer;
