// import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import axios from "axios";
// import config from "utils/config";
// import { CISUI } from '../../utils/CISUI'
import moment from "moment";
import { useQuery, useQueryClient } from "react-query";
import axiosinstance from "utils/AxiosInstance";
import Loading from "components/Loading/Loading";
import { useEffect } from "react";
import { GetPaymentStatus } from "utils/CISUI";

export interface PaymentHistoryCardProps {
  book_unqid?: string;
  image?: string;
  property_name?: string;
  discount_amount?: number;
  grand_total?: string;
  dates?: any[];
  room_id?: number;
  booking_id?: number;
  room_no?: string;
  debit?: number;
  payment_date?: string;
}
const PaymentHistoryCard = (): JSX.Element => {
  const queryClient = useQueryClient();

  const { data: bookings, isLoading } = useQuery(
    ["booking/paymenthistory"],
    () =>
      axiosinstance.get("booking/paymenthistory").then((res) => res.data)
  );

  useEffect(() => {
    return () => {
      queryClient.removeQueries("booking/paymenthistory");
    };
  }, []);

  if (bookings?.status === 0) {
    return (
      <div className="text-center text-2xl font-medium">Don't have any payment history found</div>
    );
  }

  if (isLoading) return <Loading />;

  return (
    <>
      {bookings?.data?.map((item: any) => (
        <div
          className={`nc-PropertyCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow h-full`}
          data-nc-id="PropertyCardH"
          key={item.book_unqid}
        >
          <Link
            to={`/customer/bookingdetails/${item.booking_id}`}
            className="w-full flex flex-col sm:flex-row sm:items-center"
          >
            <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
              <div className="space-y-4 w-full p-2">
                <div className="flex items-center space-x-2">
                  <h2 className="text-lg font-medium capitalize">
                    <span className="line-clamp-2">{item.property_name}</span>
                  </h2>
                </div>
                <div className="flex items-center space-x-2">
                  <h2 className="capitalize">
                    <span className="line-clamp-2">
                      Room Name: {item.room_no}
                    </span>
                  </h2>
                </div>
                <div className="flex items-center space-x-2">
                  <div className="flex items-center space-x-2">
                    <span className="sm:inline-block text-sm">
                      Payment Date:
                    </span>
                    <span className="text-md text-neutral-500 dark:text-neutral-400">
                      {moment(item.payment_date).format("DD MMM, YYYY")}
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-4">
                  <div className="xl:inline-grid grid-cols-2">
                    <div className="flex items-center space-x-2">
                      <span className="sm:inline-block text-sm">
                        Booking Unique ID:
                      </span>
                      <span className="text-md text-neutral-500 dark:text-neutral-400">
                        {item.book_unqid}
                      </span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <span className=" sm:inline-block text-sm">
                        Booking ID:
                      </span>
                      <span className="text-md text-neutral-500 dark:text-neutral-400">
                        {item.booking_id}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-4">
                  <div className="xl:inline-grid grid-cols-2">
                    <div className="flex items-center space-x-2">
                      <span className="sm:inline-block text-sm">
                        <i className="las la-calendar-check text-lg" />
                        Check-in:
                      </span>
                      <span className="text-md text-neutral-500 dark:text-neutral-400">
                        {moment(item.dates?.[0]?.[0]).format("DD MMM, YYYY")}
                      </span>
                    </div>

                    {/* ---- */}

                    <div className="flex items-center space-x-2">
                      <span className=" sm:inline-block text-sm">
                        <i className="las la-calendar-minus text-lg" />
                        Check-Out:
                      </span>
                      <span className="text-md text-neutral-500 dark:text-neutral-400">
                        {moment(
                          item.dates?.[item.dates?.length - 1]?.[0]
                        ).format("DD MMM, YYYY")}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 "></div>
                <div className="flex w-full justify-between items-end font-medium text-secondary-500">
                  <p>Total : £{item.debit}  {item.advance_pay === 1 ? <span className="text-xs text-green-500">(Advance Payment)</span> : null}</p>
                  {/* <p>Payment Status: {item.payment_status}</p> */}
                  {GetPaymentStatus(item.payment_status)}
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </>
  );
};

export default PaymentHistoryCard;
