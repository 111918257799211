import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import SocialsList from "shared/SocialsList/SocialsList";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import GoogleMapReact from "google-map-react";
import axios from "axios";
import config from "../../utils/config";
import { CISUI } from "../../utils/CISUI";
import ReCAPTCHA from "react-google-recaptcha";

// @ts-ignore
import DOMPurify from "dompurify";
import Loading from "components/Loading/Loading";
import Label from "components/Label";
import LocationMarker from "components/LocationMarker";
import { useQuery } from "react-query";
import PhoneInput from "react-phone-input-2";

export interface PageContactProps {
  className?: string;
}

export interface itemContactUs {
  image: string;
  name: string;
  body: string;
  keyword: string;
}

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const { data: contacts, isLoading } = useQuery(
    ["widgets?page_name=Contact US"],
    () =>
      axios
        .get(config.apiserver + "widgets?page_name=Contact US")
        .then((res) => res.data.data)
  );

  const createMarkup = (html: any) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };
  let lt = "51.514992";
  let lg = "-0.5879683";

  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    message: "",
  });
  const [reCaptcha, setReCaptcha] = useState<string | null | boolean>(false);

  // const [errorMsg, setErrorMsg] = useState("");
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    message: "",
  });

  const changeInput = (e: any) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  function onChange(value: any) {
    setReCaptcha(value);
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (reCaptcha === false) {
      setReCaptcha(null);
    } else {
      const data = {
        first_name: state.first_name,
        last_name: state.last_name,
        phone: state.phone,
        email: state.email,
        message: state.message,
      };

      axios
        .post(config.apiserver + `contactus`, data)
        .then((res) => {
          if (res.data.status === 1) {
            CISUI().notification("success", res.data.msg);
            setState({
              first_name: "",
              last_name: "",
              phone: "",
              email: "",
              message: "",
            });

            setErrors({
              first_name: "",
              last_name: "",
              email: "",
              phone: "",
              message: "",
            });
          } else {
            // setErrorMsg(res.data.msg);
          }
        })
        .catch((error) => {
          setErrors(error.response.data.errors);
        });
    }
  };

  return isLoading ? (
    <Loading />
  ) : (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Easy 2 Manage (E2M) || The Property Management Company</title>
      </Helmet>

      <div className="mb-10 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contact
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div className="items-center max-w-sm space-y-8 mt-14">
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🗺 ADDRESS
                </h3>
                <span
                  className="block mt-2 text-neutral-500 dark:text-neutral-400"
                  dangerouslySetInnerHTML={createMarkup(contacts?.[0]?.body)}
                >
                  {/*{address?.name}*/}
                </span>
              </div>
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  💌 EMAIL
                </h3>
                <span
                  className="block mt-2 text-neutral-500 dark:text-neutral-400"
                  dangerouslySetInnerHTML={createMarkup(contacts?.[1]?.body)}
                >
                  {/*{email?.name}*/}
                </span>
              </div>
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  ☎ PHONE
                </h3>
                <span
                  className="block mt-2 text-neutral-500 dark:text-neutral-400"
                  dangerouslySetInnerHTML={createMarkup(contacts?.[2]?.body)}
                >
                  {/*{phone?.name}*/}
                </span>
              </div>
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 SOCIALS
                </h3>
                <SocialsList className="mt-2" />
              </div>
            </div>

            <div>
              <form
                className="grid grid-cols-1 gap-6"
                action="#"
                method="post"
                onSubmit={handleSubmit}
              >
                <div className="grid grid-cols-2 gap-6">
                  <label className="block">
                    <Label>
                      First Name{" "}
                      <sup style={{ color: "red", fontSize: "15px" }}>*</sup>
                    </Label>
                    <Input
                      name="first_name"
                      placeholder="First Name"
                      type="text"
                      className="mt-1"
                      value={state.first_name}
                      onChange={(e) => changeInput(e)}
                    />
                    {errors && (
                      <p style={{ color: "red", fontSize: "15px" }}>
                        {errors.first_name}
                      </p>
                    )}
                  </label>
                  <label className="block">
                    <Label>
                      Last Name{" "}
                      <sup style={{ color: "red", fontSize: "11px" }}>*</sup>
                    </Label>
                    <Input
                      name="last_name"
                      placeholder="Last Name"
                      type="text"
                      className="mt-1"
                      value={state.last_name}
                      onChange={(e) => changeInput(e)}
                    />
                    {errors && (
                      <p style={{ color: "red", fontSize: "15px" }}>
                        {errors.last_name}
                      </p>
                    )}
                  </label>
                  <label className="block">
                    <Label>
                      Email address{" "}
                      <sup style={{ color: "red", fontSize: "15px" }}>*</sup>
                    </Label>
                    <Input
                      name="email"
                      type="email"
                      placeholder="example@example.com"
                      className="mt-1"
                      value={state.email}
                      onChange={(e) => changeInput(e)}
                    />
                    {errors && (
                      <p style={{ color: "red", fontSize: "15px" }}>
                        {errors.email}
                      </p>
                    )}
                  </label>
                  <label className="block">
                    <Label>
                      Phone Number{" "}
                      <sup style={{ color: "red", fontSize: "15px" }}>*</sup>
                    </Label>
                    {/* <Input
                      name="phone"
                      placeholder="Phone Number"
                      type="number"
                      className="mt-1"
                      value={state.phone}
                      onChange={(e) => changeInput(e)}
                    /> */}
                    <PhoneInput
                      country="us"
                      value={state.phone}
                      onChange={(phone) =>
                        setState({ ...state, phone: `+${phone}` })
                      }
                      inputClass="block !w-full !border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white !dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 mt-1 h-11 px-4 py-3 text-sm font-normal bg-white dark:bg-neutral-900 dark:focus:bg-neutral-900 !rounded-2xl"
                      dropdownClass="!dark:bg-neutral-900 dark:text-neutral-800"
                    />
                    {errors && (
                      <p style={{ color: "red", fontSize: "15px" }}>
                        {errors.phone}
                      </p>
                    )}
                  </label>
                </div>
                <label className="block">
                  <Label>
                    Message{" "}
                    <sup style={{ color: "red", fontSize: "15px" }}>*</sup>
                  </Label>
                  <Textarea
                    name="message"
                    className="mt-1"
                    rows={6}
                    placeholder="Message..."
                    value={state.message}
                    onChange={(e) => changeInput(e)}
                  />
                  {errors && (
                    <p style={{ color: "red", fontSize: "15px" }}>
                      {errors.message}
                    </p>
                  )}
                </label>
                {/* https://www.google.com/recaptcha/admin/create */}

                {/* <div className="g-recaptcha" data-sitekey="6LcYrgoeAAAAALhdWsL9zj19peuJ61rtK1xCn-K6" /> */}
                <ReCAPTCHA
                  sitekey="6LcYrgoeAAAAALhdWsL9zj19peuJ61rtK1xCn-K6"
                  onChange={onChange}
                />
                {reCaptcha === null && (
                  <p style={{ color: "red", fontSize: "15px" }}>
                    Please verify that you are not a robot.
                  </p>
                )}
                <div>
                  <ButtonPrimary type="submit">Send Message</ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
      <div className="container mb-10">
        <div className="listingSection__wrap rounded-[40px] my-8 p-6 bg-neutral-100 dark:bg-black dark:bg-opacity-20">
          {/* HEADING */}
          <div className="text-center">
            <h2 className="text-2xl font-semibold">Easy 2 Manage</h2>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              <i className="las la-map-marker-alt mr-1"></i>
              65 St Pauls Avenue Slough, SL2 5EX
            </span>
          </div>
          {/* <div className="w-full border-b border-neutral-200 dark:border-neutral-700" /> */}
          {/* MAP */}
          <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
            <div className="rounded-xl overflow-hidden">
              {lt !== "" ? (
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyDxJaU8bLdx7sSJ8fcRdhYS1pLk8Jdvnx0",
                  }}
                  defaultZoom={15}
                  yesIWantToUseGoogleMapApiInternals
                  defaultCenter={{
                    lat: parseFloat(lt),
                    lng: parseFloat(lg),
                  }}
                >
                  <LocationMarker lat={parseFloat(lt)} lng={parseFloat(lg)} />
                </GoogleMapReact>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageContact;
