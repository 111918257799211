import React, { FC, useState } from "react";
// import facebookSvg from "images/Facebook.svg";
// import twitterSvg from "images/Twitter.svg";
// import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../utils/config";
import { CISUI } from "../../utils/CISUI";
import { useQuery } from "react-query";
import Loading from "components/Loading/Loading";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/bootstrap.css';
import ButtonSecondary from "shared/Button/ButtonSecondary";

declare global {
    interface Window {
        intlTelInput: any;
    }
}

export interface PageSignUpProps {
    className?: string;
    id?: any;
}

// const loginSocials = [
//     {
//         name: "Continue with Facebook",
//         href: "#",
//         icon: facebookSvg,
//     },
//     {
//         name: "Continue with Twitter",
//         href: "#",
//         icon: twitterSvg,
//     },
//     {
//         name: "Continue with Google",
//         href: "#",
//         icon: googleSvg,
//     },
// ];

// eslint-disable-next-line react-hooks/rules-of-hooks
const SignUp: FC<PageSignUpProps> = ({ className = "", id = useParams() }) => {
    let type = id.id;
    const [eyeToggle, setEyeToggle] = useState(false);
    const [eyeToggle2, setEyeToggle2] = useState(false);
    const [results, setResults] = useState([])
    const [popOver, setPopOver] = useState(false)

    let history = useHistory();

    const [state, setState] = useState({
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        address: '',
        country: '',
        password: '',
        confirmPassword: '',
        countryCode: '44',
        errors: {},
    });


    const [errorMsg, setErrorMsg] = useState("");
    const [errors, setErrors] = useState({
        first_name: '',
        last_name: '',
        address: '',
        email: '',
        phone: '',
        password: '',
        country: ''
    })

    const [passError, setPassError] = useState('');


    const handleSubmit = (e: any) => {
        e.preventDefault();
        const userData = {
            first_name: state.first_name,
            last_name: state.last_name,
            // phone: ("+" + state.countryCode + state.phone),
            phone: state.phone,
            email: state.email,
            address: state.address,
            country: state.country,
            password: state.password,
        }

        axios.post(config.apiserver + `${type}/signup`, userData)
            .then(res => {
                if (res.data.status === 422) {
                }
                if (res.data.status === 1 || res.data.status === 3) {
                    // eslint-disable-next-line no-restricted-globals
                    CISUI().notification('success', res.data.msg)
                    history.push(`/otp-verification?phone=${state.phone}`);
                }
                else if (res.data.status === 2) {
                    history.push('/login/customer')
                    CISUI().notification('warning', res.data.msg)
                }
                else {
                    CISUI().notification('success', res.data.msg)
                    setErrorMsg(res.data.msg)
                }
            })
            .catch(error => {
                setErrors(error.response.data.errors);
                if (state.password !== '' && state.password !== state.confirmPassword) {
                    setPassError('Confirm Password is not matched with Password')
                }
                else {
                    setPassError('');
                }
            })


    }

    const changeInput = (e: any) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    };
    const { data: country, isLoading } = useQuery(["country"], () => axios.get(config.apiserver + "country").then((res) => res.data.data));
    let cityname: any = []

    const getPrediction = async () => {
        setPopOver(false)
        if (state?.address?.length >= 2) {
            setPopOver(true)
            var results: any = await googleAutocomplete(state?.address)

            if (results) {
                results?.forEach((item: any) => {
                    cityname.push(item.description)
                })
                setResults(cityname)
            }
        } else {
            setPopOver(false)
        }
    }

    const googleAutocomplete = async (text: any) =>
        new Promise((resolve, reject) => {
            if (!text) {
                return reject("Need valid text input")
            }
            if (typeof window === "undefined") {
                return reject("Need valid window object")
            }
            try {
                new window.google.maps.places.AutocompleteService().getPlacePredictions(
                    { input: text },
                    resolve
                )
            } catch (e) {
                reject(e)
            }
        })
    const handleSelectLocation = (item: any) => {

        setState({
            ...state,
            "address": item,
        });
        setPopOver(false)
    }

    const onReset = () => {
        setState({
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            address: '',
            country: '',
            password: '',
            confirmPassword: '',
            countryCode: '44',
            errors: {},
        });
    }

    if (isLoading) {
        return <Loading />
    }

    return (
        localStorage.getItem('access_token') ?
            <Redirect to='/customer/customer-upcoming-booking' />
            :
            <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
                <Helmet>
                    <title>Sign up || Booking React Template</title>
                </Helmet>
                <div className="container mb-24 lg:mb-32">
                    <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                        {type === 'customer' ? 'Customer Sign Up' : 'Partner Sign Up'}
                    </h2>
                    <div className="max-w-5xl mx-auto space-y-6">
                        {
                            errorMsg !== '' && (
                                <p className="signup_error" style={{ textAlign: "center", color: "red" }}>{errorMsg}</p>
                            )
                        }
                        <form className="" action="#" method="post" onSubmit={handleSubmit}>
                            <div className="grid grid-cols-2 gap-4">
                                <label className="block">
                                    <span className="text-neutral-800 dark:text-neutral-200">
                                        First Name <sup style={{ color: "red" }}>*</sup>
                                    </span>
                                    <Input
                                        name="first_name"
                                        type="text"
                                        className="firstname mt-1"
                                        placeholder="Enter your first name"
                                        value={state.first_name}
                                        onChange={changeInput} />
                                    {
                                        errors &&
                                        <p style={{ color: "red" }}>{errors.first_name}</p>
                                    }
                                </label>
                                <label className="block">
                                    <span className="text-neutral-800 dark:text-neutral-200">
                                        Last Name <sup style={{ color: "red" }}>*</sup>
                                    </span>
                                    <Input
                                        name="last_name"
                                        type="text"
                                        className="lastname mt-1"
                                        placeholder="Enter your last name"
                                        value={state.last_name} onChange={(e) => changeInput(e)} />
                                    {
                                        errors &&
                                        <p style={{ color: "red" }}>{errors.last_name}</p>
                                    }
                                </label>
                                <label className="block">
                                    <span className="text-neutral-800 dark:text-neutral-200">
                                        Mobile Number <sup style={{ color: "red" }}>*</sup>
                                    </span>

                                    <>
                                        <PhoneInput
                                            country="us"
                                            value={state.phone}
                                            onChange={(phone) => setState({ ...state, phone: `+${phone}` })}
                                            inputClass="block !w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 mt-1 h-11 px-4 py-3 text-sm font-normal !rounded-2xl"
                                            dropdownClass="!dark:bg-neutral-900 dark:text-neutral-800"
                                        />
                                    </>
                                </label>
                                <label className="block">
                                    <span className="text-neutral-800 dark:text-neutral-200">
                                        Email address
                                    </span>
                                    <Input
                                        name="email"
                                        type="email"
                                        placeholder="example@example.com"
                                        className="mt-1"
                                        value={state.email} onChange={(e) => changeInput(e)}
                                    />
                                    {
                                        errors &&
                                        <p style={{ color: "red" }}>{errors.email}</p>
                                    }
                                </label>
                                <label className="block">
                                    <span className="text-neutral-800 dark:text-neutral-200">
                                        Address <sup style={{ color: "red" }}>*</sup>
                                    </span>
                                    <Input
                                        name="address"
                                        type="text"
                                        placeholder="Address"
                                        className="mt-1"
                                        value={state.address}
                                        onChange={(e) => {
                                            getPrediction()
                                            changeInput(e)
                                        }}

                                    />
                                    {
                                        errors &&
                                        <p style={{ color: "red" }}>{errors.address}</p>
                                    }

                                    {popOver && (
                                        <div className="mt-2 absolute bg-white dark:bg-neutral-800 min-w-[350px] sm:min-w-[500px] shadow-2xl rounded-2xl z-50">
                                            {results.map((item: any) => (
                                                <span
                                                    onClick={() => handleSelectLocation(item)}
                                                    key={item}
                                                    className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 sm:py-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer rounded-2xl"
                                                >
                                                    <span className=" block font-medium text-neutral-700 dark:text-neutral-200">
                                                        {item}
                                                    </span>
                                                </span>
                                            ))}
                                        </div>
                                    )}

                                </label>

                                <label className="block">
                                    <span className="text-neutral-800 dark:text-neutral-200">
                                        Country <sup style={{ color: "red" }}>*</sup>
                                    </span>
                                    <select name="country"
                                        placeholder="Country Name"
                                        className="`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 mt-1 h-11 px-4 py-3 text-sm font-normal rounded-2xl"
                                        value={state.country} onChange={(e) => changeInput(e)}
                                    >
                                        <option value="">Select an option</option>
                                        {country.map((item: any, index: number) =>
                                            <option key={index}>{item.name}</option>
                                        )
                                        }
                                    </select>
                                    {
                                        errors &&
                                        <p style={{ color: "red" }}>{errors.country}</p>
                                    }
                                </label>
                                <label className="block">
                                    <span className="text-neutral-800 dark:text-neutral-200">
                                        Password <sup style={{ color: "red" }}>*</sup>
                                    </span>
                                    <div className="relative flex w-full flex-wrap items-stretch mb-3">
                                        <input name="password" value={state.password} onChange={(e) => changeInput(e)} type={eyeToggle ? "text" : "password"} placeholder="New Password" className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1.5" />
                                        <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-4" onClick={() => setEyeToggle(!eyeToggle)}>
                                            {eyeToggle ? (
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                </svg>
                                            ) : (
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                                </svg>
                                            )}
                                        </span>
                                    </div>
                                    {
                                        errors &&
                                        <p style={{ color: "red" }}>{errors.password}</p>
                                    }
                                </label>
                                <label className="block">
                                    <span className="text-neutral-800 dark:text-neutral-200">
                                        Confirm Password <sup style={{ color: "red" }}>*</sup>
                                    </span>

                                    <div className="relative flex w-full flex-wrap items-stretch mb-3">
                                        <input name="confirmPassword" value={state.confirmPassword} onChange={(e) => changeInput(e)} type={eyeToggle2 ? "text" : "password"} placeholder="Confirm Password" className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1.5" />
                                        <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-4" onClick={() => setEyeToggle2(!eyeToggle2)}>
                                            {eyeToggle2 ? (
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                </svg>
                                            ) : (
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                                </svg>
                                            )}
                                        </span>
                                    </div>
                                    {
                                        passError !== '' &&
                                        <p style={{ color: "red" }}>{passError}</p>
                                    }
                                </label>
                            </div>
                            <br />
                            <div className="relative text-center">
                                <ButtonSecondary type="reset" className="h-10 px-5 m-2" onClick={onReset}>Reset</ButtonSecondary>
                                <ButtonPrimary className="h-10 px-5 m-2" type="submit">Signup</ButtonPrimary>
                            </div>
                        </form>
                        <span className="block text-center text-neutral-700 dark:text-neutral-300">
                            Already have an account? {` `}
                            <Link to={`/login/${type}`}>Sign in</Link>
                        </span>
                    </div>
                </div>
            </div>
    );
};

export default SignUp;
