import config from "../../utils/config";
import Heading from 'components/Heading/Heading';
import Loading from 'components/Loading/Loading';
import { useQuery } from 'react-query';
import axiosinstance from 'utils/AxiosInstance';

export interface itemProudMember {
    image: string;
    name: string;
    body: string;
    keyword: string;
}

const ProudMembers = () => {
    const { data, isLoading } = useQuery(["widgets?page_name=Home"], () => axiosinstance.get("widgets?page_name=Home").then((res) => res.data.data));


    return (
        isLoading ? <Loading /> :
            <>
                <Heading isCenter desc="">
                    Proud Members
                </Heading>
                <div className="ncSectionLogos grid grid-cols-4 lg:grid-cols-4 gap-5 sm:gap-16">
                    <div className="flex items-end justify-center">
                        <img className="block dark:hidden" src={config.imageURL + "public" + data?.[22]?.image} alt="logo4" />
                        <img className="hidden dark:block" src={config.imageURL + "public" + data?.[18]?.image} alt="logo4" />
                    </div>
                    <div className="flex items-end justify-center">
                        <img className="block dark:hidden" src={config.imageURL + "public" + data?.[23]?.image} alt="logo1" />
                        <img className="hidden dark:block" src={config.imageURL + "public" + data?.[19]?.image} alt="logo1" />
                    </div>
                    <div className="flex items-end justify-center">
                        <img className="block dark:hidden" src={config.imageURL + "public" + data?.[24]?.image} alt="logo2" />
                        <img className="hidden dark:block" src={config.imageURL + "public" + data?.[20]?.image} alt="logo2" />
                    </div>
                    <div className="flex items-end justify-center">
                        <img className="block dark:hidden" src={config.imageURL + "public" + data?.[25]?.image} alt="logo3" />
                        <img className="hidden dark:block" src={config.imageURL + "public" + data?.[21]?.image} alt="logo3" />
                    </div>

                </div>
            </>
    );
};

export default ProudMembers;
