import React, { FC, useEffect, useState } from "react";
// import facebookSvg from "images/Facebook.svg";
// import twitterSvg from "images/Twitter.svg";
// import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import PhoneInput from 'react-phone-input-2';

import Config from '../../utils/config';
import axios from 'axios';
import { CISUI } from "../../utils/CISUI";
import Loading from "components/Loading/Loading";
import 'react-phone-input-2/lib/bootstrap.css';
// import Checkbox from "shared/Checkbox/Checkbox";
import Input from "shared/Input/Input";

export interface PageLoginProps {
    className?: string;
    id?: any;

}

// const loginSocials = [
//     {
//         name: "Continue with Facebook",
//         href: "#",
//         icon: facebookSvg,
//     },
//     {
//         name: "Continue with Twitter",
//         href: "#",
//         icon: twitterSvg,
//     },
//     {
//         name: "Continue with Google",
//         href: "#",
//         icon: googleSvg,
//     },
// ];

// eslint-disable-next-line react-hooks/rules-of-hooks
const PageLogin: FC<PageLoginProps> = ({ className = "", id = useParams() }, props) => {
    const [eyeToggle, setEyeToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [option, setOption] = useState(true);

    let type = id.id;
    let history = useHistory();
    let location = useLocation();

    let urlParam = new URLSearchParams(window.location.search);
    let is_verified = urlParam.get('verified');


    const [state, setState] = useState({
        email: '',
        password: '',
        phone: '',
        countryCode: '44',
        errors: {},
    });
    const [errorMsg, setErrorMsg] = useState("");

    const handleSubmit = (e: any) => {
        e.preventDefault();


        setLoading(true);
        let userData = {};
        if (option) {
            userData = {
                phone: state.phone,
                password: state.password
            }
        } else {
            userData = {
                email: state.email,
                password: state.password
            }
        }

        axios.post(Config.apiserver + 'userlogin', userData)
            .then((res: { data: any; }) => {
                if (res.data.status === 200) {
                    localStorage.setItem('access_token', res.data.access_token)
                    localStorage.setItem('user', JSON.stringify(res.data.user))
                    localStorage.setItem('login_type', type)
                    axios.defaults.headers.common['Authorization'] = res.data.access_token;

                    setLoading(false);
                    CISUI().notification('success', 'Login Successful.');
                    if (location?.state) {
                        history.push(`${location?.state}`);
                    } else {
                        history.push('/customer/customer-booking-request');
                    }
                    window.location.reload();

                } else {
                    if (res.data.message !== undefined) {
                        setLoading(false);
                        setErrorMsg(res.data.message)
                    }
                    else {
                        setLoading(false);
                        CISUI().notification('error', "Invalid UserId or Password");
                    }
                }
            })
            .catch(error => {
                setLoading(false);
                CISUI().notification('error', "Invalid UserId");
            })

    }
    const changeInput = (e: any) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        if (localStorage.getItem('access_token')) {
            history.goBack();
        }
    }, [])

    const handleChange = (e: any) => {
        setOption(!e.target.checked)
    }


    return loading ? <Loading /> : (
        <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
            <Helmet>
                <title>Easy 2 Manage (E2M) || The Property Management Company</title>
            </Helmet>
            <div className="container mb-24 lg:mb-32">
                <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    {type === 'customer' ? 'Customer Login' : 'Partner Login'}

                </h2>
                <div className="max-w-md mx-auto space-y-6">
                    {errorMsg !== '' && (
                        <p className="login_error" style={{ textAlign: "center", fontSize: 20, color: "red" }}>{errorMsg}</p>
                    )
                    }
                    {is_verified && (
                        <p className="login_error" style={{ textAlign: "center", fontSize: 20, color: "red" }}>Your account is verified. Now you can login.</p>
                    )
                    }
                    <form className="grid grid-cols-1 gap-5" onSubmit={handleSubmit}>
                        <div>
                            {option ? <>
                                <label className='mb-3 '>Enter your Phone Number</label>
                                <PhoneInput
                                    country="us"
                                    value={state.phone}
                                    onChange={(phone) => setState({ ...state, phone: `+${phone}` })}
                                    inputClass="block !w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 mt-1 h-11 px-4 py-3 text-sm font-normal bg-white dark:bg-neutral-900 dark:focus:bg-neutral-900"
                                    dropdownClass="!dark:bg-neutral-900 dark:text-neutral-800"
                                />
                            </> :
                                <>
                                    <label> Enter your Email Address</label>
                                    <Input type="email" name="email" className="mt-1 rounded dark:text-white" placeholder="Enter your email" value={state.email} onChange={(e) => changeInput(e)} />
                                </>}

                            <div className="mt-1">
                                <input id="use_email" type="checkbox" name="use_email" className="focus:ring-action-primary h-4 w-4 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500 mr-2" onChange={(e) => handleChange(e)} />
                                <label htmlFor="use_email" className='mb-3 text-sm'>{option ? "Use email instead of phone" : "Use phone instead of email"}</label>
                            </div>
                        </div>

                        <label className="block">
                            <span className="text-neutral-800 dark:text-neutral-200">
                                Password <sup style={{ color: "red", fontSize: "15px" }}>*</sup>

                                <div className="relative flex w-full flex-wrap items-stretch mb-3">
                                    <input name="password" value={state.password} onChange={(e) => changeInput(e)} type={eyeToggle ? "text" : "password"} placeholder="Password" className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded text-sm font-normal h-11 px-4 py-3 mt-1.5 dark:text-white" />
                                    <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-4" onClick={() => setEyeToggle(!eyeToggle)}>
                                        {eyeToggle ? (
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                            </svg>
                                        ) : (
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                            </svg>
                                        )}
                                    </span>
                                </div>
                                <Link to="/forgot-pass" className="text-sm float-right mt-3">
                                    Forgot password?
                                </Link>
                            </span>
                        </label>
                        <ButtonPrimary type="submit" disabled={(state.phone === '' && state.email === '') || state.password === ''}>Continue</ButtonPrimary>
                    </form>
                    {/* <div className="relative text-center">
                        <span
                            className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
                            OR
                        </span>

                    </div>
                    <div className="grid gap-3">
                        {loginSocials.map((item, index) => (
                            <a
                                key={index}
                                href={item.href}
                                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
                            >
                                <img
                                    className="flex-shrink-0"
                                    src={item.icon}
                                    alt={item.name}
                                />
                                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                                    {item.name}
                                </h3>
                            </a>
                        ))}
                    </div> */}

                    <span className="block text-center text-neutral-700 dark:text-neutral-300">
                        New user? {` `}
                        <Link to={`/signup/${type}`}>Create an account</Link>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default PageLogin;