/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import axiosinstance from "utils/AxiosInstance";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";

const RetrieveStatus = (props: any) => {
  const stripe = useStripe();
  // const history = useHistory();

  const [status, setStatus] = useState("processing");

  const getData = async () => {
    if (!stripe) {
      return;
    }

    if (!props.clientSecret) {
      return;
    }

    await stripe.retrievePaymentIntent(props.clientSecret).then((res: any) => {
      const { status } = res.paymentIntent;
      setStatus(status);
    });
  };

  axiosinstance
    .get(
      "booking/retrievePayment?payment_intent=" +
        props.paymentIntent +
        "&payment_intent_client_secret=" +
        props.clientSecret +
        "&redirect_status=" +
        props.redirectStatus
    )
    .then((res) => {
      console.log(res);
    });

  useEffect(() => {
    getData();
  }, [stripe]);

  // React.useEffect(() => {
  //   timer > 0 && setTimeout(() => setTimer(timer - 1), 1000);
  // }, [timer]);

  return (
    <>
      <div className="container">
        <div className="bg-white dark:bg-neutral-900">
          <div className="bg-white dark:bg-neutral-900 p-10  md:mx-auto">
            <div className="text-center">
              <img
                src={`/assets/images/${status}.svg`}
                alt="e2m"
                className="mx-auto bg-white rounded"
              />
              {/* {status === "succeeded" ? (
                <p className="text-sm text-neutral-6000 dark:text-neutral-300 mt-5">
                  You will redriecd to your booking history page in
                  {` ${timer} `}
                  seconds
                </p>
              ) : null} */}
              <div className="py-4 text-center">
                <Link to="/customer/customer-booking-history">
                  <ButtonPrimary className="my-2">
                    See Your Booking History
                  </ButtonPrimary>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RetrieveStatus;
