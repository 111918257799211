import BookedProperty from "containers/CustomerPortal/bookedProperty";
import BookingHistoryCard from "containers/CustomerPortal/BookingHistoryCard";
import BookingRequestCard from "containers/CustomerPortal/BookingRequestCard";
import PaymentHistoryCard from "containers/CustomerPortal/PaymentHistoryCard";
import ProfileUpdate from "containers/CustomerPortal/ProfileUpdate";
import React, { HTMLAttributes, ReactNode } from "react";

export interface DashboardProps extends HTMLAttributes<HTMLHeadingElement> {
  fontClass?: string;
  desc?: ReactNode;
  hasNextPrev?: boolean;
  isCenter?: boolean;
  pageTitle?: string;
  show?: string;
}

const ListView: React.FC<DashboardProps> = ({
  children,
  desc = "Popular places to stay that E2M recommends for you",
  className = "mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50",
  isCenter = false,
  hasNextPrev = false,
  pageTitle = "",
  show,
  ...args
}) => {
  return (
    <div className="listingSection__wrap bg-neutral-100 dark:bg-black dark:bg-opacity-20 p-5 rounded-2xl my-2">
      <div>
        <h2 className="text-2xl font-semibold">{pageTitle}</h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400"></span>
      </div>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      {show === "profile" ? (
        <ProfileUpdate />
      ) : show === "bookingRequest" ? (
        <BookingRequestCard />
      ) : show === "upcomingBooking" ? (
        <BookedProperty />
      ) : show === "bookingHistory" ? (
        <BookingHistoryCard />
      ) : show === "paymentHistory" ? (
        <PaymentHistoryCard />
      ) : (
        ""
      )}
    </div>
  );
};

export default ListView;
