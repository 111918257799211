import { FC } from "react";

// @ts-ignore
import DOMPurify from "dompurify";
import { Helmet } from "react-helmet";

import ButtonPrimary from "shared/Button/ButtonPrimary";
import GoogleMapReact from "google-map-react";
import config from "../../utils/config";
import { useQuery } from "react-query";
import axiosinstance from "utils/AxiosInstance";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import LocationMarker from "components/LocationMarker";
import Loading from "components/Loading/Loading";

export interface PageAboutProps {
  className?: string;
}

const About: FC<PageAboutProps> = ({ className = "" }) => {
  let lt = "51.514992";
  let lg = "-0.5879683";

  const { data, isLoading } = useQuery(["widgets?page_name=company"], () =>
    axiosinstance.get("widgets?page_name=company").then((res) => res.data.data)
  );

  const createMarkup = (html: any) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About - Easy 2 Manage The Property Management Company</title>
      </Helmet>

      <BgGlassmorphism />
      {isLoading ? (
        <Loading />
      ) : (
        <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
          <div>
            <img
              src={config.imageURL + "public" + data?.[0]?.image}
              alt="Easy2Mange"
              className="rounded-3xl w-full sm:w-full md:w-full lg:w-3/6 xl:w-3/6 2xl:w-3/6 float-right ml-5 mb-5"
            />
            <div>
              <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100 mb-5">
                {data?.[0]?.name}
              </h2>
              <span
                className="text-justify md:text-lg text-neutral-500 dark:text-neutral-400"
                dangerouslySetInnerHTML={createMarkup(data?.[0]?.body)}
              ></span>
            </div>
            <div
              className={`rounded-[40px] my-8 p-16 bg-neutral-100 dark:bg-black dark:bg-opacity-20`}
            >
              <img
                src={config.imageURL + "public" + data?.[1]?.image}
                alt=""
                className="rounded-3xl w-full sm:w-full md:w-full lg:w-2/5 xl:w-2/5 2xl:w-2/5 float-right ml-5 mb-5"
              />
              <h2 className="text-xl !leading-tight font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100 mb-5">
                {data?.[1]?.name}
              </h2>
              <span
                className="text-justify md:text-lg text-neutral-500 dark:text-neutral-400"
                dangerouslySetInnerHTML={createMarkup(data?.[1]?.body)}
              ></span>
              <br />
              <br />

              <br />
              <ButtonPrimary href="/contact" className="mt-5">
                Contact Us!
              </ButtonPrimary>
            </div>
          </div>

          <div>
            <div className="listingSection__wrap rounded-[40px] my-8 p-6 bg-neutral-100 dark:bg-black dark:bg-opacity-20">
              <div className="text-center">
                <h2 className="text-2xl font-semibold">Easy 2 Manage</h2>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                  <i className="las la-map-marker-alt mr-1"></i>
                  65 St Pauls Avenue Slough, SL2 5EX
                </span>
              </div>
              <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
                <div className="rounded-xl overflow-hidden">
                  {lt !== "" ? (
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: "AIzaSyDxJaU8bLdx7sSJ8fcRdhYS1pLk8Jdvnx0",
                      }}
                      defaultZoom={15}
                      yesIWantToUseGoogleMapApiInternals
                      defaultCenter={{
                        lat: parseFloat(lt),
                        lng: parseFloat(lg),
                      }}
                    >
                      <LocationMarker
                        lat={parseFloat(lt)}
                        lng={parseFloat(lg)}
                      />
                    </GoogleMapReact>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default About;
