import { FC } from "react";
// import HeroRealEstateSearchForm from "components/HeroSearchForm/HeroRealEstateSearchForm";
// import Heading from "components/Heading/Heading";
import axios from "axios";
import config from "utils/config";
import Loading from "components/Loading/Loading";
import Heading from "components/Heading/Heading";
import HeroRealEstateSearchForm from "components/SearchForm/HeroRealEstateSearchForm";
import { useQuery } from "react-query";
export interface HeroProps {
    className?: string;
}

export interface heroInterface {
    image: string;
    name: string;
    body: string
}
const Hero: FC<HeroProps> = ({ className = "", children }) => {
    // const [loading, setLoading] = useState(false)
    // const [hero, setHero] = useState<heroInterface>();
    // useEffect(() => {
    //     heroAllData();
    // }, []);

    // const heroAllData = () => {
    //     setLoading(true)
    //     axios.get(config.apiserver + 'widgets?page_name=Home')
    //         .then(function (response) {
    //             const allData = response.data.data;
    //             setHero(allData[0]);
    //             setLoading(false)
    //         })
    //         .catch(function (error) {
    //             setLoading(false)
    //         })
    // }

    const { data, isLoading } = useQuery(["widgets?page_name=Home"], () => axios.get(config.apiserver + "widgets?page_name=Home").then((res) => res.data.data));

    if (isLoading) {
        return <Loading />
    }
    return (
        <div
            className={`nc-SectionHero2 relative ${className}`}
            data-nc-id="SectionHero2"
        >
            <div className="absolute bottom-0 top-60 md:inset-y-0 w-5/6 xl:w-3/4 right-0 flex-grow opacity-6">
                <img className="absolute inset-0 object-cover w-full h-full" src={config.imageURL + "public" + data?.[0]?.image} alt="hero" />
            </div>
            <div className="relative flex flex-col-reverse items-start md:block pb-14 md:py-14 lg:py-20">
                <div className="relative inline-flex bg-primary-500">
                    <div className="w-screen right-20 md:right-47 inset-y-0 absolute bg-primary-500"></div>
                    <div
                        className="relative max-w-3xl inline-flex flex-col items-start py-16 sm:py-20 lg:py-24 space-y-8 sm:space-y-10 text-white">
                        {children ? (
                            children
                        ) : (

                            <h2 className="font-semibold sm:text-xl md:text-5xl xl:text-7xl !leading-[110%]">
                                <Heading className={"text-left w-full mx-auto max-w-2xl"} desc={data?.[0]?.body}>
                                    {data?.[0]?.name}
                                </Heading>
                            </h2>
                        )}
                    </div>
                </div>
                <div className="mb-10 md:mb-0 md:mt-10 lg:mt-20 w-full">
                    <HeroRealEstateSearchForm />
                </div>
            </div>
        </div>
    );
};

export default Hero;
