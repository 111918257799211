import { Link } from "react-router-dom";
import config from "utils/config";
import moment from "moment";
import { useQuery, useQueryClient } from "react-query";
import axiosinstance from "utils/AxiosInstance";
import Loading from "components/Loading/Loading";
import { useEffect } from "react";

export interface BookingsProps {
  book_unqid?: string;
  image?: string;
  property_name?: string;
  discount_amount?: number;
  grand_total?: string;
  booking_dates?: any[];
  room_id?: number;
  booking_id?: number;
}
const BookedRoom = (): JSX.Element => {
  const queryClient = useQueryClient();

  const { data: bookings, isLoading } = useQuery(
    ["booking/customerbooking"],
    () => axiosinstance.get("booking/customerbooking").then((res) => res.data)
  );

  useEffect(() => {
    return () => {
      queryClient.removeQueries("booking/customerbooking");
    };
  }, []);

  const upcomingBookings = bookings?.data?.filter((item: any) =>
    moment(moment(item.booking_dates?.[0]?.date).format("YYYY-MM-DD")).isAfter(
      moment().format("YYYY-MM-DD")
    )
  );

  if (isLoading) return <Loading />;
  if (bookings?.status === 0 || upcomingBookings?.length === 0)
    return (
      <div className="text-center text-2xl mb-5 font-medium">
        You don't have any booking yet...
      </div>
    );

  return (
    <>
      {upcomingBookings?.map((item: any) => (
        <div
          className={`nc-PropertyCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow h-full`}
          data-nc-id="PropertyCardH"
          key={item.book_unqid}
        >
          <Link
            to={`/customer/bookingdetails/${item.booking_id}`}
            className="w-full flex flex-col sm:flex-row sm:items-center"
          >
            <div className="flex-shrink-0 p-3 w-full h-full sm:w-64 ">
              <img
                src={config.imageURL + item.image}
                alt=""
                className="h-full rounded-lg max-h-56"
              />

              {item.discount_amount === 0 ? (
                ""
              ) : (
                <div
                  className={`nc-SaleOffBadge flex items-center justify-center text-xs py-0.5 px-3 text-red-50 rounded-full absolute left-5 top-5 bg-orange-500`}
                  data-nc-id="SaleOffBadge"
                >
                  - {item.discount_amount} £
                </div>
              )}
            </div>

            <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
              <div className="space-y-4 w-full p-2">
                <div className="flex items-center space-x-2">
                  <h2 className="text-lg font-medium capitalize">
                    <span className="line-clamp-2">{item.property_name}</span>
                  </h2>
                </div>

                <div className="grid grid-cols-1 gap-4">
                  <div className="inline-grid grid-cols-2">
                    <div className="flex items-center space-x-2">
                      <span className="sm:inline-block text-sm">
                        <i className="las la-calendar-check text-lg" />
                        Check-in:
                      </span>
                      <span className="text-xs text-neutral-500 dark:text-neutral-400">
                        {moment(item.booking_dates?.[0].date).format(
                          "DD MMM, YYYY"
                        )}
                      </span>
                    </div>

                    {/* ---- */}

                    <div className="flex items-center space-x-2">
                      <span className="sm:inline-block text-sm">
                        <i className="las la-calendar-minus text-lg" />
                        Check-Out:
                      </span>
                      <span className="text-xs text-neutral-500 dark:text-neutral-400">
                        {moment(
                          item.booking_dates?.[item.booking_dates?.length - 1]
                            ?.date
                        ).format("DD MMM, YYYY")}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 "></div>
                <div className="flex w-full justify-between items-end font-medium text-secondary-500">
                  Total : £{item.grand_total}
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </>
  );
};

export default BookedRoom;
