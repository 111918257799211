import axios from "axios";
import config from "./config";

const axiosinstance = axios.create({
  baseURL: config.apiserver,
  headers: {
    "Content-Type": "application/json",
    Authorization: `${localStorage.getItem("access_token")}`,
  },
});

export default axiosinstance;
