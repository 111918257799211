import { FC } from "react";
import "react-dates/initialize";
import RealEstateSearchForm, { GuestsInputs } from "./RealEstateSearchForm";
import { DateRage } from "./StaySearchForm";

export type SearchRealEstateTab = "Buy" | "Rent" | "Sell";

export interface HeroRealEstateSearchFormProps {
  className?: string;
  currentTab?: SearchRealEstateTab;
  date?: DateRage;
  guest?: GuestsInputs;
  location?: string | null;
}

const HeroRealEstateSearchForm: FC<HeroRealEstateSearchFormProps> = ({
  className = "",
  currentTab = "Buy",
  location,
  date,
  guest,
}) => {
  // const [tabActive, setTabActive] = useState<SearchRealEstateTab>(currentTab);

  const renderForm = () => {
    switch (currentTab) {
      default:
        return (
          <RealEstateSearchForm
            location={location}
            dateProps={date}
            guestProps={guest}
          />
        );
    }
  };

  return (
    <div
      className={`nc-HeroRealEstateSearchForm w-full max-w-6xl py-5 lg:py-0 mx-auto ${className}`}
      data-nc-id="HeroRealEstateSearchForm"
    >
      {renderForm()}
    </div>
  );
};

export default HeroRealEstateSearchForm;
