import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";

const ConfirmPayment = ({ setOpen, amount }: any) => {
  const [payLoading, setPayLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setPayLoading(true);
    if (!stripe || !elements) {
      return;
    }
  
    stripe
      .confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.origin + "/retrievepayment",
        },
      })
      .then(function (result) {
        if (result.error) {
          // Inform the customer that there was an error.
          alert(result.error.message);
          setPayLoading(false);
        }
      });
    
  };
  const handleCheckbox = () => {
    setBtnDisabled((prev) => !prev);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          backgroundColor: "white",
          padding: "40px",
          borderRadius: "10px",
        }}
      >
        {/* <h1 className="text-lg mb-5 text-neutral-6000 font-semibold">Pay Avdance Fee Amount of £{amount} </h1> */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 absolute top-5 right-5 cursor-pointer"
          onClick={() => setOpen(false)}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>

        <PaymentElement />
        <div className={`flex text-sm sm:text-base my-5`}>
          <input
            name="trams"
            type="checkbox"
            className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
            onChange={handleCheckbox}
          />

          <label
            htmlFor="trams"
            className="ml-3.5 flex flex-row text-neutral-6000"
          >
            I agree to the{" "}
            <Link to="/terms" className="font-semibold ml-1">
              Terms and Conditions
            </Link>
          </label>
        </div>
        <div className="flex justify-between">
          <ButtonPrimary
            disabled={btnDisabled || !stripe || !elements}
            loading={payLoading}
            className={payLoading ? "cursor-not-allowed" : ""}
          >
            Confirm and pay
          </ButtonPrimary>
        </div>
      </form>
    </>
  );
};

export default ConfirmPayment;
