import { FC } from "react";
import { useHistory } from "react-router-dom";
export interface CustomerLogoutProps {
  className?: string;
}

const CustomerLogout: FC<CustomerLogoutProps> = () => {
  let history = useHistory();
  const Logout = () => {
    let type = localStorage.getItem("login_type");
    localStorage.removeItem("access_token");
    localStorage.removeItem("user");
    if (type === "owner") {
      history.push("/login/owner");
      window.location.reload();
    } else {
      history.push("/login/customer");
      window.location.reload();
    }
  };
  return <>{Logout()}</>;
};
export default CustomerLogout;
