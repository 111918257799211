import { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import axios from "axios";
import config from "../../utils/config";
import Loading from "../Loading/Loading";
import { useQuery } from "react-query";
export interface OurFeaturesProps {
  className?: string;
  type?: "type1" | "type2";
}
export interface itemBennefits {
  image: string;
  name: string;
  body: string;
  keyword: string;
}
const OurFeatures: FC<OurFeaturesProps> = ({
  className = "lg:py-14",
  type = "type1",
}) => {
  const { data, isLoading } = useQuery(["widgets?page_name=Home"], () =>
    axios
      .get(config.apiserver + "widgets?page_name=Home")
      .then((res) => res.data.data)
  );

  return isLoading ? (
    <Loading />
  ) : (
    <div
      className={`nc-OurFeatures relative flex flex-col items-center ${
        type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
      } ${className}`}
      data-nc-id="OurFeatures"
    >
      <div className="flex-grow">
        <NcImage src={config.imageURL + "public" + data?.[7]?.image} />
      </div>
      <div
        className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-2/5 ${
          type === "type1" ? "lg:pl-16" : "lg:pr-16"
        }`}
      >
        <span className="uppercase text-sm text-gray-400 tracking-widest">
          {data?.[7]?.keyword}
        </span>
        <h2 className="font-semibold text-4xl mt-5"> {data?.[7]?.name}</h2>

        <ul className="space-y-4 mt-10">
          <li className="flex items-center space-x-4">
            <Badge name="01" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              {/*YEARS OF EXPERIENCE*/}
              {data?.[8]?.name}
            </span>
          </li>
          <li className="flex items-center space-x-4">
            <Badge name="02" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              {/*ADVANCE MANAGEMENT SYSTEM*/}
              {data?.[9]?.name}
            </span>
          </li>
          <li className="flex items-center space-x-4">
            <Badge name="03" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              {/*BEST CUSTOMER SERVICES*/}
              {data?.[10]?.name}
            </span>
          </li>
          <li className="flex items-center space-x-4">
            <Badge name="04" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              {/*COMPETITIVE COMISSION RATES*/}
              {data?.[11]?.name}
            </span>
          </li>
          <li className="flex items-center space-x-4">
            <Badge name="05" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              {/*PROFESSIONAL STAFF*/}
              {data?.[12]?.name}
            </span>
          </li>
          <li className="flex items-center space-x-4">
            <Badge name="06" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              {/*IN HOUSE CLEANING STAFF*/}
              {data?.[13]?.name}
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default OurFeatures;
