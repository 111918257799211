import { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import GoogleMapReact from "google-map-react";
import useWindowSize from "hooks/useWindowResize";
import moment from "moment";
import { Helmet } from "react-helmet";

import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";

import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

import NcImage from "shared/NcImage/NcImage";

import ModalPhotos from "./ModalPhotos";

import axios from "axios";
import config from "../../utils/config";
// @ts-ignore
import Loading from "components/Loading/Loading";
import LocationMarker from "components/LocationMarker";
import GuestsInput from "components/SearchForm/GuestsInput";
import StayDatesRangeInput from "components/SearchForm/StayDatesRangeInput";
import { DateRage } from "components/SearchForm/StaySearchForm";
import GridCategoryBox from "components/Section/GridCategoryBox";
import DOMPurify from 'dompurify';
import { CISUI } from "../../utils/CISUI";

export interface ListingStayDetailPageProps {
    className?: string;
    isPreviewMode?: boolean;
}

export interface GuestsInputs {
    guestAdults?: number | undefined;
    guestChildren?: number | undefined;
    guestInfants?: number | undefined;
    petCount?: number | undefined;
}

const PropertyDetailPage: FC<ListingStayDetailPageProps> = ({
    className = "",
    isPreviewMode = false,
}) => {
    interface RoomDetailsInface {
        balcony?: string | number;
        bathroom?: string | number;
        bed_room?: string | number;
        com_id?: string | number;
        commission?: string | number;
        created_at?: string | number;
        customer_price?: string | number;
        cleaning_fee?: string | number;
        pet_fee?: string | number;
        details?: string | number;
        discount?: string | number;
        facilities?: any;
        id?: string | number;
        other_room?: string | number;
        owner_price?: string | number;
        priority?: string | number;
        property_id?: string | number;
        property_name?: string;
        remark?: string | number;
        room_name?: string | number;
        room_type?: string | number;
        latitude?: string | undefined;
        longitude?: string;
        address?: string | number;
        size?: string | number;
        status?: string | number;
        updated_at?: string | number;
        user_id?: string | number;
        kitchen?: string | number;
        maximum_guest?: string | number;
        sufa_bed?: string | number
        check_in?: string;
        check_out?: string;
        room_policy?: string
        booking_policy?: string
        cancellation_policy?: string
    }
    const createMarkup = (html: any) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }

    interface AmenitiesInface {
        name?: string;
        id?: number
    }

    interface RoomImagesInface {
        image?: string;
        room_id?: number
    }
    const [rAmenities, setRAmenities] = useState<AmenitiesInface[]>([]);
    const [rHighAmenities, setRHighAmenities] = useState<AmenitiesInface[]>([]);
    const [rDetails, setRDetails] = useState<RoomDetailsInface>({});
    const [rImages, setRImages] = useState<RoomImagesInface[]>([]);
    const [details, setDetails] = useState('');
    const [rPolicy, setrPolicy] = useState('');
    const [cPolicy, setcPolicy] = useState('');
    const [bPolicy, setbPolicy] = useState('');
    const [loading, setLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false);
    const [openFocusIndex, setOpenFocusIndex] = useState(0);
    const [guestsInputValue, setGuestsInputValue] = useState<GuestsInputs>({
        guestAdults: 1,
        guestChildren: 0,
        guestInfants: 0,
        petCount: 0,
    })

    const [selectedDate, setSelectedDate] = useState<DateRage>({
        startDate: moment(),
        endDate: null
    });
    const [reservedDate, setReservedDate] = useState([])

    let obj = localStorage.getItem('user')
    // @ts-ignore
    let user = JSON.parse(obj)

    const PHOTOS: string[] = [];
    const { id } = useParams<{ id?: any }>();

    const windowSize = useWindowSize();

    const getRooms = () => {
        let header: any = "";
        if (user !== null) {
            header = CISUI().HeaderRequest
        }
        setLoading(true)
        axios.get(config.apiserver + `roomdetails/${id}`, header)
            .then(function (response) {

                let totaldates = response.data.booked_date;
                let onlyDates: any = [];
                totaldates?.forEach((item: any) => {
                    onlyDates.push(moment(item.booked_date).format("MM/DD/YYYY"))
                })
                setReservedDate(onlyDates)
                setDetails(response.data.data.room_details.details)
                setrPolicy(response.data.data.room_details.room_policy)
                setbPolicy(response.data.data.room_details.booking_policy)
                setcPolicy(response.data.data.room_details.cancellation_policy)
                setRAmenities(response.data.amenities)
                setRHighAmenities(response.data.highlighted_amenities)
                setRDetails(response.data.data.room_details)
                setRImages(response.data.data.room_images)
                setLoading(false)
            })
            .catch(function (error) {
                setLoading(false)
            })
    }
    const dateRangeCheck = (date: any) => {
        if (date?.endDate !== null) {
            const getDaysBetweenDates = function (startDate: any, endDate: any) {
                const now = startDate.clone(), dates = [];

                while (now.isSameOrBefore(endDate)) {
                    dates.push(now.format('MM/DD/YYYY'));
                    now.add(1, 'days');
                }
                return dates;
            };

            const startDate = selectedDate.startDate;
            const endDate = selectedDate.endDate;

            const dateList = getDaysBetweenDates(startDate, endDate);

            const intersection = reservedDate.filter(element => dateList.includes(element));

            if (intersection.length > 0) {
                setSelectedDate({
                    startDate: selectedDate.startDate,
                    endDate: null,
                })
                CISUI().notification('warning', "Invalid selection");

            }
        }
    }

    var nights = 1;
    var price = 0;
    var discount = 0;
    var cleaning_price = 0;
    const countNights = () => {

        nights = moment(selectedDate.endDate === null ? moment(selectedDate.startDate).add(1, "day") : selectedDate.endDate).diff(moment(selectedDate.startDate), 'days');
        cleaning_price = (parseFloat(rDetails?.cleaning_fee as string));
        price = ((parseFloat(rDetails?.customer_price as string)) * nights);
        discount = price * (parseFloat(rDetails.discount as string) / 100);

    }
    useEffect(() => {
        getRooms()
    }, [])

    let history = useHistory();

    const restrictedRequestToBook = () => {
        if (selectedDate.endDate !== null) {
            let obj = localStorage.getItem('user')
            // @ts-ignore
            let user = JSON.parse(obj)

            let startDate = moment(selectedDate.startDate).format("MM-DD-YYYY");
            let endDate = moment(selectedDate.endDate).format("MM-DD-YYYY");


            if (user === null) {
                CISUI().notification('warning', 'Please Login First.')

                history.push({pathname: '/login/customer', state: `/requestcheckout/${id}?sd=${startDate}&ed=${endDate}&ag=${guestsInputValue?.guestAdults}&cg=${guestsInputValue?.guestChildren}&ig=${guestsInputValue?.guestInfants}&pet=${guestsInputValue?.petCount}`});
            }
            else {
                history.push(`/requestcheckout/${id}?sd=${startDate}&ed=${endDate}&ag=${guestsInputValue?.guestAdults}&cg=${guestsInputValue?.guestChildren}&ig=${guestsInputValue?.guestInfants}&pet=${guestsInputValue?.petCount}`);

            }
        } else {
            CISUI().notification('warning', 'Please Select Check out date.')
        }
    }


    const handleOpenModal = (index: number) => {
        setIsOpen(true);
        setOpenFocusIndex(index);
    };

    const handleCloseModal = () => setIsOpen(false);

    const renderSection1 = () => {
        return (
            <>
                <div className="listingSection__wrap !space-y-6">
                    {/* 1 */}
                    <div className="flex justify-between items-center">
                        <Badge name={rDetails?.room_type} />
                    </div>

                    {/* 2 */}
                    <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
                        {rDetails?.property_name}<br />
                    </h2>
                    <h2 className="flex items-center space-x-4">
                        Property Name : {rDetails?.room_name}
                    </h2>
                    {/* 3 */}
                    <div className="flex items-center space-x-4">
                        <span>
                            <i className="las la-map-marker-alt "></i>
                            <span className="ml-1"> {rDetails?.address}</span>
                        </span>
                    </div>

                    {/* 4 */}
                    <div className="items-center hidden">
                        <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" />
                        <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
                            Hosted by{" "}
                            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
                                Kevin Francis
                            </span>
                        </span>
                    </div>

                    {/* 5 */}
                    <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

                    {/* 6 */}
                    <div
                        className="flex items-center justify-between xl:justify-start space-x-6 xl:space-x-8 text-md text-neutral-700 dark:text-neutral-300">
                        {rDetails?.maximum_guest !== 0 ?
                            (
                                <div className="flex items-center space-x-3 ">
                                    <i className=" las la-user-friends text-2xl mr-2"></i>
                                    {rDetails?.maximum_guest} <span className="hidden sm:inline-block">Maximum
                                    </span>

                                </div>
                            ) : ""}

                        {rDetails?.bed_room !== 0 ? (
                            <div className="flex items-center space-x-3 ">
                                <i className=" las la-bed text-2xl "></i>
                                <span className="">
                                    {rDetails.bed_room} <span className="hidden sm:inline-block">beds</span>
                                </span>
                            </div>
                        ) : ""}

                        {rDetails?.sufa_bed !== 0 ? (
                            <div className="flex items-center space-x-3 ">
                                <i className=" las la-couch text-2xl "></i>
                                <span className="">
                                    {rDetails?.sufa_bed} <span className="hidden sm:inline-block">sofa bed</span>
                                </span>
                            </div>
                        ) : ""}

                        {rDetails?.bathroom !== 0 ? (
                            <div className="flex items-center space-x-3">
                                <i className=" las la-bath text-2xl"></i>
                                <span className="">
                                    {rDetails.bathroom} <span className="hidden sm:inline-block">bathroom</span>
                                </span>
                            </div>
                        ) : ""}

                        {rDetails?.kitchen !== 0 ? (
                            <div className="flex items-center space-x-3">
                                <i className=" las la-utensils text-2xl"></i>
                                <span className=" ">
                                    {rDetails.kitchen} <span className="hidden sm:inline-block">kitchen</span>
                                </span>
                            </div>
                        ) : ""}

                    </div>
                </div>

            </>
        );
    };

    const highAmenities = () => {
        return (
            <div className="listingSection__wrap">
                <div>
                    <h2 className="text-2xl font-semibold">Highlighted Amenities </h2>
                    <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                        About the property's highlighted amenities and services
                    </span>
                </div>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                <div className="text-sm text-neutral-700 dark:text-neutral-300 ">

                    {rHighAmenities.slice(0, 8).map((item) => (
                        <div key={item.name} className="inline-block my-4 mr-5">

                            <ButtonSecondary>
                                <span>{item.name}</span>
                            </ButtonSecondary>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const renderSection2 = () => {
        return (
            <div className="listingSection__wrap">
                <h2 className="text-2xl font-semibold">Room Details</h2>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                <div className="text-neutral-6000 dark:text-neutral-300">
                    <span dangerouslySetInnerHTML={createMarkup(details)}></span>
                </div>
            </div>
        );
    };

    const Amenities = () => {
        return (
            <div className="listingSection__wrap">
                <div>
                    <h2 className="text-2xl font-semibold">Amenities </h2>
                    <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                        About the property's amenities and services
                    </span>
                </div>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                {/* 6 */}
                <div className="text-sm text-neutral-700 dark:text-neutral-300 ">

                    {rAmenities.slice(0, 8).map((item) => (
                        <div key={item?.name} className="inline-block my-4 mr-5">
                            <ButtonSecondary>
                                {/*<i className={`text-3xl las ${item.icon}`}></i>*/}
                                <span className="">{item?.name}</span>
                            </ButtonSecondary>
                        </div>
                    ))}
                </div>

            </div>
        );
    };

    const renderSection7 = () => {
        
        let lt = '';
        let lg = '';

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        rDetails?.latitude !== undefined ? lt = rDetails?.latitude : 0.00
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        rDetails?.longitude !== undefined ? lg = rDetails?.longitude : 0.00;
        console.log("🚀 ~ file: index.tsx:409 ~ renderSection7 ~ rDetails:", rDetails)
        return (
            <div className="listingSection__wrap">
                {/* HEADING */}
                <div>
                    <h2 className="text-2xl font-semibold">Location</h2>
                    <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                        {rDetails?.address}
                    </span>
                </div>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
                {/* MAP */}
                <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
                    <div className="rounded-xl overflow-hidden">
                        {lt !== '' && lg !== '' ? (
                            <GoogleMapReact
                                bootstrapURLKeys={{
                                    key: "AIzaSyB_T6uN1DqnL_Tp426Z-PSoVVsLzOaMEx8",
                                }}
                                defaultZoom={15}
                                yesIWantToUseGoogleMapApiInternals
                                defaultCenter={{
                                    lat: parseFloat(lt),
                                    lng: parseFloat(lg)
                                }}
                            >
                                <LocationMarker lat={parseFloat(lt)} lng={parseFloat(lg)} />
                            </GoogleMapReact>) : ''}

                    </div>
                </div>
            </div>
        );
    };

    const renderSection8 = () => {
        return (
            <div className="listingSection__wrap">
                {/* HEADING */}
                <h2 className="text-2xl font-semibold">Things to know</h2>

                {/* CONTENT */}
                {rPolicy !== " ée" ? (
                    <>
                        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
                        <div>
                            <h4 className="text-lg font-semibold">Room policy</h4>
                            <span className="block mt-3 text-neutral-500 dark:text-neutral-400" dangerouslySetInnerHTML={createMarkup(rPolicy)} >
                            </span>
                        </div>
                    </>
                ) : ''}
                {bPolicy !== "  ée" ? (
                    <div>
                        <h4 className="text-lg font-semibold">Booking policy</h4>
                        <span className="block mt-3 text-neutral-500 dark:text-neutral-400" dangerouslySetInnerHTML={createMarkup(bPolicy)} >
                        </span>
                    </div>
                ) : ''}
                {cPolicy !== "  ée" ? (
                    <>
                        <div>
                            <h4 className="text-lg font-semibold">Cancellation policy</h4>
                            <span className="block mt-3 text-neutral-500 dark:text-neutral-400" dangerouslySetInnerHTML={createMarkup(cPolicy)} >
                            </span>
                        </div>
                        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
                    </>
                ) : ''}

                <div>
                    <h4 className="text-lg font-semibold">Check-in Check-out time</h4>
                    <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
                        <div
                            className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
                            <span>Check-in</span>
                            <span>{rDetails.check_in}</span>
                        </div>
                        <div className="flex space-x-10 justify-between p-3">
                            <span>Check-out</span>
                            <span>{rDetails.check_out}</span>
                        </div>
                    </div>
                </div>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

                {rDetails.remark ?
                    (<div>
                        <h4 className="text-lg font-semibold">Special Note</h4>
                        <div className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
                            {rDetails.remark}
                        </div>
                    </div>) : ''}
            </div>
        );
    };

    const renderSidebar = () => {
        return (
            <div className="listingSection__wrap shadow-xl p-7">
                {/* PRICE */}
                <div className="flex justify-between">
                    <span className="text-3xl font-semibold">
                        £{rDetails?.customer_price}
                        <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                            /night
                            <br />
                        </span>
                    </span>
                </div>

                {/* FORM */}
                <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
                    <StayDatesRangeInput
                        onClick={countNights()}
                        wrapClassName="divide-x divide-neutral-200 dark:divide-neutral-700"
                        onChange={(date) => {
                            setSelectedDate(date)
                            dateRangeCheck(date)
                        }}
                        numberOfMonths={1}
                        fieldClassName="p-5"
                        defaultValue={selectedDate}
                        anchorDirection={windowSize.width > 1400 ? "left" : "right"}
                        reservedDate={reservedDate}


                    />
                    <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
                    <GuestsInput
                        fieldClassName="p-5"
                        defaultValue={guestsInputValue}
                        onChange={(e) => setGuestsInputValue(e)}
                        maximum_guests={rDetails.maximum_guest}
                        pet={rDetails?.pet_fee !== 0}
                    />
                    <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>

                </form>

                {/* SUM */}
                <div className="flex flex-col space-y-4">
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                        <span>£{rDetails?.customer_price} x {nights || 0} nights</span>
                        <span>£{price || 0}</span>

                    </div>
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                        <span>Discount:</span>
                        <span>{rDetails.discount}%</span>
                    </div>
                    <div className="flex justify-between font-semibold">
                        <span>After Discount</span>
                        <span>£{(price) - discount}</span>
                    </div>
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                        <span>Cleaning fee:</span>
                        <span>£{cleaning_price || 0}</span>
                    </div>
                    {
                        rDetails?.pet_fee !== 0 ?
                            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                                <span>Pet fee:</span>
                                <span>£{rDetails?.pet_fee || 0} x {guestsInputValue?.petCount}</span>
                            </div> : ''
                    }
                    <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                    <div className="flex justify-between font-semibold">
                        <span>Total</span>
                        <span>£{(price + cleaning_price + ((parseFloat(rDetails?.pet_fee as string)) * (guestsInputValue?.petCount || 0))) - discount}</span>
                    </div>
                </div>
                <ButtonPrimary onClick={restrictedRequestToBook} type="button">Request to Book</ButtonPrimary>
            </div>
        );
    };

    return (
        loading ? <Loading /> :
            <div
                className={`nc-ListingStayDetailPage  ${className}`}
                data-nc-id="PropertyDetailPage"
            >

                <Helmet>
                    <title>{rDetails?.property_name === undefined ? "Room Details" : rDetails?.property_name} || Easy 2 Manage (E2M)</title>
                </Helmet>
                <>
                    {rImages.forEach((item, index) => {
                        PHOTOS.push(config.imageURL + 'public/upload/room_images/' + item.image + "?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260")
                    })}
                    <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
                        <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
                            <div
                                className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                                onClick={() => handleOpenModal(0)}
                            >
                                <NcImage
                                    containerClassName="absolute inset-0"
                                    className="object-cover w-full h-full rounded-md sm:rounded-xl"
                                    src={PHOTOS[0]}
                                    prevImageHorizontal
                                />
                                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
                            </div>
                            {PHOTOS.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
                                <div
                                    key={index}
                                    className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                                        }`}
                                >
                                    <NcImage
                                        containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                                        className="object-cover w-full h-full rounded-md sm:rounded-xl "
                                        src={item || ""}
                                        prevImageHorizontal
                                    />

                                    {/* OVERLAY */}
                                    <div
                                        className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                                        onClick={() => handleOpenModal(index + 1)}
                                    />
                                </div>
                            ))}

                            <div
                                className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                                onClick={() => handleOpenModal(0)}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={1.5}
                                        d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                                    />
                                </svg>
                                <span className="ml-2 text-neutral-800 text-sm font-medium">
                                    Show all photos
                                </span>
                            </div>
                        </div>
                    </header>
                    {/* MODAL PHOTOS */}

                    <ModalPhotos
                        imgs={PHOTOS}
                        isOpen={isOpen}
                        onClose={handleCloseModal}
                        initFocus={openFocusIndex}
                    />
                </>

                {/* MAIn */}
                <main className="container mt-11 flex ">


                    {/* CONTENT */}
                    <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
                        {renderSection1()}
                        <div className="md:hidden px-5 sm:px-10">
                            {renderSidebar()}
                        </div>
                        {rHighAmenities.length !== 0 ? highAmenities() : ""}
                        {renderSection2()}
                        {rAmenities.length !== 0 ? Amenities() : ""}
                        {console.log(rDetails?.latitude, rDetails?.longitude)}

                        {rDetails?.latitude && renderSection7()}
                        {renderSection8()}
                    </div>

                    {/*SIDEBAR*/}
                    <div className="hidden lg:block flex-grow">
                        <div className="sticky top-24">{renderSidebar()}</div>
                    </div>

                </main>

                {/* OTHER SECTION */}
                {!isPreviewMode && (
                    <div className="container py-24 lg:py-32">
                        <GridCategoryBox />
                    </div>
                )}

            </div>
    );
};

export default PropertyDetailPage;