const config = {
  apiserver: "https://e2msandbox.datalibrary.io/api/",
  verify_url: "https://e2msandbox.datalibrary.io/",
  // apiserver: 'http://localhost:8000/api/',
  imageURL: "https://e2msandbox.datalibrary.io/",
  stripe_key:
    "pk_live_51K9W10BSzlec9v53J7r4xK8IVTS203ZvepTqb2yLRAVVNK6j8aU9Q2fSxj26uZHAMVyh1YSELZQzdLSOhuBgvjH900WfmjSu6d",
  // stripe_key:
  //   "pk_test_51MJVSoHFwED0J18IfjhvEdcKhtHd5vmzLyomRijS6enqspNgnNqK1FfWNDU6ybVqo0hzNAnwdCi7X0anXx3fHsvd00PJYFRMVJ",
  // stripe_key: "pk_test_51MvHprGEBsvur3nsEzZaH2OYUAwoiSEpjRoAv9TfAt2Z6FA99QkgM20OABEm8xBYjVxXqvNfDb6CdxawhKE2dQKQ00c5SbgQaq"
  // stripe_key:
  //   "pk_live_51MgAgGLFpL5zgcibFjKsyeO70TPKkgQVu65xfzAAXWTKYtfg1oMngiXOAr8ivTuzCN7WJUvJKbJw1ZHumhkZzc7000detFYkyT",
};

export default config;
