import BookedRoom from "containers/BookingDetails/BookedRoom";
import { FC } from "react";
export interface BookedPropertyProps {
  className?: string;
}

const BookedProperty: FC<BookedPropertyProps> = () => {
  return (
    <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
      <BookedRoom />
    </div>
  );
};
export default BookedProperty;
