import { FC, ReactNode, useEffect, useState } from "react";
// import axios from "axios";
import config from "../../utils/config";
import Heading from "../../shared/Heading/Heading";
import Nav from "../../shared/Nav/Nav";
import NavItem from "../../shared/NavItem/NavItem";
import ButtonSecondary from "../../shared/Button/ButtonSecondary";
import { Link } from "react-router-dom";
import GallerySlider from "components/GallerySlider/GallerySlider";
import Loading from "components/Loading/Loading";
import { useQuery } from "react-query";
import axiosinstance from "utils/AxiosInstance";
import Badge from "shared/Badge/Badge";

let PHOTOS: string[] = [];

export interface HomeFeaturedRoomProps {
  tabActive?: string;
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
  onClickTab?: (item: string) => void;
  id?: string;
  saleOff?: any;
}
const HomeFeaturedRoom: FC<HomeFeaturedRoomProps> = ({
  tabActive = "",
  gridClass = "",
  heading = "Featured Properties to stay",
  subHeading = "Highly rated properties to stay that E2M recommends for you",
  onClickTab,
}) => {
  const [tabActiveState, setTabActiveState] = useState(tabActive);
  // const [rooms, setRooms] = useState([]);
  const [filter, setFilter] = useState("");
  const [isClick, setIsClick] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const saleOff = "";
  useEffect(() => {
    setTabActiveState(tabActive);
  }, [tabActive]);
  const handleClickTab = (item: string) => {
    onClickTab && onClickTab(item);
    setTabActiveState(item);
    let filtered = tabs.filter((tab, index) => tab === item)[0];

    // @ts-ignore
    setFilter(filtered);
    setIsClick(true);
  };
  const [tabs, setTabs] = useState([]);

  const { data: roomTypes } = useQuery(["roomtypes"], () =>
    axiosinstance.get("roomtypes").then((res) => res.data.data)
  );
  const { data, isLoading } = useQuery(["featuredroomlist"], () =>
    axiosinstance.get("featuredroomlist").then((res) => res.data.data)
  );

  useEffect(() => {
    if (roomTypes) {
      let tab: string[] = [];
      roomTypes.forEach((item: { name: string }, index: any) => {
        tab.push(item.name);
      });
      // @ts-ignore
      setTabs(tab);
    }
  }, [roomTypes]);

  const content = (
    id: number,
    property_name: string,
    bed_room: string,
    bathroom: string,
    size: string,
    price: string,
    images: any[],
    is_featured: number,
    discount: number,
    address: string,
    latitude: string,
    longitude: string,
    amenities: any[]
  ): JSX.Element => {
    PHOTOS = [];
    images.map(({ room_id, image }) =>
      room_id === id ? PHOTOS.push(config.imageURL + image) : ""
    );

    return (
      <div
        className={`nc-PropertyCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow h-full`}
        data-nc-id="PropertyCardH"
        key={id}
      >
        <Link
          to={`/property-details/${id}`}
          className="w-full flex flex-col sm:flex-row sm:items-center"
        >
          <div className="flex-shrink-0 p-3 w-full h-full sm:w-64">
            <GallerySlider
              ratioClass="aspect-w-1 aspect-h-1"
              galleryImgs={PHOTOS}
              className="w-full h-full rounded-2xl overflow-hidden"
              uniqueID={`PropertyCardH-${Date.now()}-${id}`}
            />

            {discount === 0 ? (
              ""
            ) : (
              <div
                className={`nc-SaleOffBadge flex items-center justify-center text-xs py-0.5 px-3 text-red-50 rounded-full absolute left-5 top-5 bg-orange-500`}
                data-nc-id="SaleOffBadge"
              >
                {"-"} {discount} {"%"}
              </div>
            )}
          </div>
          <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
            <div className="space-y-4 w-full">
              <div className="flex items-center space-x-2">
                <h2 className="text-lg font-medium capitalize">
                  <span className="line-clamp-2">{property_name}</span>
                </h2>
              </div>
              <div className="flex items-center space-x-4">
                <i className="las la-map-marker-alt"></i>
                <span className="ml-1"> {address}</span>
              </div>

              <div className="inline-grid grid-cols-3 gap-2">
                <div className="flex flex-col items-center space-x-2">
                  <span className="hidden sm:inline-block">
                    <i className="las la-bed text-lg" />
                  </span>
                  <span className="text-xs text-neutral-500 dark:text-neutral-400">
                    {bed_room} beds
                  </span>
                </div>

                <div className="flex flex-col items-center space-x-2">
                  <span className="hidden sm:inline-block">
                    <i className="las la-bath text-lg" />
                  </span>
                  <span className="text-xs text-neutral-500 dark:text-neutral-400">
                    {bathroom} baths
                  </span>
                </div>

                {/* ---- */}
                <div className="flex flex-col items-center space-x-2">
                  <span className="hidden sm:inline-block">
                    <i className="las la-expand-arrows-alt text-lg" />
                  </span>
                  <span className="text-xs text-neutral-500 dark:text-neutral-400">
                    {size} Sq. Fit
                  </span>
                </div>
              </div>
              <div className="flex items-center space-x-2">
                {amenities.slice(0, 3).map((item: any, index: number) => (
                  <Badge
                    key={index}
                    name={item}
                    className="text-center nc-PropertyCardH group relative  dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow h-full !text-white"
                    color="primary"
                  />
                ))}
                {/* <Badge name="..." className="text-center nc-PropertyCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow h-full !text-white" /> */}
              </div>
              {/* <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 "></div> */}
              <div className="flex w-full justify-between items-end">
                <span className="flex items-center justify-center px-3 py-2 border border-secondary-500 rounded leading-none text-base font-medium text-secondary-500">
                  £{price}
                </span>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  };

  // @ts-ignore
  return (
    <div
      className="nc-SectionGridFeatureProperty relative"
      id="SectionGridFeatureProperty"
    >
      <div className="flex flex-col mb-8 relative">
        <Heading desc={subHeading}>{heading}</Heading>
        <div className="flex items-center justify-between">
          <Nav
            className="sm:space-x-2"
            containerClassName="relative flex w-full overflow-x-auto text-sm md:text-base hiddenScrollbar"
          >
            {tabs.map((item, index) => (
              <NavItem
                key={index}
                isActive={tabActiveState === item}
                onClick={() => handleClickTab(item)}
              >
                {item}
              </NavItem>
            ))}
          </Nav>
        </div>
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <div
          className={`grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 ${gridClass}`}
        >
          <>
            {/* @ts-ignore */}
            {data?.map(
              (
                {
                  id,
                  property_name,
                  bed_room,
                  size,
                  bathroom,
                  price,
                  discount,
                  room_type,
                  images,
                  is_featured,
                  address,
                  latitude,
                  longitude,
                  amenities,
                }: any,
                index: number
              ) =>
                is_featured === 1
                  ? isClick === false
                    ? content(
                        id,
                        property_name,
                        bed_room,
                        bathroom,
                        size,
                        price,
                        images,
                        is_featured,
                        discount,
                        address,
                        latitude,
                        longitude,
                        amenities
                      )
                    : room_type === filter
                    ? content(
                        id,
                        property_name,
                        bed_room,
                        bathroom,
                        size,
                        price,
                        images,
                        is_featured,
                        discount,
                        address,
                        latitude,
                        longitude,
                        amenities
                      )
                    : ""
                  : ""
            )}
          </>
        </div>
      )}
      <div className="mt-5 flex justify-center">
        <span>
          <ButtonSecondary className="!leading-none" href={"/book-now"}>
            <span>View all</span>
            <i className="ml-3 las la-arrow-right text-xl" />
          </ButtonSecondary>
        </span>
      </div>
    </div>
  );
};

export default HomeFeaturedRoom;
