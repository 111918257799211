import { toast } from "react-toastify";

const CISUI = () => {
  const LocalStorage = () => {};

  const HeaderRequest = () => {
    const token = localStorage.getItem("access_token");
    return { headers: { Authorization: `${token}` } };
  };

  const createNotification = (type: string, message: string) => {
    let notify: string = "";
    switch (type) {
      case "info":
        toast.info(message);
        break;
      case "success":
        toast.success(message);
        break;
      case "warning":
        toast.warning(message);
        break;
      case "error":
        toast.error(message);
        break;
      default:
        toast.success(message);
        break;
    }
    return notify;
  };

  return {
    HeaderRequest: HeaderRequest(),
    LocalStorage: LocalStorage(),
    notification: createNotification,
  };
};

export { CISUI };

export const GetPaymentStatus = (status: string) => {
  switch (status) {
    case "Amount Capturable Updated":
      return <p className="text-warning">Amount Capturable Updated</p>;
    case "Payment Canceled":
      return <p className="text-danger">Payment Canceled</p>;
    case "Payment Intent Created":
      return <p className="text-success">Payment Intent Created</p>;
    case "Payment Partially Funded":
      return <p className="text-danger">Payment Partially Funded</p>;
    case "Payment Failed":
      return <p className="text-danger">Payment Failed</p>;
    case "Processing":
      return <p className="text-danger">Processing</p>;
    case "Requires Action":
      return <p className="text-danger">Requires Action</p>;
    case "Payment Succeeded":
      return <p className="text-success">Payment Succeeded</p>;
    default:
      return <p className="text-warning">Not Paid</p>;
  }
};
