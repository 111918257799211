// import BackgroundSection from "components/BackgroundSection/BackgroundSection";
// import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import axios from "axios";
import config from "../../utils/config";
// @ts-ignore
import DOMPurify from "dompurify";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import HowItWork from "components/Section/HowItWork";
import Background from "components/Section/Background";
import SliderNewCategories from "components/Section/SliderNewCategories";
import OurFeatures from "components/Section/OurFeatures";
import Loading from "components/Loading/Loading";
import { heroInterface } from "components/Section/HeroTwo";
export interface ListingStayPageProps {
  className?: string;
}

const Accommodation: FC<ListingStayPageProps> = ({ className = "" }) => {
  const [loading, setLoading] = useState(false);
  const [hero, setHero] = useState<heroInterface>();
  useEffect(() => {
    heroAllData();
  }, []);

  const heroAllData = () => {
    setLoading(true);
    axios
      .get(config.apiserver + "widgets?page_name=Accommodation")
      .then(function (response) {
        const allData = response.data.data;
        setHero(allData[0]);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
      });
  };
  const createMarkup = (html: any) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };
  return loading ? (
    <Loading />
  ) : (
    <div
      className={`nc-ListingStayPage relative overflow-hidden ${className}`}
      data-nc-id="Accommodation"
    >
      <Helmet>
        <title>Easy 2 Manage (E2M) || Service Accommodation Specialist</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative overflow-hidden">
        {/* SECTION HERO */}
        <div
          className={`nc-SectionHeroArchivePage flex flex-col relative`}
          data-nc-id="SectionHeroArchivePage"
        >
          <div className="flex flex-col lg:flex-row lg:items-center">
            <div
              className={`pt-10 lg:pt-16 flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-6 lg:space-y-10 pb-14 lg:pb-32 xl:pb-64 xl:pr-14 lg:mr-10 xl:mr-0`}
            >
              <h2 className="font-medium text-2xl md:text-3xl xl:text-4xl leading-[110%]">
                {/*{coverTitle}*/}
                {hero?.name}
              </h2>
              <div className="flex items-center text-justify md:text-lg text-neutral-500 dark:text-neutral-400">
                <span
                  className="ml-2.5"
                  dangerouslySetInnerHTML={createMarkup(hero?.body)}
                >
                  {/*{subTitle}*/}
                </span>
              </div>
              <div className="flex items-center text-justify md:text-lg text-neutral-500 dark:text-neutral-400">
                <span className="ml-2.5">{/*{subTitle2}*/}</span>
              </div>
            </div>

            <div className="flex-grow lg:-mt-40 xl:-mt-56">
              <img
                className="w-full rounded-3xl"
                src={config.imageURL + "public" + hero?.image}
                alt="hero"
              />
            </div>
          </div>
        </div>

        {/* SECTION 2 */}
        <HowItWork className={"lg:pb-32"} title="Why Choose Us" desc="" />
        <div className="relative py-16 mb-8">
          <Background />
          <SliderNewCategories id="1" />
        </div>
        {/* SECTION 6 */}
        <OurFeatures type="type2" />
      </div>
    </div>
  );
};

export default Accommodation;
