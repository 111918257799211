import { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import { useParams } from "react-router-dom";
import axios from "axios";
import config from "../../utils/config";
import moment from "moment";
import { CISUI } from "utils/CISUI";
import Badge from "shared/Badge/Badge";
import { useMutation, useQueryClient } from "react-query";
import axiosinstance from "utils/AxiosInstance";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Modal } from "@material-ui/core";
import ConfirmPayment from "containers/CheckOutPage/ConfirmPayment";
import Loading from "components/Loading/Loading";
export interface BookingRequestProps {
  className?: string;
}

const BookingRequestDetails: FC<BookingRequestProps> = ({ className = "" }) => {
  interface BookingsProps {
    check_in?: String;
    check_out?: String;
    booking_id?: number;
    customer_name?: String;
    discount_amount?: number;
    grand_total?: number;
    guest?: number;
    image?: String;
    property_name?: String;
    room_id?: number;
    room_no?: String;
    status?: number;
    total?: number;
    total_discount?: number;
    request_id?: number;
    property_id?: number;
    price?: number;
    nights?: number;
    cleaning_fee?: number;
    whole_discount_amount?: number;
    whole_discount?: number;
    advance_paid?: number;
    advance?: number;
  }

  const { id } = useParams<{ id?: any }>();

  const stripePromise = loadStripe(config.stripe_key);

  const [bookings, setBookings] = useState<BookingsProps>({});
  const [loading, setloading] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [open, setOpen] = useState(true);

  const [status, setStatus] = useState("");
  const queryClient = useQueryClient();

  const newbooking = useMutation((data: any) => {
    const res = axiosinstance
      .post(`booking/newbooking/${id}`, data)
      .then((res) => {
        if (res.data.status === 1) {
          setClientSecret(res.data.client_secret);
          setOpen(true);
        } else {
          CISUI().notification("error", res.data.msg);
        }
      });
    return res;
  });

  const getRequestDetails = () => {
    setloading(true);
    axios
      .get(
        config.apiserver + `booking/pendingcustomerbookreqdetails/${id}`,
        CISUI().HeaderRequest
      )
      .then(function (response) {
        setBookings(response.data.data);
        setloading(false);
        let currentStatus =
          response.data.data?.status === 0
            ? "Request Pending"
            : response.data.data?.status === 1
            ? "Request Accepted."
            : response.data.data?.status === 2
            ? "Request Declined"
            : response.data.data?.status === 3
            ? "Canceled"
            : "";
        setStatus(currentStatus);
      })
      .catch(function (error) {
        setloading(false);
      });
  };

  const handleCancel = () => {
    setloading(true);
    axios
      .post(
        config.apiserver + `booking/bookingreqcancel/${id}`,
        null,
        CISUI().HeaderRequest
      )
      .then(function (res) {
        if (res.data.status === 1) {
          setloading(false);
          setStatus("Canceled");
          queryClient.refetchQueries(["booking/pendingcustomerbookingreq"]);
        }
        setloading(false);
      })
      .catch(function (error) {
        setloading(false);
      });
  };
  useEffect(() => {
    getRequestDetails();
  }, []);
  const options = {
    clientSecret,
  };

  return loading ? (
    <Loading />
  ) : (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
      {/* <Elements stripe={stripePromise} options={options}> */}
      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">
          <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
            <div className="space-y-6">
              <div className="flex justify-between items-center">
                <h3 className="text-2xl font-semibold">
                  Booking Request Submitted
                </h3>
                <Badge className="py-2" name={status} />
              </div>
              <div className="flex flex-col sm:flex-row sm:items-center">
                <div className="flex-shrink-0 w-full sm:w-40">
                  <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                    <NcImage src={config.imageURL + bookings?.image} />
                  </div>
                </div>
                <div className="pt-5  sm:pb-5 sm:px-5 space-y-3">
                  <div>
                    <span className="font-semibold mt-1 block text-3xl">
                      {bookings?.property_name}
                    </span>
                  </div>
                  <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                    Room Name: {bookings?.room_no}
                  </span>
                </div>
              </div>
              <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
                <div className="flex-1 p-5 flex space-x-4">
                  <svg
                    className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
                      stroke="#D1D5DB"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Date</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      {moment(`${bookings?.check_in}`).format("ll")} {"to"}{" "}
                      {moment(`${bookings?.check_out}`).format("ll")}
                    </span>
                  </div>
                </div>
                <div className="flex-1 p-5 flex space-x-4">
                  <svg
                    className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 5.07987C14.8551 4.11105 16.1062 3.5 17.5 3.5C20.0773 3.5 22.1667 5.58934 22.1667 8.16667C22.1667 10.744 20.0773 12.8333 17.5 12.8333C16.1062 12.8333 14.8551 12.2223 14 11.2535M17.5 24.5H3.5V23.3333C3.5 19.4673 6.63401 16.3333 10.5 16.3333C14.366 16.3333 17.5 19.4673 17.5 23.3333V24.5ZM17.5 24.5H24.5V23.3333C24.5 19.4673 21.366 16.3333 17.5 16.3333C16.225 16.3333 15.0296 16.6742 14 17.2698M15.1667 8.16667C15.1667 10.744 13.0773 12.8333 10.5 12.8333C7.92267 12.8333 5.83333 10.744 5.83333 8.16667C5.83333 5.58934 7.92267 3.5 10.5 3.5C13.0773 3.5 15.1667 5.58934 15.1667 8.16667Z"
                      stroke="#D1D5DB"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Guests</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      {bookings?.guest} Guests
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="space-y-6">
              <h3 className="text-2xl font-semibold">Request details</h3>
              <div className="flex flex-col space-y-4">
                <div className="flex text-neutral-6000 dark:text-neutral-300">
                  <span className="flex-1">Request Id:</span>
                  <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                    {bookings?.request_id}
                  </span>
                </div>
                <div className="flex text-neutral-6000 dark:text-neutral-300">
                  <span className="flex-1">Total:</span>
                  <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                    {bookings?.price} x {bookings?.nights} = £ {bookings?.total}
                  </span>
                </div>
                <div className="flex text-neutral-6000 dark:text-neutral-300">
                  <span className="flex-1">Cleaning fee:</span>
                  <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                    £ {bookings?.cleaning_fee}
                  </span>
                </div>

                {bookings?.whole_discount !== 0 ? (
                  <>
                    <div className="flex text-neutral-6000 dark:text-neutral-300">
                      <span className="flex-1">Total discount (%):</span>
                      <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                        {bookings?.whole_discount}%
                      </span>
                    </div>

                    <div className="flex text-neutral-6000 dark:text-neutral-300">
                      <span className="flex-1">Total Discount Amount:</span>
                      <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                        £ {bookings?.whole_discount_amount}
                      </span>
                    </div>
                  </>
                ) : null}

                <div className="flex text-neutral-6000 dark:text-neutral-300">
                  <span className="flex-1">Total Amount:</span>
                  <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                    £ {bookings?.grand_total}
                  </span>
                </div>
                {bookings?.advance !== 0 && (
                  <div className="flex text-neutral-6000 dark:text-neutral-300">
                    <span className="flex-1">Advance Payment:</span>
                    <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                      £ {bookings?.advance} -{" "}
                      {bookings?.advance_paid === 1 ? (
                        <span className="text-green-500">Paid</span>
                      ) : (
                        <span className="text-red-500">Not Paid</span>
                      )}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div>
              {/* {bookings?.status === 1 && (
                <ButtonPrimary
                  href=""
                  className="w-full"
                  onClick={() => {
                    newbooking.mutate();
                  }}
                >
                  Make Payment
                </ButtonPrimary>
              )} */}
              {bookings?.status === 1 && (
                <ButtonPrimary
                  href=""
                  className="w-full"
                  onClick={() => {
                    newbooking.mutate({
                      advance_pay: bookings?.advance === 0 ? 0 : 1,
                    });
                  }}
                >
                  {bookings?.advance === 0 ? `Pay Now £ ${bookings?.grand_total}` : `Advance Pay £ ${bookings?.advance}`}
                </ButtonPrimary>
              )}
              {clientSecret !== "" ? (
                <Elements stripe={stripePromise} options={options}>
                  <Modal
                    open={open}
                    onClose={() => {
                      setClientSecret("");
                      setOpen(false);
                    }}
                  >
                    <>
                      <ConfirmPayment
                        setOpen={setOpen}
                        amount={bookings?.advance}
                      />
                    </>
                  </Modal>
                </Elements>
              ) : null}

              {bookings?.status === 0 && status !== "Canceled" && (
                <ButtonPrimary
                  className="w-full"
                  href=""
                  onClick={handleCancel}
                >
                  Cancel Booking
                </ButtonPrimary>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default BookingRequestDetails;
