import React, { FC, useEffect, useState } from "react";
import NcImage from "shared/NcImage/NcImage";

import VectorImg from "images/VectorHIW.svg";
import axios from "axios";
import config from "../../utils/config";
// @ts-ignore
import DOMPurify from "dompurify";
import Loading from "../Loading/Loading";
import Heading from "components/Heading/Heading";
import { useQuery } from "react-query";

export interface HowItWorkProps {
  className?: string;
  data?: {
    id: number;
    title: string;
    desc: string;
    img: string;
    imgDark?: string;
  }[];
  title?: string;
  desc?: string;
}

export interface itemInterface {
  image: string;
  name: string;
  body: string;
  imgDark?: string;
}
const HowItWork: FC<HowItWorkProps> = ({
  className = "",
  // data = DEMO_DATA,
  title = "Why Choose Us",
  desc = "",
}) => {
  const [imgDark, setImgDark] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("theme") === "dark") {
      setImgDark(true);
    } else {
      setImgDark(false);
    }
  }, [localStorage.getItem("theme")]);

  const { data: items, isLoading } = useQuery(["widgets?page_name=Home"], () =>
    axios
      .get(config.apiserver + "widgets?page_name=Home")
      .then((res) => res.data.data)
  );

  const createMarkup = (html: any) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };
  return isLoading ? (
    <Loading />
  ) : (
    <div
      className={`nc-SectionHowItWork  ${className}`}
      data-nc-id="SectionHowItWork"
    >
      <Heading isCenter desc={desc}>
        {title}
      </Heading>
      <div className="mt-20 relative grid md:grid-cols-3 gap-20">
        <img
          className="hidden md:block absolute inset-x-0 top-10"
          src={VectorImg}
          alt=""
        />
        {imgDark ? (
          <>
            <div className="relative flex flex-col items-center max-w-xs mx-auto">
              <NcImage
                containerClassName="mb-8 max-w-[200px] mx-auto"
                src={config.imageURL + "public" + items?.[1]?.image}
              />
              <div className="text-center ">
                <h3 className="text-xl font-semibold">{items?.[1]?.name}</h3>
                <span
                  className="block mt-5 text-neutral-500 dark:text-neutral-400"
                  dangerouslySetInnerHTML={createMarkup(items?.[1]?.body)}
                ></span>
              </div>
            </div>

            <div className="relative flex flex-col items-center max-w-xs mx-auto">
              <NcImage
                containerClassName="mb-8 max-w-[200px] mx-auto"
                src={config.imageURL + "public" + items?.[2]?.image}
              />
              <div className="text-center ">
                <h3 className="text-xl font-semibold">{items?.[2]?.name}</h3>
                <span
                  className="block mt-5 text-neutral-500 dark:text-neutral-400"
                  dangerouslySetInnerHTML={createMarkup(items?.[2]?.body)}
                >
                  {/*{itemTwo?.body}*/}
                </span>
              </div>
            </div>
            <div className="relative flex flex-col items-center max-w-xs mx-auto">
              <NcImage
                containerClassName="mb-8 max-w-[200px] mx-auto"
                src={config.imageURL + "public" + items?.[3]?.image}
              />
              <div className="text-center ">
                <h3 className="text-xl font-semibold">{items?.[3]?.name}</h3>
                <span
                  className="block mt-5 text-neutral-500 dark:text-neutral-400"
                  dangerouslySetInnerHTML={createMarkup(items?.[3]?.body)}
                >
                  {/*{itemThree?.body}*/}
                </span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="relative flex flex-col items-center max-w-xs mx-auto">
              <NcImage
                containerClassName="mb-8 max-w-[200px] mx-auto"
                src={config.imageURL + "public" + items?.[4]?.image}
              />
              <div className="text-center ">
                <h3 className="text-xl font-semibold">{items?.[4]?.name}</h3>
                <span
                  className="block mt-5 text-neutral-500 dark:text-neutral-400"
                  dangerouslySetInnerHTML={createMarkup(items?.[4]?.body)}
                >
                  {/*{itemOne?.body}*/}
                </span>
              </div>
            </div>

            <div className="relative flex flex-col items-center max-w-xs mx-auto">
              <NcImage
                containerClassName="mb-8 max-w-[200px] mx-auto"
                src={config.imageURL + "public" + items?.[5]?.image}
              />
              <div className="text-center ">
                <h3 className="text-xl font-semibold">{items?.[5]?.name}</h3>
                <span
                  className="block mt-5 text-neutral-500 dark:text-neutral-400"
                  dangerouslySetInnerHTML={createMarkup(items?.[5]?.body)}
                >
                  {/*{itemTwo?.body}*/}
                </span>
              </div>
            </div>
            <div className="relative flex flex-col items-center max-w-xs mx-auto">
              <NcImage
                containerClassName="mb-8 max-w-[200px] mx-auto"
                src={config.imageURL + "public" + items?.[6]?.image}
              />
              <div className="text-center ">
                <h3 className="text-xl font-semibold">{items?.[6]?.name}</h3>
                <span
                  className="block mt-5 text-neutral-500 dark:text-neutral-400"
                  dangerouslySetInnerHTML={createMarkup(items?.[6]?.body)}
                ></span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default HowItWork;
