import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import HomeFeaturedRoom from "./FeatureRoom";
import Hero from "components/Section/Hero";
// import HowItWork from "components/Section/HowItWork";
import Background from "components/Section/Background";
import GridCategoryBox from "components/Section/GridCategoryBox";
// import OurFeatures from "components/Section/OurFeatures";
import ProudMembers from "./ProudMembers";
import Review from "components/Section/Review";
import DowloadApp from "components/Section/DownloadApp";

function PageHome() {
  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-cyan-blueGrey";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);

  // @ts-ignore
  return (
    <div className="nc-PageHome2 relative overflow-hidden">
      <Helmet>
        <title>Easy 2 Manage (E2M) || The Property Management Company</title>
      </Helmet>

      <div className="container relative space-y-24 mb-24 lg:space-y-32 lg:mb-32">
        <Hero className="lg:mt-2" />

        {/* <HowItWork /> */}

        <div className="relative py-16">
          <Background />
          <HomeFeaturedRoom />
        </div>

        <GridCategoryBox />

        {/* <OurFeatures type="type2" /> */}

        <DowloadApp />

        <ProudMembers />

        <div className="relative py-16">
          <Background />
          <Review />
        </div>
      </div>
    </div>
  );
}

export default PageHome;
