import { TaxonomyType } from "data/types";
import React from "react";
import axios from "axios";
import config from "../../utils/config";
import Loading from "../Loading/Loading";
import Heading from "components/Heading/Heading";
import CardCategoryBox from "components/CardCategoryBox";
import { useQuery } from "react-query";

export interface GridCategoryBoxProps {
  categories?: TaxonomyType[];
  headingCenter?: boolean;
  categoryCardType?: "card1";
  className?: string;
  gridClassName?: string;
}

const GridCategoryBox: React.FC<GridCategoryBoxProps> = ({
  headingCenter = true,
  className = "",
  gridClassName = "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4",
}) => {
  const { data, isLoading } = useQuery(["booking/customerbooking"], () =>
    axios.get(config.apiserver + "allblogs").then((res) => res.data.data)
  );

  const blogs = data?.map((item: any) => {
    return {
      id: item.id,
      href: `/cities-blog/${item.id}`,
      name: item.title,
      taxonomy: "category",
      count: 1288,
      thumbnail: config.imageURL + "/public/upload/cms/blog/" + item.image,
    };
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className={`nc-GridCategoryBox relative ${className}`}>
      <Heading
        desc="Discover great places near where you live"
        isCenter={headingCenter}
      >
        Explore Cities
      </Heading>
      <div className={`grid ${gridClassName} gap-5 sm:gap-6 md:gap-8`}>
        {blogs?.map((item: any, i: any) => (
          <CardCategoryBox key={i} taxonomy={item} />
        ))}
      </div>
    </div>
  );
};

export default GridCategoryBox;
