import React from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  RouteProps,
} from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import Header from "shared/Header/Header";
// import Page404 from "containers/Page404/Page404";
// import Accommodation from "containers/Services/Accommodation";
import Accommodation from "containers/Accommodation/Accommodation";
// import HolidayHouse from "containers/Services/HolidayHouse";
import HolidayHouse from "containers/Accommodation/HolidayHouse";
// import ShortTermRent from "containers/Services/ShortTermRent";
import ShortTermRent from "containers/Accommodation/ShortTermRent";
// import Apartment from "containers/Services/Apartment";

// import PropertyDetailPage from "containers/ListingDetailPage/PropertyDetailPage";
import PropertyDetailPage from "containers/ListingDetailPage";
// import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
// import PayPage from "containers/Pay/PayPage";
import PayPage from "containers/Pay/PayPage";
// import PageContact from "containers/PageContact/PageContact";
import PageContact from "containers/Contact";
// import PageAbout from "containers/Company/PageAbout";
// import PageSignUp from "containers/SignUp/PageSignUp";
import PageSignUp from "containers/SignUp/PageSignUp";
// import PageLogin from "containers/PageLogin/PageLogin";
import PageLogin from "containers/PageLogin/PageLogin";
// import OtpVerify from "containers/SignUp/OtpVerify";
import OtpVerify from "containers/SignUp/OtpVerify";

// import PageHome from "containers/PageHome/PageHome";
import PageHome from "containers/Home";

// import Property from "../containers/OwnerPortal/Property";
// import BookingReport from "../containers/OwnerPortal/BookingReport";
// import PaymentHistory from "../containers/OwnerPortal/PaymentHistory";
// import ProfileSetup from "../containers/OwnerPortal/ProfileSetup";
// import Service from "../containers/OwnerPortal/Services";
// import Customer from "../containers/CustomerPortal/Customer";
// import Customer from "pages/CustomerPortal/Customer";
// import SearchList from "../containers/PageHome/SearchList";
import SearchList from "containers/SearchList";
// import BookingPage from "../components/PropertyCardH/BookingPage";
import BookingPage from "containers/Booking";
import { CISUI } from "../utils/CISUI";
// import CustomerProfileSetup from "../containers/CustomerPortal/CustomerProfileSetup";
import CustomerProfileSetup from "containers/CustomerPortal/CustomerProfileSetup";
// import CustomerBookingHistory from "../containers/CustomerPortal/CustomerBookingHistory";
import CustomerBookingHistory from "containers/CustomerPortal/CustomerBookingHistory";
// import CustomerUpcomingBooking from "../containers/CustomerPortal/CustomerUpcomingBooking";
import CustomerUpcomingBooking from "containers/CustomerPortal/CustomerUpcomingBooking";
// import CustomerLogout from "../containers/CustomerPortal/CustomerLogout";
import CustomerLogout from "containers/CustomerPortal/CustomerLogout";
// import Terms from "../containers/PageHome/terms";
import Terms from "containers/terms";
// import BookingDetails from "components/BookingDetails/BookingDetails";
import BookingDetails from "containers/BookingDetails/BookingDetails";
// import CustomerPaymentHistory from "../containers/CustomerPortal/CustomerPaymentHistory";
import CustomerPaymentHistory from "containers/CustomerPortal/CustomerPaymentHistory";
// import ForgotPass from "containers/ForgotPass/ForgotPass";
import ForgotPass from "containers/ForgotPass/ForgotPass";
// import PassReset from "containers/ForgotPass/PassReset";
import PassReset from "containers/ForgotPass/PassReset";
// import NidVerification from "containers/CustomerPortal/NidVerification";
import NidVerification from "containers/CustomerPortal/NidVerification";
// import Complaint from "containers/Complaint/Complaint";
import Complaint from "containers/Complaint";
// import BookingRequestDetails from "containers/CustomerPortal/BookingRequestDetails";
import BookingRequestDetails from "containers/CustomerPortal/BookingRequestDetails";
// import CustomerBookingRequest from "../containers/CustomerPortal/CustomerBookingRequest";
import CustomerBookingRequest from "containers/CustomerPortal/CustomerBookingRequest";
// import RequestCheckOut from "containers/CheckOutPage/RequestCheckOut";
import RequestCheckOut from "containers/CheckOutPage/RequestCheckOut";
// import CitiesBlog from "../containers/PageHome/CitiesBlog";
import CitiesBlog from "containers/Home/CitiesBlog";
import About from "containers/About";
// import RetrievePayment from "containers/CheckOutPage/RetrievePayment";
import RetrievePayment from "containers/CheckOutPage/RetrievePayment";
import Apartment from "containers/Accommodation/Apartment";
import Page404 from "containers/Page404";
export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },

  { path: "/accommodation", component: Accommodation },
  { path: "/holiday-house", component: HolidayHouse },
  { path: "/short-term-rent", component: ShortTermRent },
  { path: "/apartment", component: Apartment },
  // {path: "/retrievepayment", component: RetrievePayment},

  { path: "/property-details/:id", component: PropertyDetailPage },
  { path: "/cities-blog/:id", component: CitiesBlog },
  { path: "/contact", component: PageContact },
  { path: "/about", component: About },
  { path: "/signup/:id", component: PageSignUp },
  { path: "/login/:id", component: PageLogin },
  { path: "/forgot-pass", component: ForgotPass },
  { path: "/password-reset", component: PassReset },
  { path: "/terms", component: Terms },
  { path: "/search", component: SearchList },
  { path: "/book-now", component: BookingPage },
  { path: "/complaint", component: Complaint },
  { path: "/otp-verification", component: OtpVerify },
];
export const pages2: Page[] = [
  // { path: "/owner/my-property", exact: true, component: Property },
  // { path: "/owner/booking-report", exact: true, component: BookingReport },
  // { path: "/owner/payment-history", exact: true, component: PaymentHistory },
  // { path: "/owner/services", exact: true, component: Service },
  // { path: "/owner/account-setup", exact: true, component: ProfileSetup },
  // { path: "/owner/message", exact: true, component: ProfileSetup },
  // { path: "/customer/customer", exact: true, component: Customer },
  { path: "/retrievepayment", exact: true, component: RetrievePayment }, // add later
  {
    path: "/customer/customer-upcoming-booking",
    exact: true,
    component: CustomerUpcomingBooking,
  },
  {
    path: "/customer/customer-booking-request",
    exact: true,
    component: CustomerBookingRequest,
  },
  {
    path: "/customer/customer-booking-history",
    exact: true,
    component: CustomerBookingHistory,
  },
  {
    path: "/customer/customer-profile-setup",
    exact: true,
    component: CustomerProfileSetup,
  },
  {
    path: "/customer/customer-payment-history",
    exact: true,
    component: CustomerPaymentHistory,
  },
  { path: "/customer/customer-logout", component: CustomerLogout },
  { path: "/checkout/:id", component: CheckOutPage },
  { path: "/pay-done/:id", component: PayPage },
  { path: "/customer/bookingdetails/:id", component: BookingDetails },

  { path: "/customer/bookingrequest/:id", component: BookingRequestDetails },

  { path: "/customer/nid-verification", component: NidVerification },
  { path: "/requestcheckout/:id", component: RequestCheckOut },
];
interface AuthRouteProps extends RouteProps {
  component: any;
  authenticated: boolean;
  rest?: any;
}
const PrivateRoute: React.FC<AuthRouteProps> = ({
  component: Component,
  authenticated,
  path,
  ...rest
}: any) => (
  <Route
    {...rest}
    path={path}
    render={(props) => {
      return localStorage.getItem("access_token") ? (
        <Component {...props} />
      ) : (
        (CISUI().notification("warning", "Please login first!"),
        (<Redirect to="/login/customer" />))
      );
    }}
  />
);

const Routes = () => {
  let authUser: boolean = false;
  if (localStorage.getItem("access_token")) {
    authUser = true;
  }
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <Switch>
          {pages.map(({ component, path, exact }) => {
            return (
              <Route
                key={path}
                component={component}
                exact={!!exact}
                path={path}
              />
            );
          })}

          {pages2.map(({ component, path }, index) => {
            return (
              <PrivateRoute
                key={index}
                component={component}
                authenticated={authUser}
                path={path}
              />
            );
          })}
          <Route component={Page404} />
        </Switch>
        <Footer />
        <CookieConsent
          location="bottom"
          disableStyles={true}
          buttonText="Accept"
          cookieName="easy2manageCookie"
          buttonClasses="drop-shadow-2xl py-1 px-5 lg:text-sm text-xs rounded-full bg-white text-primary"
          expires={150}
          containerClasses="fixed w-full primary-color flex flex-col lg:flex-row items-center justify-evenly py-1 text-white py-2"
          contentClasses="lg:w-8/12 w-11/12 text-center text-sm"
        >
          When you visit or interact with our sites, services or tools, we or
          our authorised service providers may use cookies for storing
          information to help provide you with a better, faster and safer
          experience.
        </CookieConsent>
      </BrowserRouter>
    </>
  );
};

export default Routes;
