import { FC } from "react";
// import StayCard from "../../components/StayCard/StayCard";
// import BookedRoom from "../StayCard/BookedRoom";
import BookedRoom from './BookedRoom'
export interface BookedPropertyProps {
	className?: string;
}

const BookedProperty: FC<BookedPropertyProps> = () => {
	return (
		<div className="grid grid-cols-1 gap-8">
			<BookedRoom />
		</div>
	)
}
export default BookedProperty;