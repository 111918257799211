import React, { FC, useEffect, useState } from "react";

import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import NcModal from "shared/NcModal/NcModal";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../utils/config";
// @ts-ignore
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import moment from "moment";
import useWindowSize from "../../hooks/useWindowResize";
import { CISUI } from "../../utils/CISUI";
import { DateRage } from "components/SearchForm/StaySearchForm";
import StartRating from "components/StarRating";
import StayDatesRangeInput from "components/SearchForm/StayDatesRangeInput";
import GuestsInput from "components/SearchForm/GuestsInput";

export interface CheckOutPageProps {
  className?: string;
}

export interface GuestsInputs {
  guestAdults?: number | undefined;
  guestChildren?: number | undefined;
  guestInfants?: number | undefined;
  petCount?: number | undefined;
}

const CheckOutPage: FC<CheckOutPageProps> = ({ className = "" }) => {
  let history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const startDate = urlParams.get("sd");
  const endDate = urlParams.get("ed");

  const adultGuest: any = urlParams.get("ag");
  const childGuest: any = urlParams.get("cg");
  const infantGuest: any = urlParams.get("ig");
  const petCount: any = urlParams.get("pet");

  const guest = {
    guestAdults: parseInt(adultGuest),
    guestChildren: parseInt(childGuest),
    guestInfants: parseInt(infantGuest),
    petCount: parseInt(petCount),
  };
  const [selectedDate, setSelectedDate] = useState<DateRage>({
    startDate: moment(),
    endDate: moment().add(1, "days"),
  });

  const [reservedDate, setReservedDate] = useState([]);

  const [guestsInputValue, setGuestsInputValue] = useState<GuestsInputs>(guest);

  const [btnDisabled, setBtnDisabled] = useState(true);
  const [btnLoading, setBtntLoading] = useState(false);

  const windowSize = useWindowSize();
  useEffect(() => {
    if (startDate) {
      setSelectedDate({
        startDate: moment(startDate, "MM-DD-YYYY"),
        endDate: moment(endDate, "MM-DD-YYYY"),
      });
    }
  }, []);
  interface RoomDetailsInface {
    balcony?: string | number;
    bathroom?: string | number;
    bed_room?: string | number;
    com_id?: string | number;
    commission?: string | number;
    created_at?: string | number;
    customer_price?: string | number;
    cleaning_fee?: string | number;
    details?: string | number;
    discount?: string | number;
    facilities?: any;
    id?: string | number;
    other_room?: string | number;
    owner_price?: string | number;
    priority?: string | number;
    property_id?: string | number;
    property_name?: string;
    remark?: string | number;
    room_name?: string | number;
    room_type?: string | number;
    latitude?: string | undefined;
    longitude?: string;
    address?: string | number;
    size?: string | number;
    status?: string | number;
    updated_at?: string | number;
    user_id?: string | number;
    kitchen?: string | number;
    pet_fee?: string | number;
    maximum_guest?: string | number;
  }

  interface RoomImagesInface {
    image?: string;
    room_id?: number;
  }

  let nights = 1;
  let price = 0;
  let discount = 0;
  let cleaning_price = 0;
  const countNights = () => {
    nights = moment(
      selectedDate.endDate === null
        ? moment(selectedDate.startDate).add(1, "day")
        : selectedDate.endDate
    ).diff(moment(selectedDate.startDate), "days");
    cleaning_price = parseFloat(rDetails?.cleaning_fee as string);
    price = parseFloat(rDetails?.customer_price as string) * nights;
    discount = price * (parseFloat(rDetails.discount as string) / 100);
  };

  const { id } = useParams<{ id?: any }>();

  const [rDetails, setRDetails] = useState<RoomDetailsInface>({});
  const [rImages, setRImages] = useState<RoomImagesInface[]>([]);
  const PHOTOS: string[] = [];
  const getRooms = () => {
    axios
      .get(config.apiserver + `roomdetails/${id}`)
      .then(function (response) {
        let totaldates = response.data.booked_date;
        let onlyDates: any = [];
        totaldates?.forEach((item: any) => {
          onlyDates.push(moment(item.booked_date).format("MM/DD/YYYY"));
        });

        setReservedDate(onlyDates);

        setRDetails(response.data.data.room_details);
        setRImages(response.data.data.room_images);
      })
      .catch(function (error) {
      });
  };
  useEffect(() => {
    getRooms();
  }, []);

  const dateRangeCheck = (date: any) => {

    if (date?.endDate !== null) {
      const getDaysBetweenDates = function (startDate: any, endDate: any) {
        const now = startDate.clone(),
          dates = [];

        while (now.isSameOrBefore(endDate)) {
          dates.push(now.format("MM/DD/YYYY"));
          now.add(1, "days");
        }
        return dates;
      };

      const dateList = getDaysBetweenDates(date.startDate, date.endDate);

      const intersection = reservedDate.filter((element) =>
        dateList.includes(element)
      );

      if (intersection.length > 0) {
        setSelectedDate({
          startDate: selectedDate.startDate,
          endDate: null,
        });
        CISUI().notification("warning", "Invalid selection");
      }
    }
  };

  const renderSidebar = () => {
    rImages.forEach((item) => {
      PHOTOS.push(
        config.imageURL +
          "public/upload/room_images/" +
          item.image +
          "?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
      );
    });

    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <NcImage src={PHOTOS[0]} />
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                Hotel room in {rDetails?.address}
              </span>
              <span className="text-base font-medium mt-1 block">
                {rDetails?.property_name}-{rDetails.room_name}
              </span>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                Property Name : {rDetails?.room_name}
              </span>
            </div>
            <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
              {rDetails.bed_room} beds · {rDetails.bathroom} baths
            </span>
            <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
            <StartRating />
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Price detail</h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>
              £{rDetails?.customer_price} x {nights} nights
            </span>
            <span>£{price}</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Discount</span>
            <span>-{rDetails.discount}%</span>
          </div>
          <div className="flex justify-between font-semibold">
            <span>After Discount</span>
            <span>£{price - discount}</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Cleaning fee:</span>
            <span>£{cleaning_price}</span>
          </div>
          {rDetails?.pet_fee !== 0 ? (
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>Pet fee:</span>
              <span>
                £{rDetails?.pet_fee || 0} x {guestsInputValue?.petCount}
              </span>
            </div>
          ) : (
            ""
          )}
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>
              £
              {price +
                cleaning_price +
                parseFloat(rDetails?.pet_fee as string) *
                  (guestsInputValue?.petCount || 0) -
                discount}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    const handleSubmit = async (event: { preventDefault: () => void }) => {
      setBtntLoading(true);
      event.preventDefault();

      const cardElement = elements!.getElement(CardElement);

      const { error, paymentMethod } = await stripe!.createPaymentMethod({
        type: "card",
        card: cardElement!,
      });

      if (!error) {
        // try {
        const tGuest =
          parseInt(adultGuest) + parseInt(childGuest) + parseInt(infantGuest);
        const customer: any = localStorage.getItem("user");
        let user = JSON.parse(customer);

        const values = {
          property_id: rDetails.property_id,
          stripeToken: paymentMethod!.id,
          customer_id: user.customer_id,
          guest: tGuest === 0 ? 1 : tGuest,
          booking_items: [
            {
              room_id: parseInt(id),
              room_no: rDetails?.room_name,
              check_in_date: moment(selectedDate.startDate).format(
                "YYYY-MM-DD"
              ),
              check_out_date: moment(selectedDate.endDate).format("YYYY-MM-DD"),
              nights: nights,
              customer_price: rDetails.customer_price,
              cleaning_fee: rDetails.cleaning_fee,
              discount: rDetails.discount,
              total: price + cleaning_price - discount,
            },
          ],
          logs: paymentMethod,
        };

        const response = await axios.post(
          config.apiserver + "booking/newbooking",
          values,
          CISUI().HeaderRequest
        );
        if (response.data.status === 1) {

          CISUI().notification("success", response.data.msg);
          setBtntLoading(false);
          history.push("/customer/customer-upcoming-booking");
        } else {
          CISUI().notification("error", response.data.msg);
          setBtntLoading(false);
        }
      } else {
        let msg: any = error.message;
        CISUI().notification("error", msg);
        setBtntLoading(false);
      }
    };

    const handleCheckbox = () => {
      setBtnDisabled(!btnDisabled);
    };
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Confirm and payment
        </h2>
        <form
          className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl "
          onSubmit={handleSubmit}
        >
          <div>
            <div className="p-4 sm:px-4 xl:p-5">
              <h3 className="text-2xl font-semibold">Your trip</h3>
              <NcModal
                contentExtraClass="lg:w-1/2"
                modalTitle="View Details"
                renderTrigger={(openModal) => (
                  <span
                    onClick={() => openModal()}
                    className="block lg:hidden underline  mt-1 cursor-pointer mb-3"
                  >
                    View booking details
                  </span>
                )}
                renderContent={renderSidebar}
              />
            </div>
            <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
            <StayDatesRangeInput
              wrapClassName="divide-x divide-neutral-200 dark:divide-neutral-700"
              onClick={countNights()}
              onChange={(date) => {
                setSelectedDate(date);

                dateRangeCheck(date);
              }}
              numberOfMonths={1}
              fieldClassName="p-5"
              defaultValue={selectedDate}
              anchorDirection={windowSize.width > 1400 ? "left" : "right"}
              reservedDate={reservedDate}
            />
            <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
            <GuestsInput
              fieldClassName="p-5"
              defaultValue={guestsInputValue}
              onChange={(e) => setGuestsInputValue(e)}
              maximum_guests={rDetails.maximum_guest}
              pet={rDetails?.pet_fee !== 0}
            />
            <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          </div>

          <div className="p-4 sm:p-4 xl:p-5">
            <h3 className="text-2xl font-semibold">Pay with Stripe</h3>
            <div className="mt-6">
              {/*<form id="payment-form" onSubmit={handleSubmit}>*/}

              <CardElement className="my-5 border-2 p-5 rounded-2xl border-neutral-200 dark:border-neutral-700 dark:bg-white !text-white" />

              {/* CheckBox */}

              <div className={`flex text-sm sm:text-base mb-5`}>
                <input
                  name="trams"
                  type="checkbox"
                  className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                  onChange={handleCheckbox}
                />

                <label
                  htmlFor="trams"
                  className="ml-3.5 flex flex-row text-neutral-6000 dark:text-neutral-300"
                >
                  I agree to the{" "}
                  <Link to="/terms" className="font-semibold ml-1">
                    Terms and Conditions
                  </Link>
                </label>
              </div>

              <div className="flex justify-between">
                <ButtonPrimary
                  disabled={btnDisabled}
                  loading={btnLoading}
                  className={btnDisabled === true ? "cursor-not-allowed" : ""}
                >
                  Confirm and pay
                </ButtonPrimary>
                <p className="mt-5 text-gray-400 text-xs">
                  Powered by{" "}
                  <b className="text-gray-700 dark:text-white">Stripe</b>
                </p>
              </div>

            </div>
          </div>
        </form>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPage ${className}`} data-nc-id="CheckOutPage">
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
      </main>
    </div>
  );
};

export default CheckOutPage;
