import React, { FC, useEffect, useState } from "react";
// import BackgroundSection from "../../components/BackgroundSection/BackgroundSection";
// import Heading from "../../shared/Heading/Heading";
import axios from "axios";
// import config from "../../utils/config";
// @ts-ignore
import DOMPurify from 'dompurify';
// import Loading from "components/Loading/Loading";
import Heading from "shared/Heading/Heading";
import config from "utils/config";
import Loading from "components/Loading/Loading";
export interface termsProps {
	className?: string;
}
const Terms: FC<termsProps> = () => {
	const [details, setDetails] = useState('');
	const [loading, setLoading] = useState(false)
	const getTerms = () => {
		setLoading(true)
		axios.get(config.apiserver + "policydetails/3")
			.then(function (response) {
				setDetails(response.data.data.details)
				setLoading(false)
			})
			.catch(function (error) {
				setLoading(false)

			})
	}
	useEffect(() => {
		getTerms()
	}, [])

	const createMarkup = (html: any) => {
		return {
			__html: DOMPurify.sanitize(html)
		}
	}

	return (
		loading ? <Loading /> :
			<div className="container relative space-y-24 mb-24 lg:space-y-32 lg:mb-32">
				<div className="relative py-16">
					<Heading
						desc=""
						isCenter={true}
					>
						Terms & Conditions
					</Heading>
					<div
						className="prose prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark text-xs"
					>
						{/*{details}*/}
						<span dangerouslySetInnerHTML={createMarkup(details)}></span>
					</div>
				</div>
			</div>



	)

};

export default Terms;