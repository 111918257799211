import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import config from "utils/config";
import RetrieveStatus from "./RetrieveStatus";

const RetrievePayment = () => {
  const paymentIntent = new URLSearchParams(window.location.search).get(
    "payment_intent"
  );
  const clientSecret = new URLSearchParams(window.location.search).get(
    "payment_intent_client_secret"
  );
  const redirectStatus = new URLSearchParams(window.location.search).get(
    "redirect_status"
  );

  const stripePromise = loadStripe(config.stripe_key);

  return (
    <>
      <Elements stripe={stripePromise}>
        <RetrieveStatus
          paymentIntent={paymentIntent}
          clientSecret={clientSecret}
          redirectStatus={redirectStatus}
        />
      </Elements>
    </>
  );
};

export default RetrievePayment;
