import React, { FC, useEffect } from "react";
import Glide from "@glidejs/glide";
import { TaxonomyType } from "data/types";
import ncNanoId from "utils/ncNanoId";
import { Link } from "react-router-dom";
import NcImage from "../../shared/NcImage/NcImage";

export interface SliderNewCategoriesProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  categories?: TaxonomyType[];
  categoryCardType?: "card3" | "card4" | "card5";
  itemPerRow?: 4 | 5;
  sliderStyle?: "style1" | "style2";
  id?: any;
}

const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "/accommodation",
    name: "Accommodation",
    taxonomy: "category",
    count: 17288,
    thumbnail:
      "https://images.pexels.com/photos/2581922/pexels-photo-2581922.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "2",
    href: "/holiday-house",
    name: "Holiday House",
    taxonomy: "category",
    count: 2118,
    thumbnail:
      "https://images.pexels.com/photos/2351649/pexels-photo-2351649.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "3",
    href: "/short-term-rent",
    name: "Short Term Rentals",
    taxonomy: "category",
    count: 36612,
    thumbnail:
      "https://images.pexels.com/photos/962464/pexels-photo-962464.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "4",
    href: "/apartment",
    name: "Service Apartment",
    taxonomy: "category",
    count: 2118,
    thumbnail:
      "https://images.pexels.com/photos/248837/pexels-photo-248837.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
];

const SliderNewCategories: FC<SliderNewCategoriesProps> = ({
  heading = "",
  subHeading = "",
  className = "",
  itemClassName = "",
  categories = DEMO_CATS,
  itemPerRow = 5,
  categoryCardType = "card3",
  sliderStyle = "style1",
  id,
}) => {
  const UNIQUE_CLASS = "glide_" + ncNanoId();

  useEffect(() => {
    if (document.querySelector(`.${UNIQUE_CLASS}`)) {
      new Glide(`.${UNIQUE_CLASS}`, {
        perView: itemPerRow,
        gap: 32,
        bound: true,
        breakpoints: {
          1280: {
            perView: itemPerRow - 1,
          },
          1024: {
            gap: 20,
            perView: itemPerRow - 1,
          },
          768: {
            gap: 20,
            perView: itemPerRow - 2,
          },
          640: {
            gap: 20,
            perView: itemPerRow - 3,
          },
          500: {
            gap: 20,
            perView: 1.3,
          },
        },
      }).mount();
    }
  }, []);

  return (
    <div className="relative text-center">
      <div>
        <h2
          className={`sm:text-3xl text-3xl md:text-4xl font-semibold mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50`}
        >
          Accommodation Type
        </h2>

        <ul className="flex  flex-col lg:flex-row justify-between items-center">
          {DEMO_CATS.map((item, index) =>
            id === item.id ? (
              ""
            ) : (
              <li key={index} style={{ width: "230.4px", marginRight: "16px" }}>
                <Link
                  to={item.href}
                  className={`nc-CardCategory3 flex flex-col ${className}`}
                  data-nc-id="CardCategory3"
                >
                  <div
                    className={`flex-shrink-0 relative w-full aspect-w-5 aspect-h-4 sm:aspect-h-7 h-0 rounded-2xl overflow-hidden group`}
                  >
                    <NcImage
                      src={item.thumbnail}
                      className="object-cover w-full h-full rounded-2xl"
                    />
                    <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
                  </div>
                  <div className="mt-4 truncate mb-4">
                    <h2
                      className={`text-base sm:text-lg text-neutral-900 dark:text-neutral-100 font-medium truncate`}
                    >
                      {item.name}
                    </h2>
                    <span
                      className={`block mt-2 text-sm text-neutral-6000 dark:text-neutral-400`}
                    >
                      {/*{convertNumbThousand(item.count || 0)} properties*/}
                    </span>
                  </div>
                </Link>
              </li>
            )
          )}
        </ul>
      </div>
    </div>
  );
};

export default SliderNewCategories;
