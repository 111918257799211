import { Link } from "react-router-dom";
import config from "utils/config";
import moment from "moment";
import { useQuery, useQueryClient } from "react-query";
import Loading from "components/Loading/Loading";
import axiosinstance from "utils/AxiosInstance";
import { useEffect } from "react";

export interface BookingsProps {
  check_in?: String;
  check_out?: String;
  booking_id?: number;
  customer_name?: String;
  discount_amount?: number;
  grand_total?: number;
  guest?: number;
  image?: String;
  property_name?: String;
  room_id?: number;
  room_no?: String;
  status?: number;
  total?: number;
  total_discount?: number;
  request_id?: number;
}
const BookingRequestCard = (): JSX.Element => {
  const queryClient = useQueryClient();

  const { data: bookings, isLoading } = useQuery(
    ["booking/pendingcustomerbookingreq"],
    () =>
      axiosinstance
        .get(`booking/pendingcustomerbookingreq`)
        .then((res) => res.data),
    { refetchOnMount: true }
  );

  useEffect(() => {
    return () => {
      queryClient.removeQueries("booking/pendingcustomerbookingreq");
    };
  }, []);

  if (isLoading) return <Loading />;

  if (bookings?.status === 0) {
    return (
      <div className="text-center text-2xl font-medium">No Booking Found</div>
    );
  }

  return (
    <>
      {bookings?.data?.map((item: any) => (
        <div
          className={`nc-PropertyCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow h-full`}
          data-nc-id="PropertyCardH"
          key={item.request_id}
        >
          <Link
            to={`/customer/bookingrequest/${item.request_id}`}
            className="w-full flex flex-col sm:flex-row sm:items-center"
          >
            <div className="flex-shrink-0 p-3 w-full h-full sm:w-64 ">
              <img
                src={config?.imageURL + item?.image}
                alt=""
                className="h-full rounded-lg max-h-56"
              />

              {item.discount_amount === 0 ? (
                ""
              ) : (
                <div
                  className={`nc-SaleOffBadge flex items-center justify-center text-xs py-0.5 px-3 text-red-50 rounded-full absolute left-5 top-5 bg-orange-500`}
                  data-nc-id="SaleOffBadge"
                >
                  - {item.discount_amount} £
                </div>
              )}
            </div>

            <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
              <div className="space-y-4 w-full p-2">
                <div className="flex items-center space-x-2">
                  <h2 className="text-lg font-medium capitalize">
                    <span className="line-clamp-2">{item.property_name}</span>
                  </h2>
                </div>
                <div className="flex items-center space-x-2">
                  <h2 className="text-lg font-medium capitalize">
                    <span className="line-clamp-2">
                      Room Name: {item.room_no}
                    </span>
                  </h2>
                </div>
                <div className="flex items-center space-x-2">
                  <span className="sm:inline-block text-sm">Request ID:</span>
                  <span className="text-md text-neutral-500 dark:text-neutral-400">
                    {item.request_id}
                  </span>
                </div>

                <div className="grid grid-cols-1 gap-4">
                  <div className="xl:inline-grid grid-cols-2">
                    <div className="flex items-center space-x-2">
                      <span className="sm:inline-block text-sm">
                        <i className="las la-calendar-check text-lg" />
                        Check-in:
                      </span>
                      <span className="text-xs text-neutral-500 dark:text-neutral-400">
                        {moment(`${item.check_in}`).format("DD MMM, YYYY")}
                      </span>
                    </div>

                    {/* ---- */}

                    <div className="flex items-center space-x-2">
                      <span className="sm:inline-block text-sm">
                        <i className="las la-calendar-minus text-lg" />
                        Check-Out:
                      </span>
                      <span className="text-xs text-neutral-500 dark:text-neutral-400">
                        {moment(`${item.check_out}`).format("DD MMM, YYYY")}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 "></div>
                <div className="grid grid-cols-1 gap-4">
                  <div className="xl:inline-grid grid-cols-2">
                    {/* <div className="flex w-full justify-between items-end font-medium text-secondary-500 mb-2">
  										Total : £{item.total}
  									</div>
  									<div className="flex w-full justify-between items-end font-medium text-secondary-500 mb-2">
  										Cleaning Fee : £{item.cleaning_fee}
  									</div>
  									<div className="flex w-full justify-between items-end font-medium text-secondary-500 mb-2">
  										Total Discount : £{item.total_discount}
  									</div> */}
                    <div className="flex w-full justify-between items-end font-medium text-secondary-500 mb-2">
                      Grand Total : £{item.grand_total}
                    </div>
                    <div className="flex items-center justify-center px-3 py-2 border border-secondary-500 rounded leading-none text-base font-medium text-secondary-500">
                      {item.status === 0
                        ? "Request Pending"
                        : item.status === 1
                        ? "Request Accepted."
                        : item.status === 2
                        ? "Request Declined"
                        : item.status === 3
                        ? "Canceled"
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </>
  );
};

export default BookingRequestCard;
