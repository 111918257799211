import BookingCardList from "components/BookingCarcdList";
import { FC } from "react";
export interface BookingCardProps {
  className?: string;
}

const BookingHistoryCard: FC<BookingCardProps> = () => {
  return (
    <div className="grid grid-cols-1 gap-8">
      <BookingCardList />
    </div>
  );
};
export default BookingHistoryCard;
