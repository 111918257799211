import React, { FC } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
// import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import { useHistory } from "react-router-dom";

export interface MainNav1Props {
  isTop: boolean;
}

const MainNav1: FC<MainNav1Props> = ({ isTop }) => {
  let customer = "customer";
  let owner = "owner";
  let access = localStorage.getItem("access_token");
  let history = useHistory();
  const Logout = () => {
    let type = localStorage.getItem("login_type");
    localStorage.removeItem("access_token");
    localStorage.removeItem("user");
    if (type === "owner") {
      history.push("/login/owner");
      window.location.reload();
    } else {
      history.push("/login/customer");
      window.location.reload();
    }
  };
  const Profile = () => {
    let type = localStorage.getItem("login_type");
    if (type === owner) {
      history.push("/owner/my-property");
      window.location.reload();
    } else {
      history.push("/customer/customer-upcoming-booking");
    }
  };

  // @ts-ignore
  return (
    <div
      className={`nc-MainNav1 relative z-10 ${
        isTop ? "onTop " : "notOnTop backdrop-filter"
      }`}
    >
      <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
        <div className="flex justify-start flex-grow items-center space-x-4 sm:space-x-10 2xl:space-x-14">
          <Logo />
          <Navigation />
        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="hidden items-center space-x-0 xl:flex">
            <SwitchDarkMode />
            {/*<SearchDropdown />*/}
            {access === null ? (
              <>
                <ButtonPrimary
                  href={`/login/${customer}`}
                  type="button"
                  fontSize="text-sm font-medium"
                >
                  Sign in/ Sign Up
                </ButtonPrimary>
                <div className="px-1" />
                <a
                  href="https://partners.easy2manage.co.uk/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-primary text-sm font-medium"
                >
                  Partners Portal
                </a>
              </>
            ) : (
              <>
                <ButtonPrimary
                  onClick={Profile}
                  type="button"
                  fontSize="text-sm font-medium"
                >
                  Profile
                </ButtonPrimary>
                <div className="px-1" />
                <ButtonPrimary
                  onClick={Logout}
                  type="button"
                  fontSize="text-sm font-medium"
                >
                  Logout
                </ButtonPrimary>
              </>
            )}
          </div>
          <div className="flex items-center xl:hidden">
            <SwitchDarkMode />
            <div className="hidden lg:flex items-center space-x-0 xl:flex">
              {access === null ? (
                <>
                  <ButtonPrimary
                    href={`/login/${customer}`}
                    type="button"
                    fontSize="text-sm font-medium"
                  >
                    Sign in/ Sign Up
                  </ButtonPrimary>
                  <div className="px-1" />
                  <ButtonPrimary
                    href="https://partners.easy2manage.co.uk/"
                    type="button"
                    targetBlank
                    fontSize="text-sm font-medium"
                  >
                    Partners Portal
                  </ButtonPrimary>
                </>
              ) : (
                <>
                  <ButtonPrimary
                    onClick={Profile}
                    type="button"
                    fontSize="text-sm font-medium"
                  >
                    Profile
                  </ButtonPrimary>
                  <div className="px-1" />
                  <ButtonPrimary
                    onClick={Logout}
                    type="button"
                    fontSize="text-sm font-medium"
                  >
                    Logout
                  </ButtonPrimary>
                </>
              )}
            </div>
            <div className="px-1" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
