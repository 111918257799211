import React, { FC, ReactNode } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from "axios";
import config from "../../utils/config";
import Loading from "components/Loading/Loading";
import { useQuery } from "react-query";

// @ts-ignore
// import DOMPurify from 'dompurify';
export interface SectionHeroProps {
  className?: string;
  // rightImg: string;
  heading: ReactNode;
  subHeading: string;
  btnText: string;
  heading2?: ReactNode;
  subHeading2?: string;
}

export interface bookingTopInterface {
  image: string;
  name: string;
  body: string;
  imgDark?: string;
}
const SectionHero: FC<SectionHeroProps> = ({
  className = "",
  heading,
  heading2,
  subHeading,
  subHeading2,
  btnText,
}) => {
  const { data, isLoading } = useQuery(["widgets?page_name=book now"], () =>
    axios
      .get(config.apiserver + "widgets?page_name=book now")
      .then((res) => res.data.data)
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div
      className={`nc-SectionHero relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
        <div className="w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7">
          <h2 className="text-2xl !leading-tight font-semibold text-neutral-900 md:text-3xl xl:text-4xl dark:text-neutral-100">
            {data?.[0]?.name}
          </h2>
          <span className="block text-justify xl:text-lg text-neutral-6000 dark:text-neutral-400">
            {subHeading}
          </span>
          <h2 className="text-xl !leading-tight font-semibold text-neutral-900 md:text-2xl xl:text-3xl dark:text-neutral-100">
            {heading2}
          </h2>
          <span className="block text-justify xl:text-lg text-neutral-6000 dark:text-neutral-400">
            {subHeading2}
          </span>
          {!!btnText && (
            <ButtonPrimary href="/contact">{btnText}</ButtonPrimary>
          )}
        </div>
        <div className="flex-grow">
          <img
            className="w-full rounded-3xl"
            src={config.imageURL + "public" + data?.[0]?.image}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default SectionHero;
