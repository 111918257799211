import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import imagePng2 from "images/APARTMENT.jpg";
import axios from "axios";
import config from "../../utils/config";
// @ts-ignore
import DOMPurify from "dompurify";
import OurFeatures from "components/Section/OurFeatures";
import SliderNewCategories from "components/Section/SliderNewCategories";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import Loading from "components/Loading/Loading";
import { heroInterface } from "components/Section/HeroTwo";
export interface ListingStayPageProps {
  className?: string;
}

const Apartment: FC<ListingStayPageProps> = ({ className = "" }) => {
  const [loading, setLoading] = useState(false);
  const [hero, setHero] = useState<heroInterface>();
  useEffect(() => {
    heroAllData();
  }, []);

  const heroAllData = () => {
    setLoading(true);
    axios
      .get(config.apiserver + "widgets?page_name=Accommodation")
      .then(function (response) {
        const allData = response.data.data;
        setHero(allData[4]);
        setLoading(false);
      })
      .catch(function (error) {
        // handle error
        setLoading(false);
      });
  };
  const createMarkup = (html: any) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };
  return loading ? (
    <Loading />
  ) : (
    <div
      className={`nc-ListingStayPage relative overflow-hidden ${className}`}
      data-nc-id="Accommodation"
    >
      <Helmet>
        <title>Easy 2 Manage (E2M) || Service Accommodation Specialist</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container pt-10 pb-24 lg:pt-24 lg:pb-32">
        <div>
          <img
            src={imagePng2}
            alt=""
            className="rounded-3xl w-full sm:w-full md:w-full lg:w-3/6 xl:w-3/6 2xl:w-3/6 float-right ml-5 mb-5"
          />
          <div>
            <h2 className="font-medium text-2xl md:text-3xl xl:text-4xl leading-[110%] mb-5">
              {hero?.name}
            </h2>
            <span
              className="text-justify md:text-lg text-neutral-500 dark:text-neutral-400"
              dangerouslySetInnerHTML={createMarkup(hero?.body)}
            ></span>
          </div>
        </div>
      </div>
      <div className="container relative overflow-hidden">
        <div className="relative py-24">
          <SliderNewCategories id="4" />
        </div>
        <OurFeatures type="type2" />
      </div>
    </div>
  );
};

export default Apartment;
