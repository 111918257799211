import Glide from "@glidejs/glide";
import React, { FC, useState } from "react";
import { useEffect } from "react";
import ncNanoId from "utils/ncNanoId";

import axios from "axios";
import config from "../../utils/config";
import Testimonial from "components/Testimonial/Testimonial";
import Loading from "components/Loading/Loading";
import Heading from "components/Heading/Heading";
export interface itemCustomerReview {
  image: string;
  name: string;
  body: string;
  keyword: string;
  id: any;
}
export interface ReviewProps {
  className?: string;
}

const Review: FC<ReviewProps> = ({ className = "" }) => {
  const UNIQUE_CLASS = "glide_" + ncNanoId();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<itemCustomerReview[]>([]);
  let data: itemCustomerReview[] = [];
  const sectionMobailApps = () => {
    setLoading(true);
    axios
      .get(config.apiserver + "widgets?page_name=Home")
      .then(function (response) {
        const allData = response.data.data;

        data.push(allData[26]);
        data.push(allData[27]);
        data.push(allData[28]);
        data.push(allData[29]);
        data.push(allData[30]);
        setItems(data);

        setLoading(false);
      })
      .catch(function (error) {

        setLoading(false);
      });
  };
  useEffect(() => {
    sectionMobailApps();
  }, []);

  useEffect(() => {
    if (document.querySelector(`.${UNIQUE_CLASS}`)) {
      new Glide(`.${UNIQUE_CLASS}`, {
        perView: 1,
      }).mount();
    }
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      <div
        className={`nc-SectionClientSay relative ${className} `}
        data-nc-id="SectionClientSay"
        style={{ marginBottom: "50px" }}
      >
        <Heading desc="Let's see what people think of E2M" isCenter>
          Good news from far away
        </Heading>
        <Testimonial items={items} />
      </div>
    </>
  );
};

export default Review;
