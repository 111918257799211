import loader from "images/loading.gif";

const Loading = () => {
  return (
    <div className="flex items-center justify-center h-screen z-10">
      <img src={loader} alt="Loading..." className="w-28 z-10" />
    </div>
  );
};

export default Loading;
