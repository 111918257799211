import React, { HTMLAttributes, ReactNode } from "react";
import Avatar from "../../shared/Avatar/Avatar";
import { TaxonomyType } from "../../data/types";
import CardCategory from "components/CartCategory";

export interface LeftNavProps extends HTMLAttributes<HTMLHeadingElement> {
  fontClass?: string;
  desc?: ReactNode;
  isCenter?: boolean;
  username?: string;
  profile?: string;
  designation?: string;
  ownerCtg?: TaxonomyType[];
  customerCtg?: TaxonomyType[];
  type?: string;
}

const CUS_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "/customer/customer-upcoming-booking",
    name: "Upcoming Booking",
    taxonomy: "category",
    icon: "las la-city",
  },
  {
    id: "6",
    href: "/customer/customer-booking-request",
    name: "Booking Request",
    taxonomy: "category",
    icon: "las la-hotel",
  },
  {
    id: "2",
    href: "/customer/customer-booking-history",
    name: "Booking History",
    taxonomy: "category",
    icon: "las la-history",
  },
  {
    id: "3",
    href: "/customer/customer-payment-history",
    name: "Payment History",
    taxonomy: "category",
    icon: "las la-money-check-alt",
  },
  {
    id: "4",
    href: "/customer/customer-profile-setup",
    name: "Account Setup",
    taxonomy: "category",
    icon: "las la-user-tie",
  },
  {
    id: "5",
    href: "/customer/customer-logout",
    name: "Logout",
    taxonomy: "category",
    icon: "las la-sign-in-alt",
  },
];

const LeftNav: React.FC<LeftNavProps> = ({
  className = "mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50",
  isCenter = false,
  username = "",
  profile = "",
  customerCtg = CUS_CATS,
  designation = "",
  type = "",
}) => {
  const customer: any = localStorage.getItem("user");
  let user = JSON.parse(customer);

  return (
    <div className=" w-full flex flex-col items-center text-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 sm:p-6 xl:p-8 bg-neutral-100 dark:bg-black dark:bg-opacity-20 p-5 rounded-2xl">
      <div className="w-full block flex-grow mb-24 lg:mb-0">
        <div className="lg:sticky lg:top-24">
          <Avatar
            imgUrl={user.image}
            hasChecked={user?.is_verified === 1 ? true : false}
            hasCheckedClass="w-6 h-6 -top-0.5 right-2"
            sizeClass="w-28 h-28"
          />

          <div className="space-y-1 text-center flex flex-col items-center">
            <h3 className="text-2xl font-semibold lg:pt-5 capitalize">
              {username}
            </h3>
            <span className="block text-neutral-500 dark:text-neutral-400 pb-3">
              {designation}
            </span>
          </div>
          <div className="text-center flex flex-col items-center mb-6">
            <div className="border-b border-neutral-200 dark:border-neutral-700 w-14 mb-4"></div>
          </div>

          <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
            {customerCtg.map((customer) => (
              <CardCategory
                className="p-2 xl:p-3"
                key={customer.id}
                taxonomy={customer}
                size="normal"
                path={customer.href}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftNav;
