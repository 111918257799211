import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";
import Loading from "components/Loading/Loading";
import { useQuery } from "react-query";
import axios from "axios";
import config from "utils/config";

// @ts-ignore

export interface SocialsListProps {
  className?: string;
  itemClass?: string;
  socials?: SocialType[];
}

export interface itemContactUs {
  image: string;
  name: string;
  body: string;
  keyword: string;
}



const SocialsList: FC<SocialsListProps> = ({
  className = "",
  itemClass = "block" }) => {

  const { data, isLoading } = useQuery(['widgets?page_name=Contact US'], () => axios.get(config.apiserver + 'widgets?page_name=Contact US').then((res) => res.data.data));

  if (isLoading) {
    return <Loading />;
  }
  return (
    <nav
      className={`nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
      data-nc-id="SocialsList"
    >

      <a className={`${itemClass}`}
        href={data?.[3]?.name}
        target="_blank"
        rel="noopener noreferrer"
        title={data?.[3]?.keyword}
      >
        <i className="lab la-facebook-square"></i>
      </a>

      <a className={`${itemClass}`}
        href={data?.[6]?.name}
        target="_blank"
        rel="noopener noreferrer"
        title={data?.[6]?.keyword}
      >
        <i className="lab la-linkedin"></i>
      </a>

      <a className={`${itemClass}`}
        href={data?.[5]?.name}
        target="_blank"
        rel="noopener noreferrer"
        title={data?.[5]?.keyword}
      >
        <i className="lab la-youtube"></i>
      </a>


      <a className={`${itemClass}`}
        href={data?.[4]?.name}
        target="_blank"
        rel="noopener noreferrer"
        title={data?.[4]?.keyword}
      >
        <i className="lab la-instagram"></i>
      </a>

    </nav>
  );
};

export default SocialsList;
