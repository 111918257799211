import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";

export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home",
  },
  {
    id: ncNanoId(),
    href: "",
    name: "Accommodation Type",
    type: "dropdown",
    children: [
      { id: ncNanoId(), href: "/accommodation", name: "Accommodation" },
      { id: ncNanoId(), href: "/holiday-house", name: "Holiday Houses" },
      { id: ncNanoId(), href: "/short-term-rent", name: "Short Term Rentals" },
      { id: ncNanoId(), href: "/apartment", name: "Service Apartment" },
    ],
  },
  {
    id: ncNanoId(),
    href: "/about",
    name: "Company",
  },
  {
    id: ncNanoId(),
    href: "/book-now",
    name: "All Properties",
  },
  {
    id: ncNanoId(),
    href: "/contact",
    name: "Contact",
  },
];
