import { useState } from "react";
import { Helmet } from "react-helmet";
import SocialsList from "shared/SocialsList/SocialsList";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
// import Select from "shared/Select/Select";
import axios from "axios";
import config from "utils/config";
// import { CISUI } from "utils/CISUI";
import ReCAPTCHA from "react-google-recaptcha";
import Label from "components/Label";
import PhoneInput from "react-phone-input-2";
// import { useForm } from "react-hook-form";
// import { ImGoogle2 } from '@react-icons/all-files/im/ImGoogle2';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
// import { useForm } from "react-hook-form";

interface Props {
  className?: string;
}

const info = [
  {
    title: "🗺 ADDRESS",
    desc: "65 St Pauls Avenue Slough, SL2 5EX",
  },
  {
    title: "💌 EMAIL",
    desc: "info@easy2manage.co.uk",
  },
  {
    title: "☎ PHONE",
    desc: "03332423309",
  },
];

interface ComplaintInputType {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  booking_ref: string;
  reason: string;
  message: string;
}

const complaintSchema = yup.object().shape({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  email: yup.string().required("Email is required").email("Email is invalid"),
  phone: yup.string().required("Phone is required").min(5, "Phone is invalid"),
  booking_ref: yup.string().required("Booking Ref is required"),
  reason: yup.string().required("Reason is required"),
  message: yup.string().required("Message is required"),
});

const defaultValues = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  booking_ref: "",
  reason: "",
  message: "",
};

const Complaint: React.FC<Props> = ({ className = "" }) => {
  const [flag, setflag] = useState(true);
  const [phone, setPhone] = useState("");
  const [reCaptcha, setReCaptcha] = useState<string | null | boolean>(false);
  const {
    register,
    handleSubmit,
    // reset,
    setError,
    setValue,
    formState: { errors },
  } = useForm<ComplaintInputType>({
    resolver: yupResolver(complaintSchema),
    defaultValues,
  });
  const onSubmit = async (data: any) => {
    console.log("🚀 ~ file: index.tsx:105 ~ onSubmit ~ data:", data);
    if (!reCaptcha) {
      toast.error("Please verify you are not a robot");
      return;
    }
    axios
      .post(config.apiserver + `complained`, data)
      .then((res) => {
        if (res.data.status === 1) {
          setflag(false);
          toast.success(res.data.msg);
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.msg);
      });
  };

  const onChange = (value: string | null) => {
    setReCaptcha(value);
  };
  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Easy 2 Manage (E2M) || The Property Management Company</title>
      </Helmet>
      <div className="mb-10 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Complaint Form
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div className="items-center max-w-sm space-y-8 mt-14">
              {info?.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 SOCIALS
                </h3>
                <SocialsList className="mt-2" />
              </div>
            </div>
            <div>
              {flag ? (
                <form
                  className="grid grid-cols-1 gap-6"
                  action="#"
                  method="post"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="grid grid-cols-2 gap-6">
                    <label className="block">
                      <Label>
                        First Name
                        <sup className="my-2 text-xs text-red-500">*</sup>
                      </Label>

                      <Input
                        placeholder="First Name"
                        type="text"
                        className="mt-1"
                        {...register("first_name")}
                      />
                      {errors?.first_name && (
                        <p className="my-2 text-xs text-red-500">
                          {errors?.first_name?.message}
                        </p>
                      )}
                    </label>
                    <label className="block">
                      <Label>
                        Last Name
                        <sup className="my-2 text-xs text-red-500">*</sup>
                      </Label>

                      <Input
                        placeholder="Last Name"
                        type="text"
                        className="mt-1"
                        {...register("last_name")}
                      />
                      {errors?.last_name && (
                        <p className="my-2 text-xs text-red-500">
                          {errors?.last_name?.message}
                        </p>
                      )}
                    </label>
                    <label className="block">
                      <Label>
                        Email address
                        <sup className="my-2 text-xs text-red-500">*</sup>
                      </Label>
                      <Input
                        type="email"
                        placeholder="example@example.com"
                        className="mt-1"
                        {...register("email")}
                      />
                      {errors?.email && (
                        <p className="my-2 text-xs text-red-500">
                          {errors?.email?.message}
                        </p>
                      )}
                    </label>
                    <label className="block">
                      <Label>
                        Phone Number
                        <sup className="my-2 text-xs text-red-500">*</sup>
                      </Label>

                      <PhoneInput
                        country="us"
                        value={phone}
                        onChange={(phone: string) => {
                          setValue("phone", `+${phone}`);
                          setPhone(`+${phone}`);
                          setError("phone", {});
                        }}
                        inputClass="block !w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 mt-1 h-11 px-4 py-3 text-sm font-normal bg-white dark:bg-neutral-900 dark:focus:bg-neutral-900"
                        dropdownClass="!dark:bg-neutral-900 dark:text-neutral-800"
                      />
                      {errors?.phone && (
                        <p className="my-2 text-xs text-red-500">
                          {errors?.phone?.message}
                        </p>
                      )}
                    </label>

                    <label className="block">
                      <Label>
                        Booking Ref
                        <sup className="my-2 text-xs text-red-500">*</sup>
                      </Label>

                      <Input
                        placeholder="Booking Ref"
                        type="text"
                        className="mt-1"
                        {...register("booking_ref")}
                      />
                      {errors?.booking_ref && (
                        <p className="my-2 text-xs text-red-500">
                          {errors?.booking_ref?.message}
                        </p>
                      )}
                    </label>
                    <label className="block">
                      <Label>
                        Complaint Reason
                        {/* <sup className="my-2 text-xs text-red-500">*</sup> */}
                      </Label>
                      {/* <Select
                        className="mt-1"
                        // name="reason"
                        ref={{ ...register("reason") }}
                      >
                        <option value="">Select a option</option>
                        <option value="Customer Service">
                          Customer Service
                        </option>
                        <option value="Cleaning">Cleaning</option>
                        <option value="Property Details">
                          Property Details
                        </option>
                      </Select> */}

                      <select
                        className={`nc-Select mt-1 block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900`}
                        {...register("reason")}
                      >
                        <option value="">Select a option</option>
                        <option value="Customer Service">
                          Customer Service
                        </option>
                        <option value="Cleaning">Cleaning</option>
                        <option value="Property Details">
                          Property Details
                        </option>
                      </select>
                      {errors.reason && (
                        <p className="my-2 text-xs text-red-500">
                          {errors.reason.message}
                        </p>
                      )}
                    </label>
                  </div>
                  <label className="block">
                    <Label>
                      Complaint
                      <sup className="my-2 text-xs text-red-500">*</sup>
                    </Label>

                    <Textarea
                      className="mt-1"
                      rows={6}
                      placeholder="Complaint..."
                      {...register("message")}
                    />
                    {errors?.message && (
                      <p className="my-2 text-xs text-red-500">
                        {errors?.message.message}
                      </p>
                    )}
                  </label>

                  <ReCAPTCHA
                    sitekey="6LcYrgoeAAAAALhdWsL9zj19peuJ61rtK1xCn-K6"
                    onChange={onChange}
                  />

                  {reCaptcha === null && (
                    <p style={{ color: "red", fontSize: "11px" }}>
                      Please verify that you are not a robot.
                    </p>
                  )}
                  <div>
                    <ButtonPrimary
                      type="submit"
                      className="w-full"
                      // loading={isLoading}
                      // disabled={reCaptcha === null ? false : true || isLoading}
                    >
                      Send
                    </ButtonPrimary>
                  </div>
                </form>
              ) : (
                <div className="py-20">
                  <p className="font-medium text-neutral-700 dark:text-neutral-300 text-base">
                    We are sorry, you had a complaint with one of our services
                    but rest assure, we have received your complaint and we will
                    look into this and will get in touch within next 2 working
                    days.
                  </p>
                  <p className="text-base font-medium text-neutral-700 dark:text-neutral-300 mt-2">
                    If anything urgent please call us at 0333 242 3309
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Complaint;
