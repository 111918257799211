import React, { useEffect, useState } from "react";
import LocationInput from "./LocationInput";
import ButtonSubmit from "./ButtonSubmit";
import { FC } from "react";
import StayDatesRangeInput from "./StayDatesRangeInput";
import { DateRage } from "./StaySearchForm";
import moment from "moment";
import useWindowSize from "../../hooks/useWindowResize";
import GuestsInput from "./GuestsInput";
// import UK_CITIES from './uk_citys.json'

export interface RealEstateSearchFormProps {
    haveDefaultValue?: boolean;
    dateProps?: DateRage
    guestProps?: any
    location?: any
}

export interface GuestsInputs {
    guestAdults?: number | undefined;
    guestChildren?: number | undefined;
    guestInfants?: number | undefined;
}


const defaultLocationValue = "NewYork,California,saeid";


const RealEstateSearchForm: FC<RealEstateSearchFormProps> = ({
    haveDefaultValue = false,
    location,
    dateProps,
    guestProps

}) => {
    const [locationInputValue, setLocationInputValue] = useState("");
    const [guestsInputValue, setGuestsInputValue] = useState<GuestsInputs>({
        guestAdults: 1,
        guestChildren: 0,
        guestInfants: 0,
    })
    const [selectedDate, setSelectedDate] = useState<DateRage>({
        startDate: moment(),
        endDate: moment().add(1, "days"),
    })

    const windowSize = useWindowSize();

    useEffect(() => {
        if (haveDefaultValue) {
            setLocationInputValue(defaultLocationValue);
        }
    }, []);

    useEffect(() => {
        if (dateProps && guestProps && location) {
            setLocationInputValue(location)
            setSelectedDate(dateProps);
            setGuestsInputValue(guestProps)
        }

    }, []);
    const renderForm = () => {
        return (
            <form className="w-full relative xl:mt-8 flex flex-col lg:flex-row lg:items-center rounded-3xl lg:rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-900 divide-y divide-neutral-200 dark:divide-neutral-700 lg:divide-y-0">
                <LocationInput
                    defaultValue={locationInputValue}
                    onChange={(e) => setLocationInputValue(e)}
                />
                <StayDatesRangeInput
                    wrapClassName=""
                    onChange={(date) => setSelectedDate(date)}
                    numberOfMonths={1}
                    fieldClassName="p-5"
                    defaultValue={selectedDate}
                    anchorDirection={windowSize.width > 1400 ? "left" : "right"}
                    
                />
                <GuestsInput
                    fieldClassName="p-5"
                    defaultValue={guestsInputValue}
                    onChange={(e) => setGuestsInputValue(e)}
                />
                {/* BUTTON SUBMIT OF FORM */}
                <div className="px-4 py-4 lg:py-0">
                    <ButtonSubmit dateProps={selectedDate} locationProps={locationInputValue} guestProps={guestsInputValue} />
                </div>
            </form>

        );
    };

    return renderForm();
};

export default RealEstateSearchForm;